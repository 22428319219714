import axios from 'axios';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { API_BASE_URL } from '../../constants/apiConstants';
import Loader from '../loader';

const JobFeedback = () => {
    const [spFeedbacks, setSPFeedbacks] = React.useState([]);
    const [mustUpdateFeedbacks, setMustUpdateFeedbacks] = useState(0)
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [isLoading, setIsLoading] = useState(false);
    Moment.locale('ka');

    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/sp/jobs/feedbacks/get";

        const authAxios = axios.create({
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const result = await authAxios.post(`${apiUrl}`);
                setSPFeedbacks(result.data);

            } catch (err) {
                console.log("Request failed", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [mustUpdateFeedbacks, cookies.ContactiAccessToken]);

    const getEmojiFromRating = (rating) => {
        switch (rating) {
            case "Positive":
                return "../resources/icons/positive-emoji.png"
            case "Negative":
                return "../resources/icons/negative-emoji.png"
            case "Neutral":
                return "../resources/icons/neutral-emoji.png"
            default:
                return "../resources/icons/positive-emoji.png"
        }
    }

    const getClassFromRating = (rating) => {
        switch (rating) {
            case "Positive":
                return "feedback-positive"
            case "Negative":
                return "feedback-negative"
            case "Neutral":
                return "feedback-neutral"
            default:
                return "feedback-positive"
        }
    }

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString('ka-GE', options);
    }

    return (
        <>
            <div className="row feedback-filter">
                <div className="col-5">
                    <select className="custom-select">
                        <option selected>გაფილტრე შეფასების ტიპით</option>
                        <option value={1}>პოზიტიური</option>
                        <option value={2}>ნეიტრალური</option>
                        <option value={3}>ნეგატიური</option>
                    </select>
                </div>
            </div>
            {isLoading ?
                <Loader /> :
                <div className="job-cat-container">
                    <div className="job-cat-inner">
                        {spFeedbacks ? spFeedbacks.map((value, index) => (
                            <div className="feedback-container">
                                <div className="row">
                                    <div className="col-lg-10">
                                        <p className="job-category-txt">{value.jobTitle}</p>
                                    </div>
                                    <div className="col-lg-2">
                                        <p className="feedback-date">{value.feedbackDate ? formatDate(value.feedbackDate) : ''}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="feedback-userid">{value.feedbackByDisplayName}</p>
                                        <p className="feedback-cat">{value.feedbackCategory}</p>
                                    </div>
                                </div>
                                {value.feedbackDate ?
                                    <>
                                        <div className="row">
                                            <div className="col-lg-12 feedback-hr">
                                                <div className="feedback-emoji">
                                                    <img className="emoji-img" alt={value.feedbackRating} src={getEmojiFromRating(value.feedbackRating)} />
                                                    <span className={getClassFromRating(value.feedbackRating)}>{value.feedbackRating}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <span className="quotation-mark">„</span>
                                                <span className="feedback-txt">{value.feedbackDescription}</span>
                                            </div>
                                        </div>
                                    </> : <><div className="row">
                                        <div className="col-12 feedback-emoji">
                                            <p className="not-reviewed">თქვენ ჯერ კიდევ არ მიგიღიათ შეფასება </p>
                                        </div>
                                    </div>
                                        <div className="addcat-btn-container feedback-remineder-btn">
                                            <button type="button" className="btn btn-primary btn-block"><span className="add-category-btn">შეფასების შეხსენების გაგზავნა</span></button>
                                        </div></>
                                }
                            </div>
                        )) : ''}
                    </div>
                </div>}
            {/* <div className="feedback-pagination">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Previous">
                                <span aria-hidden="true">&lt;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                        <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                        <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                        <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Next">
                                <span aria-hidden="true">&gt;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div> */}
        </>
    )
}

export default JobFeedback;