import "./reset-password.scss";
import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../constants/apiConstants";
import { isEmail } from "./validation";
import { showErrMsg, showSuccessMsg } from "./notification";

const initialState = { email: "", err: "", success: "" };

function ResetPassword() {
  const [data, setData] = useState(initialState);

  const { email, err, success } = data;

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({ ...data, [name]: value, err: "", success: "" });
  };

  const emailForForgottenPassword = async (e) => {
    e.preventDefault();
    const payload = {
      Email: email,
      //   body: { email: email },
    };

    if (!isEmail(email)) {
      return setData({ ...data, err: "შეიყვანეთ სწორი ელ.ფოსტა", success: "" });
    }

    try {
      // console.log(payload);
      const response = await axios
        .post(`${API_BASE_URL}api/access/reset-password`, payload)
        .then((response) => {
          if (response.status === 200)
            setData({
              ...data,
              err: "",
              success: "ბმული გამოგზავნილია მითითებულ ელ.ფოსტაზე",
            });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rst_pass">
      <h2>პაროლის აღდგენა გსურთ?</h2>
      <div className="rst_pass_components">
        {err && showErrMsg(err)}
        {success && showSuccessMsg(success)}
        {/* <label htmlFor="email">შეიყვანეთ თქვენი ელ.ფოსტა</label> */}
        <input
          type="email"
          name="email"
          id="email"
          placeholder="შეიყვანეთ თქვენი ელ.ფოსტა"
          value={email}
          onChange={handleOnChange}
        />
        <button onClick={emailForForgottenPassword}>
          დაადასტურეთ თქვენი ელ.ფოსტა
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
