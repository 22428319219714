import React, { useContext, useState } from "react";
import "./footer.scss";
import { isMobile } from "react-device-detect";
// import logo from '../../../public/images/logo-grey.png'

function Footer(props) {
  return !isMobile ? (    
        <div className="footer-wrapper">
          <div className="footer__menu__sections">
            <div className="services">
              <div className="footer__menu">
                <p className="footer-titles fcaps">მომხმარებელი</p> 
                <a href="/" className="footer-menu-urls">
                  მთავარი გვერდი
                </a>
                <a href="/post-a-job" className="footer-menu-urls">
                  იპოვე ხელოსანი
                </a>
                <a href="/how-it-works" className="footer-menu-urls">
                  როგორ მუშაობს?
                </a>
              </div>
            </div>         
            <div className="company">
              <div className="footer__menu">
                <p className="footer-titles fcaps">კომპანია</p>
                <a href="/aboutus" className="footer-menu-urls">
                  რას ვაკეთებთ
                </a>                  
                <a href="/contact-us" className="footer-menu-urls">
                  კონტაქტი
                </a>                  
                <a href="/FAQ" className="footer-menu-urls">
                  FAQ
                </a>
              </div>
            </div>
            <div className="contact">
              <div className="footer__menu">
                <p className="footer-titles fcaps">დაგვიკავშირდით</p>
                <a href={`tel:+995505051617`} className="footer-menu-urls">
                  (+995) 505 051 617
                </a>                  
                <a href={'mailto:info@contacti.ge'} className="footer-menu-urls">
                  info@contacti.ge
                </a>                  
                <div className="social__media">
                  <a href="https://www.facebook.com/contacti.ge" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
                  </a>
                  <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-instagram" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
                  </a>
                  <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-linkedin" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__bottom__sections">
            <img className="footer__bottom__logo" src='/images/logo-grey.png' style={{height:'25px'}}/>
              <div className="footer__copyright"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-c-circle" viewBox="0 0 16 16">
                                                  <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512Z"/>
                                                </svg> ყველა უფლება დაცულია
              </div>
            </div>
          </div>
        </div>   
  ) : (
    <div className="footer-wrapper">
      <div className="footer__menu__sections">
      <div className="company">
        <div className="footer__menu">
          <p className="footer-titles fcaps">კომპანია</p>
          <a href="/aboutus" className="footer-menu-urls">
            რას ვაკეთებთ 
          </a>                  
          <a href="/contact-us" className="footer-menu-urls">
            კონტაქტი
          </a>                  
          <a href="/how-it-works" className="footer-menu-urls">
            როგორ მუშაობს?
          </a>                  
          <a href="/FAQ" className="footer-menu-urls">
            FAQ
          </a>
        </div>
      </div>
      
      <div className="contact">
        <div className="footer__menu">
          <p className="footer-titles fcaps">დაგვიკავშირდით</p>
          <a href={`tel:+995505051617`} className="footer-menu-urls">
            (+995) 505 051 617
          </a>                  
          <a href={'mailto:dspacegeorgia@gmail.com'} className="footer-menu-urls">
            info@contacti.ge
          </a>                  
          <div className="social__media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <i className="bi bi-facebook" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="bi bi-instagram" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <i className="bi bi-linkedin" style={{color:'#d4d4d4', cursor:'pointer'}}></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer__bottom">
      <div className="footer__bottom__sections">
        <img className="footer__bottom__logo" src='/images/logo-grey.png' style={{height:'20px'}}/>
        <div className="footer__copyright"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-c-circle" viewBox="0 0 16 16">
                                            <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512Z"/>
                                          </svg> ყველა უფლება დაცულია
        </div>
      </div>
    </div>
    </div>  
  );
}

export default Footer;
