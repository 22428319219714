import 'react-responsive-carousel/lib/styles/carousel.min.css';

import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ka' 
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from "react-device-detect";
import { API_BASE_URL } from '../../constants/apiConstants';
import { NavLink } from 'react-router-dom';

const UnavailableJobs = () => {
    const [showChat, setShowChat] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [showDeleteCatItem, setShowDeleteCatItem] = React.useState(-1);
    const [interestedJobs, setInterestedJobs] = React.useState([]);
    const [spJobs, setSPJobs] = React.useState([]);
    const [spSelectJobInterestedJobsModal, setSPSelectJobInterestedJobsModal] = React.useState(false);
    const [mustUpdateUnavailableJobs, setMustUpdateUnavailableJobs] = useState(0)
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const contactiCookie = cookies.ContactiAccessToken;
    const [selecterItemObj, setSelecterItemObj] = useState(null);

    useEffect(() => {

        const updateUrl = `${API_BASE_URL}/api/sp/jobs/get/state/Unavailable`;

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const result = await authAxios.post(`${updateUrl}`);
                setInterestedJobs(result.data);
                setIsLoading(false);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [mustUpdateUnavailableJobs, cookies.ContactiAccessToken]);

    useEffect(() => {
        if (contactiCookie === undefined) {
            setIsLoading(false);
        }
    }, [isLoading]);

    const updateJobState = async (jobId, state) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobs/state/update";

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "jobPostId": jobId, "jobState": state });
        setMustUpdateUnavailableJobs(Math.floor((Math.random() * 100000000000) + 1));
    };





    const renderJobList = () => {
        return (
          <div
            className="tab-pane fade show active p-3"
            id="one"
            role="tabpanel"
            aria-labelledby="all-jobs"
          >
            {isMobile &&( <div style={{width:'100%', height:'52px', background:'#909090', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{position:'absolute', left: '0', marginLeft:'15px'}} onClick={()=>setSelecterItemObj(null) }> 
           <NavLink to="/profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
      <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg></NavLink>
             </div>
            <div style={{color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>არააქტიური</div>
          </div>)}
    
    
            <div className="row no-gutters jobs-filter">
              <div className="col-12 md:col-4">
                <img
                  className="ic-search"
                  src="../resources/icons/search-icon.png"
                />
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control search-form"
                    id="jobsFilter"
                    placeholder="მოძებნე კატეგორიით..."
                  />
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-md-6">
                <div className="jobs-cat-inner">
                  {interestedJobs
                    ? interestedJobs.map((value, index) => (
                        <div
                          className={
                            index === activeTabIndex
                              ? "jobs-content jobs-tablinks active"
                              : "jobs-content jobs-tablinks"
                          }
                          onClick={() => {
                            setActiveTabIndex(index);
                            setSelecterItemObj(value);
                          }}
                          id="defaultOpen"
                        >
                          <div className="row no-gutters">
                            <div className="jobs-img-container">
                              {value && value.images && value.images[0] ? (
                                <img
                                  src={`${API_BASE_URL}/${value.images[0]}`}
                                  alt="job preview"
                                  className="jobs-img"
                                />
                              ) : (
                                <img
                                  src="../../images/no-image.jpg"
                                  alt="job preview"
                                  className="jobs-img"
                                />
                              )}
                            </div>
                            <div className="col jobs-inner">
                              <p className="jobs-title">{value.jobTitle}</p>
                              <p className="user-attribute">
                                {value.jobCategoryName}
                              </p>
                              <p className="jobs-price">{value.budgetRange}</p>
                              {value.invited ? (
                                <p className="jobs-invited">
                                  <i className="bi bi-check2 invited-check" />
                                  მოგიწვიეს
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row no-gutters jobs-content-footer">
                            <div className="jobs-location">
                              <i className="bi bi-geo-alt" />
                              <span className="jobs-location-name">
                                {value.city}
                              </span>
                            </div>
                            <span className="jobs-post-date">{`დაემატა ${moment(
                              value.postedDate.replace("Z", "")
                            ).fromNow()}`}</span>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
              {interestedJobs
                ? interestedJobs.map((value, index) =>
                    index === activeTabIndex ? renderJobDetails(value) : ""
                  )
                : ""}
            </div>
          </div>
        );
      };
    

      const renderJobDetails = (value) => {
        return (
          <div className=" col-6 tabcontent d-none d-md-block" id="tab-1">
            <div className="jobs-tabbed-gallery">
              <div className="jobs-details-container">
                <p className="job-poster">{value.jobPostedBy}</p>
                <span className="jobs-price-label">
                  კლიენტის ბიუჯეტი:{" "}
                  <span className="jobs-price">{value.budgetRange}</span>
                </span>
              </div>
              <div className="jobs-details-container">
                <p className="jobs-category">{value.jobCategoryName}</p>
                <p className="jobs-post-date no-margin">{`${moment(
                  value.postedDate.replace("Z", "")
                ).fromNow()}`}</p>
              </div>
              <div className="jobs-location user-attribute">
                <i className="bi bi-geo-alt jobs-details-location" />
                <span className="jobs-location-name jobs-details-location">
                  {value.city}
                </span>
              </div>
              <div className="jobs-gallery-container"></div>
              <Carousel
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                autoPlay={false}
              >
                {value.images.map((val, i) => (
                 <div className="jobs-img-expand">
                 <div className="jobs-gallery-tag tag-shortlisted"><i className="bi bi-tag" />Shortlisted</div>
                 <img src={`${API_BASE_URL}/${val}`} />
             </div>
                ))}
              </Carousel>
            </div>
            <div className="jobs-apply-container">
              <p className="jobs-description">{value.jobDescription}</p>
              <span className="jobs-title">
                ხელოსნის გადასახადი შერჩევის შემდეგ:
              </span>
              <span className="job-poster fee">{value.fee && value.fee}</span>
              <p className="jobs-apply-info">
                ინტერესის გამოხატვა უფასოა. საფასურს იხდით მხოლოდ მაშინ, როდესაც
                მომხმარებელი შეგარჩევთ.
              </p>
            </div>
            <div className="apply-container">
              <p className="user-attribute">
                მოწვევაზე დათანხმების შემდეგ, თქვენ მყისიერად იღებთ მომხმარებლის
                საკონტაქტო ინფორმაციაზე წვდომას და მოგიწევთ შერჩევის საფასურის
                გადახდა.
              </p>
            </div>
            <div className="apply-container">
                                        <p className="interested">შერჩეული: 14</p>
                                        <span className="shortlisted">შერჩეული: 24</span>
                                    </div>
    
        
          </div>
        );
      };

    const renderJobDetailsMobile = (value) => {
        return (
          <div style={{background:'#f8f8f8', padding:'0 0 30px 0'}}>
          <div style={{width:'100%', height:'52px', background:'#eb6c20', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{position:'absolute', left: '0', marginLeft:'15px'}} onClick={()=>setSelecterItemObj(null) }> 
            <svg onClick={()=>setSelecterItemObj(null)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
      <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
             </div>
            <div style={{color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>საქმეების სია</div>
            
          </div>
          <div className=" col-12 tabcontent" id="tab-1">
            <div className="jobs-tabbed-gallery" style={{marginLeft:'0'}}>
              
             
             
              <div className="jobs-gallery-container"></div>
              <Carousel
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                autoPlay={false}
              >
                {value.images.map((val, i) => (
                  <div className="jobs-img-expand">
                    <img src={`${API_BASE_URL}/${val}`} />
                  </div>
                ))}
              </Carousel>
            </div>
           
            <div className="jobs-apply-container">
            <div style={{width:'100%', marginTop:'10px', display:'flex', justifyContent:'space-between'}} >
              <div style={{color:'#3989f4', fontSize:'14px', fontWeight:'bold'}}>{value.jobPostedBy}</div>
              <div style={{color:'#909090', fontSize:'12px'}}>{`${moment(value.postedDate.replace("Z", "")  ).fromNow()}`}</div>
            </div>
            <div style={{width:'100%',  marginTop:'10px', display:'flex', justifyContent:'space-between'}} >
              <div style={{color:'#2b2b2b', fontSize:'18px', fontWeight:'bold'}}>{value.jobCategoryName}</div>
              <div style={{color:'#3989f4', fontSize:'18px'}}>{value.budgetRange}</div>
            </div>
            <div style={{color:'#3989f4', fontSize:'18px', display:'flex', marginTop:'5px'}}><i className="bi bi-geo-alt jobs-details-location" style={{fontSize:'16px'}} /> 
            <p style={{fontSize:'14px', color:'#909090', marginTop:'5px'}}>{value.city}</p></div>
    
    
            
            </div>
            
      
            <div  style={{display:'flex',justifyContent:'space-between'}}>
                <p className="interested">შერჩეული: 12</p>
                <span className="shortlisted">შერჩეული: 24</span>
                 </div>
                 
        
          </div>
          </div>
        );
      };


      const renderContentByDevice = () => {
        if (isMobile) {
          if (selecterItemObj) {
            return renderJobDetailsMobile(selecterItemObj);
          }
           return renderJobList();
        }
        return renderJobList();
      };
    





    return (
        <>
         { isLoading ?
           <div className="loader-wrapper">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>:
        <>


            {/*Interested JOBS TAB*/}

{renderContentByDevice()}


            {/* <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="all-jobs">
                <div className="row no-gutters jobs-filter">
                    <div className="col-4">
                        <img className="ic-search" src="../resources/icons/search-icon.png" />
                        <div className="input-group mb-2">
                            <input type="text" className="form-control search-form" id="jobsFilter" placeholder="მოძებნე კატეგორიით..." />
                        </div>
                    </div>
                </div>
                <div className="row no-gutters">
                    <div className="col-6">
                        <div className="jobs-cat-inner">

                            {interestedJobs ? interestedJobs.map((value, index) => (
                                <div className={index === activeTabIndex ? "jobs-content jobs-tablinks active" : "jobs-content jobs-tablinks"} onClick={() => setActiveTabIndex(index)} id="defaultOpen">
                                    <div className="row no-gutters">
                                        <div className="jobs-img-container">
                                            <img src="../images/gallery/house.jpg" className="jobs-img" />
                                        </div>
                                        <div className="col jobs-inner">
                                            <p className="jobs-title">{value.jobTitle}</p>
                                            <p className="user-attribute">{value.jobCategoryName}</p>
                                            <p className="jobs-price">{value.budgetRange}</p>
                                            {value.invited ? <p className="jobs-invited"><i className="bi bi-check2 invited-check" />Invited</p> : ''}
                                        </div>
                                    </div>
                                    <div className="row no-gutters jobs-content-footer">
                                        <div className="jobs-location">
                                            <i className="bi bi-geo-alt" /><span className="jobs-location-name">{value.city}</span>
                                        </div>
                                        <span className="jobs-post-date">{`დაემატა ${moment(value.postedDate.replace('Z', '')).fromNow()}`}</span>
                                    </div>
                                </div>)) : ''}
                        </div>
                        
                    </div>
                    {interestedJobs ? interestedJobs.map((value, index) => (
                        index === activeTabIndex ? <div className="col-6 tabcontent-1" id="tab-1">
                            <div className="jobs-tabbed-gallery">
                                <div className="jobs-details-container">
                                    <p className="job-poster">{value.jobPostedBy}</p>
                                    <span className="jobs-price-label">კლიენტის ბიუჯეტი: <span className="jobs-price">{value.budgetRange}</span></span>
                                </div>
                                <div className="jobs-details-container">
                                    <p className="jobs-category">{value.jobCategoryName}</p>
                                    <p className="jobs-post-date no-margin">{`${moment(value.postedDate.replace('Z', '')).fromNow()}`}</p>
                                </div>
                                <div className="jobs-location user-attribute">
                                    <i className="bi bi-geo-alt jobs-details-location" /><span className="jobs-location-name jobs-details-location">{value.city}</span>
                                </div>
                                <div className="jobs-gallery-container">
                                    
                                </div>

                               
                                 <Carousel showStatus={false} showArrows={false} showIndicators={false} autoPlay={false}>
                                            {
                                                value.images.map((val, i) =>
                                                    <div className="jobs-img-expand">
                                                        <img src={`${API_BASE_URL}/${val}`} />
                                                    </div>
                                                )
                                            }
                                        </Carousel>
                            </div>
                            <div className="jobs-apply-container">
                                <p className="jobs-apply-info">{value.jobDescription}
                                </p>
                                <span className="jobs-title">ხელოსნის გადასახადი შერჩევის შემდეგ:</span><span className="job-poster fee">{value.fee && value.fee}</span>
                                <p className="jobs-apply-info">NB! need to figure out what this is In publishing and graphic design, Lorem ipsum is a placeholder text</p>
                            </div>
                            <div className="apply-container">
                                <p className="interested">დაინტერესებული: 12</p>
                                <span className="shortlisted">შერჩეული: 24</span>
                            </div>
                            <button type="button" className="btn btn-primary btn-lg btn-block apply-btn" data-toggle="modal" data-target="#formModal" data-dismiss="modal">View form</button>
                        </div> : '')) : ''}
                </div>
            </div> */}
            {showChat ?
                <div className="modal fade" id="applyModal" tabIndex={-1} aria-labelledby="applyModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applyModalLabel">Apply form</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="jobs-apply-modal">You will be charged 5₾ and you will get contact details of the customer</p>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Start date" onfocus="(this.type='date')" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control jobs-work-time-txt" placeholder="Work time" />
                                    <select className="jobs-work-time">
                                        <option selected>Day</option>
                                        <option>Week</option>
                                        <option>Month</option>
                                    </select>
                                </div>
                                <div className="form-group jobs-salary">
                                    <input type="checkbox" className="jobs-salary-checkbox" id="salary" />
                                    <label htmlFor="salary" className="jobs-salary-label">I will tell price &amp; time later</label>
                                </div>
                                <div className="form-group">
                                    <select className="form-control">
                                        <option disabled selected>Choose your estimate salary</option>
                                        <option className="default-font">Acceptable budget</option>
                                        <option className="default-font">Cheap on a budget</option>
                                        <option className="default-font">Expensive on a budget</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-block default-font" data-toggle="modal" data-target="#applySuccessModal" data-dismiss="modal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}
            {/*APPLY SUCCESS MODAL*/}
            {showChat ?
                <div className="modal fade" id="applySuccessModal" tabIndex={-1} aria-labelledby="applySuccessModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="applySuccessModalLabel">Apply form</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <i className="bi bi-check2 jobs-apply-success-icon" />
                                <p className="jobs-invited apply-success">Your form has successfully sent</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-block default-font" data-toggle="modal" data-target="#formModal" data-dismiss="modal">View your form</button>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}
        </>
        }
        </>
    )
}

export default UnavailableJobs;