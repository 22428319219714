import { API_BASE_URL } from "../../constants/apiConstants";


const axios = require("axios");
const categories = [];
const regLabels = localStorage.getItem('reg_labels');
const businessTypeList = localStorage.getItem('businessTypeList');

const getCategories = async (language) => {
   
  try {
    const resp = await axios.get(
      API_BASE_URL + `/api/content/${language}/type/jobCategories/true`
    );
    if (categories.length == 0) categories.push(resp.data[0]);
    
    const { Children } = resp.data[0];

    localStorage.setItem('reg_categories', JSON.stringify(Children));
  } catch (err) {
    console.error(err);
  }
};

const getRegistrationFields = async () => {
  try {
    const resp = await axios.get(
      API_BASE_URL + "/api/content/ka/type/registration/true"
    );
    const { Properties } = resp.data[0];
    const { Children } = resp.data[0];
    const dropdown = Children[0].Children;
    const mobileVerificationLabels = Children[1].Properties;

    localStorage.setItem('mobileVerificationLabels', JSON.stringify(mobileVerificationLabels));
    localStorage.setItem('businessTypeList', JSON.stringify(dropdown));
    localStorage.setItem('reg_labels', JSON.stringify(Properties));

  //  console.log(Properties);
  } catch (err) {
    console.error(err);
  }
};

const getAddressList = async () => {
  try {
    const resp = await axios.get(
      API_BASE_URL + "/api/content/ka/type/addressList/true"
    );
    const { Properties } = resp.data[0];
    const { ditrictNames } = Properties;
    // console.log("Address List: ", ditrictNames)

   // console.log(Properties);
  } catch (err) {
    console.error(err);
  }
};

getAddressList();

getRegistrationFields();

getCategories();

export { categories, getCategories, getRegistrationFields, getAddressList };