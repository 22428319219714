import './landing-page.scss';

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getRegistrationFields, getCategories } from '../../services/content';

function LandingPage(props) {
  const ContactiUsername = Cookies.get('ContactiUsername');
  // const test = getRegistrationFields();
  const test2 = getCategories(props.languge);

  console.log(test2);

  const regObj = localStorage.getItem('reg_labels');
  const regLabels = JSON.parse(regObj);

  return (
    <>
      <div className="card col-12 col-lg-3 login-card mt-2 hv-center">
        <div className="form-wrapper">
          <div className="landing-header">
            <img src="images/logo.png" />
          </div>
        </div>
      </div>

      { ContactiUsername ?
        <div>
          <div className="content">
            {regLabels.landingPreRegLabel}
          </div>
        </div> :
        <div>
          <div className="content">
          {regLabels.landingPagePostRegLabel}
          </div>
          <a type="button" href="/register" className="btn btn-primary btn-block pre-register" id="next">{regLabels.preRegistrationBtnLabel}</a>
        </div>
      }
    </>
  );
}

export default withRouter(LandingPage);
