import React from "react";
import "./about.scss";

const AboutUs = () => {
  return (
    <div>
      <div className="hero-text-ab">
        <h1>
          <p className="banner-top-main-text">რას ვაკეთებთ</p>
        </h1>
      </div>

      <div className="hero">
        <img src="images/about.png" width="100%" height="207px" />
      </div>
      <div className="white-space"></div>

      <div className="container about-text-container">
        <div className="col-lg-12">
          Contacti არის ახალი სტარტაპი, რომელიც სერვისის მომწოდებლებს და
          მომხმარებლებს აერთიანებს ერთ სივრცეში. <br />
          <br />
          ჩვენი პლატფორმის მიზანია ნდობასთან და ხარისხთან დაკავშირებული
          პრობლემების გადაჭრა, რომელიც დიდი გამოწვევაა მომსახურების სფეროში.{" "}
          <br />
          <br />
          ჩვენი პროდუქტის დახმარებით, წინა მომსახურების შეფასებების
          გათვალისწინებით, თქვენ მარტივად დაიქირავებთ თქვენთვის სასურველ სანდო
          პროფესიონალს. <br />
          <br />
          თავის-მხრივ, Contacti ზრუნავს იმ პროფესიონალებზე, ვინც თავის საქმეს
          კვალიფიციურად და პასუხისმგებლობით ასრულებს. შესაბამისად, მათ ენიჭებათ
          უფრო მაღალი რეიტინგი, რომლის საფუძველზეც მათ სერვისზე უფრო მაღალი
          მოთხოვნაა.
        </div>
      </div>
      <div className="white-space"></div>
    </div>
  );
};

export default AboutUs;
