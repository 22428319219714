import './customer-jobs.scss';

import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ka'
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago';
import ka from 'javascript-time-ago/locale/ka.json'
import geFlag from '../../../resources/flags/ge.png'
import engFlag from '../../../resources/flags/gb.png'

import { API_BASE_URL } from '../../../constants/apiConstants';
import { id } from 'date-fns/locale';

const CustomerJobs = () => {

  const history = useHistory();
  const location = useLocation();
  let { jobid } = useParams();
  TimeAgo.addLocale(ka)
  const [isLoading, setIsLoading] = useState(false);
  const [hiredSpDetails, setHiredSpDetails] = useState([]);
  const [iterestedSps, setinterestedSps] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSuccessFeedbackModal, setShowSuccessFeedbackModal] = useState(false);
  const [jobViewVisible, setJobViewVisible] = useState(false);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [jobsData, setJobsData] = useState([])
  const [showRemoveJobModal, setShowRemoveJobModal] = useState(false);
  const [inHiredState, setInHiredState] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [job, setJob] = useState();
  const [activeSPId, setActiveSPId] = useState(-1);
  const [activeSP, setActiveSP] = useState(null);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(
    location.state?.serviceProviders[0]
      ? location.state?.serviceProviders[0].mobileNumber
      : -1
  );
  const [jobdetails, setJobdetails] = useState();
  const [jobDescrText, setJobDescrText] = useState(false);
  const [chatModalVisible, setChatModalVisible] = useState(false);
  const [feedbackRating, setFeedbackRating] = useState('');
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [mustUpdateSPs, setMustUpdateSPs] = useState(0);
  const [interested, setInterested] = useState([]);
  const [shortlisted, setShortlisted] = useState([]);
  const [hired, setHired] = useState([]);

  useEffect(() => {
    const getAllSps = `${API_BASE_URL}/api/cs/jobs/get/allstates/${jobid}`;

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: getAllSps,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    setinterestedSps([]);
    setServiceProviders([]);
    setHired([]);

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await authAxios.post(`${getAllSps}`);
        // Filter only hired SP from the fetched list of all SPs (Hired/Interested)
        const { hiredSps } = result.data.filter((hiredSp) => {
          return hiredSp.jobState === 'Hired';
        });

        setinterestedSps(result.data);
        setServiceProviders(result.data);
        setHired(...hiredSps);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log("Request faied", err);
      }
    };

    fetchData();



  }, [])


  useEffect(() => {

    if (iterestedSps.length > 0) {
      setActiveSPId(iterestedSps[0]?.id)
      setActiveSP(iterestedSps[0])
    }else{
      setJobViewVisible(true)
    }

  }, [iterestedSps])


  const handleChange = (e) => {
    const { value } = e.target;
    setFeedbackDescription(value);
  };

  function formatDate(string) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString('ka-GE', options);
  }

  const addFeedback = async (e) => {
    e.preventDefault();

    if (feedbackRating && feedbackRating !== '') {

      const updateUrl = API_BASE_URL + "/api/cs/jobs/feedback/add";

      const authAxios = axios.create({
        withCredentials: false,
        baseURL: updateUrl,
        headers: {
          Authorization: `Bearer ${cookies.ContactiAccessToken}`
        }
      });

      await authAxios.post(`${updateUrl}`, {
        "serviceProviderId": activeSPId,
        "jobPostId": job?.id,
        "feedbackRating": feedbackRating,
        "feedbackDescription": feedbackDescription
      });

      setFeedbackRating('');
      setFeedbackDescription('');
      setShowFeedbackModal(false);
      setShowSuccessFeedbackModal(true)
    }
  };


  useEffect(() => {

    setJob(jobsData && jobsData[0])
    setJobdetails(jobsData && jobsData[0])
  }, [jobsData]);

  useEffect(() => {

    if (jobdetails) {
      setJobViewVisible(true)
    }

    if (serviceProviders) {
      const shortlisted = serviceProviders.filter(function (item) {
        return item.jobState === 'Shortlisted';
      });

      const hired = serviceProviders.filter(function (item) {
        return item.jobState === 'Hired';
      });

      const interested = serviceProviders.filter(function (item) {
        return item.jobState === 'Interested';
      });

      setInterested(interested);
      setHired(hired);
      setShortlisted(shortlisted);
      setInHiredState((hired && hired.length > 0));
    }
  }, [mustUpdateSPs, serviceProviders]);

  useEffect(() => {

    const feedbackUrl = `${API_BASE_URL}/api/cs/jobs/feedbacks/get/${activeSPId}`;

    const feedbackAxios = axios.create({
      withCredentials: false,
      baseURL: feedbackUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchFeedbackData = async () => {
      try {
        //setIsLoading(true);
        const result = await feedbackAxios.post(`${feedbackUrl}`);
        setFeedbacks(result.data);
        //setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };

    const portfolioUrl = `${API_BASE_URL}/api/sp/portfolio/get/${activeSPId}`;
    const portfolioAxios = axios.create({
      withCredentials: false,
      baseURL: portfolioUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });





    const fetchPortfolioData = async () => {
      try {
        //setIsLoading(true);
        const result = await portfolioAxios.post(`${portfolioUrl}`);
        setPortfolio(result.data);
        //setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };


    const jobsUrl = `${API_BASE_URL}/api/cs/jobs/get`;
    const jobsAxios = axios.create({
      withCredentials: false,
      baseURL: jobsUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchJobsData = async () => {
      try {
        const result = await jobsAxios.post();
        // setJobsData(result.data);
        setJobsData(result.data.filter(item => {
          return item.id === Number(jobid)
        }))


      } catch (err) {
        console.log("Request faied", err);
      }
    };


    const certificateUrl = `${API_BASE_URL}/api/sp/certificates/get/${activeSPId}`;
    const certificateAxios = axios.create({
      withCredentials: false,
      baseURL: certificateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchCertificateData = async () => {
      try {
        //setIsLoading(true);
        const result = await certificateAxios.post(`${certificateUrl}`);
        setCertificates(result.data);
        //setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };
    fetchFeedbackData()
    fetchPortfolioData()
    fetchCertificateData();
    fetchJobsData();
  }, [activeSPId, cookies.ContactiAccessToken]);




  const updateJobState = async (evt, sp, state) => {
    evt.preventDefault();
    if (!inHiredState) {
      const updateUrl = API_BASE_URL + "/api/cs/jobs/state/update";

      const authAxios = axios.create({
        withCredentials: false,
        baseURL: updateUrl,
        headers: {
          Authorization: `Bearer ${cookies.ContactiAccessToken}`
        }
      });

      await authAxios.post(`${updateUrl}`, { "serviceProviderId": sp.id, "jobPostId": job?.id, "jobState": state });

      const allstates = await serviceProviders.map(function (item) {
        if (item.id === sp.id) item.jobState = state;
        return item;
      });

      setServiceProviders(allstates);

      history.replace({
        state: {
          job,
          serviceProviders: allstates
        }
      });

      setMustUpdateSPs(Math.floor((Math.random() * 100000000000) + 1));
    }
  };

  const openTab = (evt, tabName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("jobs-tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  function feedback_pos() {
    document.getElementById("feedback-pos").style.opacity = "1";
    document.getElementById("feedback-neu").style.opacity = ".4";
    document.getElementById("feedback-neg").style.opacity = ".4";
  }

  function feedback_neu() {
    document.getElementById("feedback-pos").style.opacity = ".4";
    document.getElementById("feedback-neu").style.opacity = "1";
    document.getElementById("feedback-neg").style.opacity = ".4";
  }

  function feedback_neg() {
    document.getElementById("feedback-pos").style.opacity = ".4";
    document.getElementById("feedback-neu").style.opacity = ".4";
    document.getElementById("feedback-neg").style.opacity = "1";
  }

  function editCustomerJobs() {
    // document.getElementById("customer-jobs-gallery").style.display ="block";
    // document.getElementById("customer-jobs-gallery-del").style.display ="block";
    document.getElementById("customer-edit-container").style.display = "block";
  }

  function viewCustomerJobs() {
    // document.getElementById("customer-jobs-gallery").style.display ="none";
    // document.getElementById("customer-jobs-gallery-del").style.display ="none";
    // document.getElementById("customer-edit-container").style.display = "none";
  }

  function remove_customerJobImg() {
    /* var expandImg = document.getElementById("expandedImg");
     expandImg.src = imgs.src;
     expandImg.parentElement.style.display = "block";*/
    document.getElementById('job-img1').remove();
    document.getElementById('job-img1-indicator').remove();
  }



  const remove_customerJob = async () => {
    const removeSelJobId = location.state.job?.id;
    const removeJobUrl = `${API_BASE_URL}api/cs/jobs/delete/${removeSelJobId}`
    const authAxios = axios.create({
      withCredentials: false,
      baseURL: removeJobUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const result = await authAxios.delete(removeJobUrl);
    const data = result.status;

    if (data === 200) {
      setShowRemoveJobModal(false);
      history.push("/customer/customer-profile")
    }

  };





  const edit_customerJob = async () => {
    const editSelJobId = location.state.job?.id;
    const editJobUrl = `${API_BASE_URL}api/cs/jobs/update-description`;

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: editJobUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const result = await authAxios.post(`${editJobUrl}`, {
      "id": editSelJobId,
      "jobDescription": jobDescrText,
    });

    const data = result.data;
    if (data) {

      setJob((prevState) => ({
        ...prevState,
        jobDescription: jobDescrText,
      }));

    }

  };

  const returnJobStatesMobile = (SpJobState) => {
    const allStates = {
      INTERESTED: "დაინტერესებული",
      SHORTLISTED: "შერჩეული",
      HIRED: "დაქირავებული",
    }

    switch(SpJobState) {
      case 'Interested':
        return allStates.INTERESTED;
      case 'Shortlisted':
        return allStates.SHORTLISTED;
      case 'Hired':
        return allStates.HIRED;
      default:
        return '';
    }
  };

  function returnFeedbackEmoji(value) {

    switch(value.feedbackRating) {
      case 'Positive':
        return (
          <div style={{display:'flex', alignItems:'center'}}>
            <img src="../../resources/icons/positive-emoji.png" alt="positive rating" style={{width:'20px', height:'20px'}}/>&nbsp;
            <div style={{color:'#0cb458', fontWeight:'bold'}}>პოზიტიური</div>            
          </div>
        )
      case 'Neutral':
        return (
          <div style={{display:'flex', alignItems:'center'}}>
            <img src="../../resources/icons/neutral-emoji.png" alt="positive rating" style={{width:'20px', height:'20px'}}/>&nbsp;
            <div style={{color:'black', fontWeight:'bold'}}>ნეიტრალური</div>
          </div>
        )
      case 'Negative':
        return (
          <div style={{display:'flex', alignItems:'center'}}>
            <img src="../../resources/icons/negative-emoji.png" alt="positive rating" style={{width:'20px', height:'20px'}}/>&nbsp;
            <div style={{color:'red', fontWeight:'bold'}}>ნეგატიური</div>
          </div>
        )
      default:
        return '';
    }
     
  }




  function editJob() {
    document.getElementById("edit-job").style.display = "none";
    document.getElementById("submit-edited-job").style.display = "block";
    document.getElementById("customer-jobs-gallery").style.display = "block";
    document.getElementById("customer-jobs-gallery-del").style.display = "block";
    document.getElementById("customer-edit-container").style.display = "block";
  }

  function submitEditedJob() {
    document.getElementById("edit-job").style.display = "block";
    document.getElementById("submit-edited-job").style.display = "none";
    document.getElementById("customer-jobs-gallery").style.display = "none";
    document.getElementById("customer-jobs-gallery-del").style.display = "none";
    document.getElementById("customer-edit-container").style.display = "none";
  }

  function myFunction(imgs) {
    var expandImg = document.getElementById("expandedImg");
    expandImg.src = imgs.src;
    expandImg.parentElement.style.display = "block";
  }

  function openTemplateToast() {
    document.getElementById("chat-template").style.display = "block";
  }

  function showJobTemplate() {
    var template = document.getElementById("templateTxt").textContent;
    //document.getElementById("catRow").style.display = "flex";
    // Displaying the value
    document.getElementById("chat-template-msg-txt").innerHTML = template;
    document.getElementById("chat-template-msg").style.display = "flex";

  }

  function closeChatTemplate() {
    document.getElementById("chat-template").style.display = "none";
  }

  function sendMessage() {
    var Message = document.getElementById("MessageTxt").value;
    // Displaying the value
    document.getElementById("chat-send-msg-txt").innerHTML = Message;
    document.getElementById("chat-send-msg").style.display = "flex";

    const monthNames = ["იან", "თებ", "მარ", "აპრ", "მაი", "ივნ",
      "ივლ", "აგვ", "სექ", "ოქტ", "ნოე", "დეკ"];
    const hoursFormat = ["00", "01", "02", "03", "04", "05",
      "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17",
      "18", "19", "20", "21", "22", "23"];
    const minutesFormat = ["00", "01", "02", "03", "04", "05",
      "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17",
      "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31",
      "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46",
      "47", "48", "49", "50", "51", "52", "53", "54", "55",
      "56", "57", "58", "59"];

    var today = new Date();
    var time = hoursFormat[today.getHours()] + ":" + minutesFormat[today.getMinutes()];
    var date = today.getDate() + " " + monthNames[today.getMonth()];

    document.getElementById("chat-time").innerHTML = time;
    document.getElementById("chat-date").innerHTML = date;
  }



  const renderTimeNeededandBudget = () => {

    if (!activeSP?.workTimeNumberOfUnit && !activeSP?.estimateSalary) {
      return (
        <div style={{fontSize:'13px', textAlign:"justify"}}>ვნახავ რა კატეგორიის სამუშაოა და შემდგომ ვიტყვი ფასსა და სამუშაოს შესრულებისთვის საჭირო დროს</div>
      )
    }
    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <div>რა დრო დაგჭირდებათ?</div>
          <div style={{ fontWeight: 'bold' }}>{activeSP?.workTimeNumberOfUnit} {activeSP?.workTimeUnit}</div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <div>საორიენტაციო ბიუჯეტი</div>
          <div style={{ fontWeight: 'bold' }}>{activeSP?.estimateSalary} ლარი </div>
        </div>
      </div>

    )
  }



  return (
    <div>
      <div className="sp-listing-bg">
        <div className="container">
          <p className="profile-title">გამოხმაურებები</p>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters"><div className="col-lg-12 profile-tabbed-menu">
          <div className="tabbed-card jobs-tabbed-menu">
            {/*JOBS TABBED*/}
            <div className="tab-content" id="myTabContent">
              {/*NEW JOBS TAB*/}
              <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="all-jobs">
                <div className="row no-gutters">
                  <div className="col-6">
                    <div className="customer-jobs-inner">
                      <div className="customer-jobs-menu">
                        <div className="col-4 customer-job-page">
                          <button onClick={(event) => { setJobViewVisible(true); viewCustomerJobs(); }}>საქმის გადახედვა</button>
                        </div>
                        <div className="col-4 edit-customer-job-page">
                          <button onClick={(event) => { setJobViewVisible(true); editCustomerJobs(); }}>საქმის დარედაქტირება</button>
                        </div>
                        <div className="col-4 delete-customer-job-page">
                          <button onClick={() => setShowRemoveJobModal(true)} data-toggle="modal">საქმის წაშლა</button>
                        </div>
                      </div>
                      {/* {showRemoveJobModal ?
                        <div className="modal fade" id="removeJobModal" tabIndex={-1} aria-labelledby="removeJobModal" aria-hidden="true">
                          <div className="modal-dialog modal-lg remove-job-modal">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="removeJobModal">Reason of removing the job</h5>
                                <button type="button" className="close" onClick={() => setShowRemoveJobModal(false)} aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="remove-job-modal-body">
                                <div className="form-check remove-job-options">
                                  <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option1" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option1">
                                    I don't want to post a job
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="form-check remove-job-options">
                                  <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option2" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option2">
                                    I have already found service provider outside of platform
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="form-check remove-job-options">
                                  <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option3" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option3">
                                    I haven't found anyone on this platform
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="form-check remove-job-options">
                                  <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option4" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option4">
                                    This platform is clumsy
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="form-check remove-job-options">
                                  <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option5" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option5">
                                    I want to post a job from the beginning
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="form-check remove-job-options remove-job-radio">
                                  <input className="form-check-input" type="radio" name="exampleRadios" id="option6" defaultValue="option1" />
                                  <label className="form-check-label paid-invoices" htmlFor="option6">
                                    Other reason
                                  </label>
                                </div>
                                <hr className="remove-job-options-hr" />
                                <div className="input-group modal-body">
                                  <textarea className="form-control" aria-label="With textarea" rows={5} placeholder="Write review comment" defaultValue={""} />
                                  <img src="../resources/icons/pencil.png" width="17px" height="17px" className="remove-job-pencil-icon" />
                                  <p className="remove-job-max-symbols">0 / 200 symbols</p>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-primary btn-block default-font" data-dismiss="modal" onClick={() => remove_customerJobImg()}>შენახვა</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ''
                      } */}

                      {showRemoveJobModal &&
                        (<div className="modal fade" id="removeJobModal" tabIndex={-1} aria-labelledby="removeJobModal" aria-hidden="true">
                          <div className="modal-dialog modal-sm">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="delCustomerJobImgModal">საქმის წაშლა</h5>
                                <button type="button" className="close" onClick={() => setShowRemoveJobModal(false)} aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <p className="default-font">ნამდვილად გსურთ რომ წაშალოთ საქმე?
                                </p>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-danger default-font" data-dismiss="modal" onClick={() => remove_customerJob()}>წაშლა</button>
                              </div>
                            </div>
                          </div>
                        </div>)
                      }



                      {/* <p className="customer-jobs-posted">Many desktop publishing packages and web page editors now use Lorem Ipsum
                        as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.</p> */}
                      <div className="sp-list-container">

                        {hired && hired.length > 0 ? <><span className="customer-jobs-hired-sp"> დაქირავებული </span><span className="jobs-title">{`SP (${hired.length})`}</span></> : ''}
                        {serviceProviders ? serviceProviders.map((value, index) => (
                          value.jobState === 'Hired' ? <div className={`jobs-content customer-jobs jobs-tablinks ${value.id === activeSPId ? 'active' : ''}`} onClick={() => { setJobViewVisible(false); setActiveSPId(value.id); setPhoneNumber(job?.jobStates[index]?.SpMobileNumber); setActiveSP(value) }}>
                            <div className="row no-gutters">
                              <div className="jobs-img-container">
                                <img src={value.Avatar ? `${API_BASE_URL}${value.Avatar}` : "../../images/profile-picture.jpg"} className="sp-listing-img" />
                              </div>
                              <div className="col jobs-inner">
                                <p className="jobs-title">{`${value.firstName} ${value.lastName}`}</p>
                                <p className="job-poster">{value.jobCategory}</p>
                                <p className="feedback-positive sp-listing-feedback">{value.positiveFeedbackPercentage}% პოზიტიური შეფასება</p>
                              </div>
                            </div>
                            <div className="jobs-content-footer sp-customerView-msg">თქვენ გაქვთ 1 ახალი შეტყობინება</div>
                          </div> : '')) : ''}
                        {shortlisted && shortlisted.length > 0 ? <><span className="customer-jobs-shortlisted-sp">შერჩეული</span><span className="jobs-title">{`SP (${shortlisted.length})`}</span></> : ''}
                        {serviceProviders ? serviceProviders.map((value, index) => (
                          value.jobState === 'Shortlisted' ? <div className={`jobs-content customer-jobs jobs-tablinks ${value.id === activeSPId ? 'active' : ''}`} onClick={() => { setJobViewVisible(false); setPhoneNumber(job?.jobStates[index]?.SpMobileNumber); setActiveSPId(value.id); setActiveSP(value) }}>
                            <div className="row no-gutters">
                              <div className="jobs-img-container">
                                <img src={value.Avatar ? `${API_BASE_URL}${value.Avatar}` : "../../images/profile-picture.jpg"} className="sp-listing-img" />
                              </div>
                              <div className="col jobs-inner">
                                <p className="jobs-title">{`${value.firstName} ${value.lastName} `}</p>
                                <p className="job-poster">{value.jobCategory}</p>
                                <p className="feedback-positive sp-listing-feedback">{value.positiveFeedbackPercentage}% პოზიტიური შეფასება</p>
                              </div>
                            </div>
                          </div> : '')) : ''}
                        {interested && interested.length > 0 ? <><span className="customer-jobs-interested-sp">დაინტერესებული</span><span className="jobs-title">{`კონტაქტები (${interested.length})`}</span></> : ''}
                        {serviceProviders ? serviceProviders.map((value, index) => (
                          value.jobState === 'Interested' ? <div className={`jobs-content customer-jobs jobs-tablinks ${value.id === activeSPId ? 'active' : ''}`} onClick={() => { setJobViewVisible(false); setActiveSPId(value.id); setPhoneNumber(job?.jobStates[index]?.SpMobileNumber); setActiveSP(value) }}>
                            <div className="row no-gutters">
                              <div className="jobs-img-container">
                                <img src={value.Avatar ? `${API_BASE_URL}${value.Avatar}` : "../../images/profile-picture.jpg"} className="sp-listing-img" />
                              </div>
                              <div className="col jobs-inner">
                                <p className="jobs-title">{`${value.firstName} ${value.lastName}`}</p>
                                <p className="job-poster">{value.jobCategory}</p>
                                <p className="feedback-positive sp-listing-feedback">{value.positiveFeedbackPercentage}% პოზიტიური შეფასება</p>
                              </div>
                            </div>
                          </div> : '')) : ''}
                      </div>
                    </div>
                    {/* <div className="feedback-pagination">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Previous">
                              <span aria-hidden="true">&lt;</span>
                              <span className="sr-only">Previous</span>
                            </a>
                          </li>
                          <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                          <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                          <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                          <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Next">
                              <span aria-hidden="true">&gt;</span>
                              <span className="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> */}
                  </div>
                  {jobViewVisible ? <div className="col-6 tabcontent" id="tab-1">
                    <div className="jobs-tabbed-gallery">
                      <div className="jobs-details-container">
                        <p className="job-poster">{job?.jobPostedBy}</p>
                        <span className="jobs-price-label">კლიენტის ბიუჯეტი:<span className="jobs-price">{job?.budgetRange}</span></span>
                      </div>
                      <div className="jobs-details-container">
                        <p className="jobs-category">{job?.jobCategoryName}</p>
                        <p className="jobs-post-date no-margin"><ReactTimeAgo date={moment(job?.postedDate?.replace('Z', ''))} locale="ka" /></p>
                      </div>
                      <div className="jobs-location user-attribute">
                        <i className="bi bi-geo-alt jobs-details-location" /><span className="jobs-location-name jobs-details-location">{`${job?.district}, ${job?.city}`}</span>
                      </div>
                      <div className="jobs-gallery-container customer-jobs-gallery">
                        <div className="customer-gallery-inner">
                          {job?.images ? job?.images.map((image, id) =>
                            <div className="customer-jobs-gallery-img">
                              <img src={API_BASE_URL + image} className="customer-jobs-img" id={id} onClick={() => myFunction(this)} />
                            </div>
                          ) : ""}
                          {/* <div className="customer-jobs-gallery-img" id="job-img1">
                            <img src={"../images/gallery/house.jpg"} className="customer-jobs-img" onClick={() => myFunction(this)} />
                          </div>
                          <div className="customer-jobs-gallery-img">
                            <img src="../images/gallery/interior.jpg" className="customer-jobs-img" id="job-img2" onClick={() => myFunction(this)} />
                          </div>
                          <div className="customer-jobs-gallery-img">
                            <img src="../images/gallery/house.jpg" className="customer-jobs-img" id="job-img3" onClick={() => myFunction(this)} />
                          </div>
                          <div className="customer-jobs-gallery-img">
                            <img src="../images/gallery/interior.jpg" className="customer-jobs-img" id="job-img4" onClick={() => myFunction(this)} />
                          </div>
                          <div className="customer-jobs-gallery-img">
                            <img src="../images/gallery/house.jpg" className="customer-jobs-img" id="job-img5" onClick={() => myFunction(this)} />
                          </div> */}
                        </div>
                      </div>
                      <div className="jobs-img-expand">
                        <div id="customer-jobs-gallery">
                          <form action method="post" encType="multipart/form-data">
                            <input type="file" multiple className="custom-file-input" name="image" id="fileToUpload" onChange="SelectChange()" />
                            <label className="customer-jobs-addImg-btn" id="uploadLabel" htmlFor="image"><i className="bi bi-plus plus-icon" /> ფოტოს დამატება</label>
                          </form>
                        </div>
                        <div id="customer-jobs-gallery-del">
                          <div className="customer-jobs-del-btn" data-toggle="modal" data-target="#delCustomerJobImgModal"><i className="bi bi-trash trash-icon" /> წაშლა</div>
                        </div>
                        {/* <img src={API_BASE_URL+job?.images[0]} className="jobs-galleryImg-lg" id="expandedImg" /> */}

                        {job && job?.images && job?.images[0] ? (
                          <img
                            src={`${API_BASE_URL}/${job?.images[0]}`}
                            alt="job preview"
                            className="jobs-galleryImg-lg" id="expandedImg"
                          />
                        ) : (
                          <img
                            src="../../images/no-image.jpg"
                            alt="job preview"
                            className="jobs-galleryImg-lg" id="expandedImg" 
                          />
                        )}



                      </div>
                      {/*<div className="jobs-img-expand no-photo">
                                              <img src="../resources/icons/no-photo-lg.png" className="no-photo-expanded" />
                                          </div>*/}
                    </div>
                    <div className="jobs-apply-container">
                      <p className="user-attribute" style={{color:'black', fontWeight:'bold', fontSize:'14px'}}>{job?.jobDescription}</p>
                      <div className="form-group" id="customer-edit-container">
                        <textarea className="form-control customer-job-description shadow-none" placeholder="დაამატეთ აღწერა..." rows={3} defaultValue={""} onChange={(e) => setJobDescrText(e.target.value)} />
                        <input className="btn btn-primary customer-edit-job-submit" value="შენახვა" type="submit" onClick={() => edit_customerJob()} defaultValue="შენახვა" />
                      </div>
                    </div>
                  </div> : ''}
                  <div className="modal fade" id="delCustomerJobImgModal" tabIndex={-1} aria-labelledby="delCustomerJobImgModal" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="delCustomerJobImgModal">ფოტოების წაშლა</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="default-font">ნამდვილად გსურთ რომ წაშალოთ ფოტო/ფოტოები?
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-danger default-font" data-dismiss="modal" onClick={() => remove_customerJobImg()}>წაშლა</button>
                          <button type="button" className="btn btn-danger default-font" data-dismiss="modal">ყველას წაშლა</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*2nd TAB*/}
                  {activeSPId !== -1 && !jobViewVisible ? <><div className="col-6 tabcontent">
                    <div className="jobs-tabbed-gallery">
                      <div className="jobs-details-container">
                        <p className="jobs-category">{`${activeSP?.firstName} ${activeSP?.lastName}`}</p>
                        <div className="job-top-section-right jobs-apply-modal">
                          <i className="bi bi-geo-alt" /><span className="jobs-location-name">{activeSP?.address}</span>
                        </div>
                      </div>
                      <div className="jobs-details-container">
                        <p className="job-poster">{activeSP?.jobCategory}</p>
                      </div>
                      <span className="jobs-post-date">ენები:
                        <img src={geFlag} />
                        <img src={engFlag} />
                       
                      </span>
                      <div id="whoWeAre" className="carousel slide sp-profileView-slider" data-ride="carousel">
                        {/* Indicators */}
                        <ul className="carousel-indicators sp-indicators">
                          <li data-target="#whoWeAre" data-slide-to={0} className="active" />
                          <li data-target="#whoWeAre" data-slide-to={1} />
                          <li data-target="#whoWeAre" data-slide-to={2} />
                        </ul>
                        {/* The slideshow */}
                        <div className="carousel-inner">
                          <div className={`jobs-gallery-tag ${activeSP?.jobState === 'Hired' ? 'tag-hired' : activeSP?.jobState === 'Shortlisted' ? 'tag-shortlisted' : 'tag-interested'}`}><i className="bi bi-tag" />{' '}{returnJobStatesMobile(activeSP?.jobState)}</div>
                          <div className="carousel-item active">
                            <img src={activeSP.Avatar ? `${API_BASE_URL}${activeSP.Avatar}` : "../../images/profile-picture.jpg"} alt="photo not found" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp2.jpg" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp3.jpg" className="about-slider" />
                          </div>
                        </div>
                      </div>
                      <div className="sp-profileView-feedback">
                        <p className="feedback-positive job-top-section-left no-margin">{activeSP.positiveFeedbackPercentage}% პოზიტიური ფიდბექი</p>
                        <p className="jobs-price-label no-margin" data-toggle="modal" data-target="#AllFeedbacksModal" data-dismiss="modal">{`ყველა შეფასება (${feedbacks ? feedbacks.length : '0'})`}</p></div>

                      <div className="customer-jobs-inner">
                        <div className="profile-title portfolio-title">ჩემს შესახებ</div>
                        <p className="sp-certificate-title description-callback">{activeSP?.description}</p>

                        {portfolio.length > 0 ? (
                          <div>
                            <div className="profile-title portfolio-title">პორტფოლიო</div>
                            <div className="cata-sub-nav sp-portfolio">

                              {portfolio.length > 0 ? portfolio.map((value, index) => (
                                <div className="card sp-portfolio-img-container">
                                  <img src={`https://staging-cms.contacti.ge/${value.imageUrl}`} alt="" className="sp-portfolio-img" />
                                </div>
                              )) : <p className="jobs-title sp-certificate-title cert-fallback">ფოტოები არ არის დამატებული</p>
                              }

                            </div>
                          </div>
                        ) : (null)}

                        {certificates.length > 0 ? (
                          <div>
                            <div className="profile-title portfolio-title">სერტიფიკატები</div>
                            <div className="sp-certificates">
                              <div className={`col-lg-6 col-12 vacancy-row ${certificates.length <= 0 ? `cert-fallback` : ''}`}>
                                {certificates.length > 0 ? certificates.map((value, index) => (
                                  <div className="sp-certificate-container">
                                    <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                                    <p className="jobs-title sp-certificate-title">{value.title}</p>
                                  </div>
                                )) : <p className="jobs-title sp-certificate-title cert-fallback">სერტიფიკატები არ არის დამატებული</p>
                                }

                              </div>

                            </div>
                          </div>
                        ) : (null)}
                        {feedbacks.length > 0 ? (
                          <div>
                            <div className="profile-title portfolio-title" data-toggle="modal" data-target="#AllFeedbacksModal" data-dismiss="modal">{`ყველა შეფასება (${feedbacks ? feedbacks.length : '0'})`}</div>
                            <div className="all-feedbacks-nav sp-portfolio">
                              {feedbacks && feedbacks.length > 0 ? feedbacks.map((value, index) => (
                                <div className="card sp-portfolio-img-container">
                                  <div className="sp-allFeedbacks-container">
                                    <div className="jobs-details-container">
                                      <p className="jobs-title job-top-section-left">{value.feedbackByDisplayName}</p>
                                      <p className="vacancy-date job-top-section-right">{formatDate(value.feedbackDate)}</p>
                                    </div>
                                    <p className="contact-detail">{value.feedbackCategory}</p>
                                    <div className="jobs-content-footer no-margin">
                                    <p>{returnFeedbackEmoji(value)}</p>
                                    </div>
                                    <span className="quotation-mark">„</span>
                                    <span className="feedback-txt">{value.feedbackDescription}</span>
                                  </div>
                                </div>
                              )) : <p className="jobs-title sp-certificate-title cert-fallback">შეფასებები ჯერ არ დამატებულა</p>
                              }
                            </div>
                          </div>
                        ) : (null)}

                      </div>
                      {activeSP?.jobState === 'Shortlisted' ?
                        <>
                          <p className="not-found-txt sp-certificate-title">შეფასების დატოვებას შეძლებთ დაქირავების შემთხევაში</p>
                          <div className="sp-buttons-container">
                            <div className="col-6 sp-button-left">
                              <a >
                                <button type="button" className={`btn btn-primary btn-lg sp-buttons ${inHiredState ? 'disabled' : ''}`} data-toggle="modal" data-target="#chatModal" onClick={() => setChatModalVisible(true)} >ხელოსნის პირობები</button>
                                {/* <button type="button" className="btn btn-primary btn-lg accept-btn" data-toggle="modal" data-target="#feedbackModal" onClick={() => { setFeedbackSPId(value.hiredSpId); setFeedbackJobId(value.id); }}>შეფასების დამატება</button> */}

                              </a>
                            </div>
                            <div className="col-6 sp-button-right">
                              <button type="button" className={`btn btn-primary btn-lg sp-buttons ${inHiredState ? 'disabled' : ''}`} onClick={(event) => updateJobState(event, activeSP, 'Hired')}>დაქირავება</button>
                            </div>
                          </div>
                          <div className="col-12 sp-button-center">
                            <a href={`tel:${phoneNumber}`}>
                              <button type="button" className={`btn btn-primary btn-lg btn-block sp-buttons ${inHiredState ? 'disabled' : ''}`}><i className="bi bi-telephone" /> {phoneNumber} </button>
                            </a>
                          </div></> : ''}
                      {activeSP?.jobState === 'Hired' ?
                        <><p className="not-found-txt sp-certificate-title">შეფასების დატოვებას შეძლებთ დაქირავების შემთხევაში</p>
                          <div className="sp-buttons-container">
                            <div className="col-6 sp-button-left">
                              <button type="button" className="btn btn-primary btn-lg sp-buttons" data-toggle="modal" data-target="#chatModal" data-dismiss="modal" onClick={() => setChatModalVisible(true)}>ხელოსნის პირობები</button>
                            </div>
                            <div className="col-6 sp-button-right">
                              <button type="button" className="btn btn-primary btn-lg sp-buttons" onClick={() => setShowFeedbackModal(true)}>შეფასება</button>
                            </div>
                          </div>
                          <div className="col-12 sp-button-center">
                            <a href={`tel:${phoneNumber}`}>
                              <button type="button" className="btn btn-primary btn-lg btn-block sp-buttons"><i className="bi bi-telephone" /> {phoneNumber} </button>
                            </a>
                          </div></> : ''}
                      {activeSP?.jobState === 'Interested' ?
                        <div className="sp-buttons-container">
                          <div className="col-6 sp-button-left">
                            <button type="button" className={`btn btn-primary btn-lg sp-buttons `} data-toggle="modal" data-target="#chatModal" data-dismiss="modal" onClick={() => setChatModalVisible(true)}>ხელოსნის პირობები</button>
                          </div>
                          <div className="col-6 sp-button-right">
                            <button type="button" className={`btn btn-primary btn-lg sp-buttons `} onClick={(event) => updateJobState(event, activeSP, 'Shortlisted')}>შერჩევა</button>
                          </div>
                        </div> : ''}
                    </div>
                  </div>
                    {activeSP?.jobState === 'Hired' ?
                      <div className="modal fade" id="AllFeedbacksModal" tabIndex={-1} aria-labelledby="AllFeedbacksModal" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="AllFeedbacksModal">{`All Feedbacks (${feedbacks ? feedbacks.length : '0'})`}</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="jobs-details-container">
                                <p className="jobs-title job-top-section-left">Giorgi Nonikashvili</p>
                                <p className="vacancy-date job-top-section-right">2 Jan. 2021</p>
                              </div>
                              <p className="contact-detail">House Celaning</p>
                              <div className="jobs-content-footer no-margin">
                                <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                              </div>
                              <span className="quotation-mark">„</span>
                              <span className="feedback-txt">Great hotel with a good parking facility, room was clean and reception staff very friendly. Good value for money.</span>
                              <hr className="all-feedbacks-hr" />
                              <div className="jobs-details-container">
                                <p className="jobs-title job-top-section-left">Giorgi Nonikashvili</p>
                                <p className="vacancy-date job-top-section-right">2 Jan. 2021</p>
                              </div>
                              <p className="contact-detail">House Celaning</p>
                              <div className="jobs-content-footer no-margin">
                                <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                              </div>
                              <span className="quotation-mark">„</span>
                              <span className="feedback-txt">Great hotel with a good parking facility, room was clean and reception staff very friendly. Good value for money.</span>
                              <hr className="all-feedbacks-hr" />
                              <div className="jobs-details-container">
                                <p className="jobs-title job-top-section-left">Giorgi Nonikashvili</p>
                                <p className="vacancy-date job-top-section-right">2 Jan. 2021</p>
                              </div>
                              <p className="contact-detail">House Celaning</p>
                              <div className="jobs-content-footer no-margin">
                                <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                              </div>
                              <span className="quotation-mark">„</span>
                              <span className="feedback-txt">Great hotel with a good parking facility, room was clean and reception staff very friendly. Good value for money.</span>
                              <hr className="all-feedbacks-hr" />
                              <div className="jobs-details-container">
                                <p className="jobs-title job-top-section-left">Giorgi Nonikashvili</p>
                                <p className="vacancy-date job-top-section-right">2 Jan. 2021</p>
                              </div>
                              <p className="contact-detail">House Celaning</p>
                              <div className="jobs-content-footer no-margin">
                                <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                              </div>
                              <span className="quotation-mark">„</span>
                              <span className="feedback-txt">Great hotel with a good parking facility, room was clean and reception staff very friendly. Good value for money.</span>
                              <hr className="all-feedbacks-hr" />
                              <div className="jobs-details-container">
                                <p className="jobs-title job-top-section-left">Giorgi Nonikashvili</p>
                                <p className="vacancy-date job-top-section-right">2 Jan. 2021</p>
                              </div>
                              <p className="contact-detail">House Celaning</p>
                              <div className="jobs-content-footer no-margin">
                                <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                              </div>
                              <span className="quotation-mark">„</span>
                              <span className="feedback-txt">Great hotel with a good parking facility, room was clean and reception staff very friendly. Good value for money.</span>
                            </div>
                          </div>
                        </div>
                      </div> : ''}</> : ''}
                  {/*Tab 3*/}
                  {activeSPId === -1 && !jobViewVisible ? <><div className="col-6 tabcontent" id="tab-3">
                    <div className="jobs-tabbed-gallery">
                      <div className="jobs-details-container">
                        <p className="jobs-category">Levan Gorgadze</p>
                        <div className="job-top-section-right jobs-apply-modal">
                          <i className="bi bi-geo-alt" /><span className="jobs-location-name">Tbilisi, Dighomi</span>
                        </div>
                      </div>
                      <div className="jobs-details-container">
                        <p className="job-poster">Handyman, Plumber</p>
                      </div>
                      <span className="jobs-post-date">ენები:
                        <img src="../resources/flags/ge.png" />
                        <img src="../resources/flags/gb.png" />
                      </span>
                      <div id="whoWeAre" className="carousel slide sp-profileView-slider" data-ride="carousel">
                        {/* Indicators */}
                        <ul className="carousel-indicators sp-indicators">
                          <li data-target="#whoWeAre" data-slide-to={0} className="active" />
                          <li data-target="#whoWeAre" data-slide-to={1} />
                          <li data-target="#whoWeAre" data-slide-to={2} />
                        </ul>
                        {/* The slideshow */}
                        <div className="carousel-inner">
                          <div className="jobs-gallery-tag tag-shortlisted"><i className="bi bi-tag" />შერჩეული</div>
                          <div className="carousel-item active">
                            <img src="../images/gallery/sp1.jpg" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp2.jpg" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp3.jpg" className="about-slider" />
                          </div>
                        </div>
                      </div>
                      <div className="sp-profileView-feedback">
                        <p className="feedback-positive job-top-section-left no-margin">76% positive feedback</p>
                        <p className="jobs-price-label no-margin">{`All Feedbacks (${feedbacks ? feedbacks.length : '0'})`}</p></div>
                      <p className="user-attribute">I'm experienced Craftsman with 12 year working experience in this field. I will be glad to work on this project</p>
                      <div className="customer-jobs-inner">
                        <div className="profile-title portfolio-title">პორტფოლიო</div>
                        <div className="nav-prev sp-portfolio-prev"><img src="../images/left-arrow.png" className="sp-portfolio-arrow-l" /></div>
                        <div className="nav-next sp-portfolio-next"><img src="../images/right-arrow.png" className="sp-portfolio-arrow-r" /></div>
                        <div className="cata-sub-nav sp-portfolio">
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/1.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/2.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/3.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/4.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/5.png" className="sp-portfolio-img" />
                          </div>
                        </div>
                        <div className="profile-title portfolio-title">Certificates</div>
                        <div className="sp-certificates">
                          <div className="col-lg-6 col-12 vacancy-row">
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Electrician certificate</p>
                            </div>
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Certificate</p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 vacancy-row">
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Handyman certificate</p>
                            </div>
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Plumber certificate</p>
                            </div>
                          </div>
                        </div>
                        <div className="profile-title portfolio-title">{`All Feedbacks (${feedbacks ? feedbacks.length : '0'})`}</div>
                        <div className="navv-prev sp-allFeedback-prev"><img src="../images/left-arrow.png" className="sp-portfolio-arrow-l" /></div>
                        <div className="navv-next sp-allFeedback-next"><img src="../images/right-arrow.png" className="sp-portfolio-arrow-r" /></div>
                        <div className="all-feedbacks-nav sp-portfolio">
                          {feedbacks && feedbacks.length > 0 ? feedbacks.map((value, index) => (
                            <div className="card sp-portfolio-img-container">
                              <div className="sp-allFeedbacks-container">
                                <div className="jobs-details-container">
                                  <p className="jobs-title job-top-section-left">{value.feedbackByDisplayName}</p>
                                  <p className="vacancy-date job-top-section-right">{formatDate(value.feedbackDate)}</p>
                                </div>
                                <p className="contact-detail">{value.feedbackCategory}</p>
                                <div className="jobs-content-footer no-margin">
                                  <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                                </div>
                                <span className="quotation-mark">„</span>
                                <span className="feedback-txt">{value.feedbackDescription}</span>
                              </div>
                            </div>
                          )) : <p className="jobs-title sp-certificate-title cert-fallback">No feedbacks have been recorded for this Service Provider</p>
                          }
                        </div>
                      </div>
                    </div>
                  </div></> : ''}
                  {/*Tab 4*/}
                  {activeSPId === -1 && !jobViewVisible ? <div className="col-6 tabcontent" id="tab-4">
                    <div className="jobs-tabbed-gallery">
                      <div className="jobs-details-container">
                        <p className="jobs-category">Zurab Zaridze</p>
                        <div className="job-top-section-right jobs-apply-modal">
                          <i className="bi bi-geo-alt" /><span className="jobs-location-name">Tbilisi, Dighomi</span>
                        </div>
                      </div>
                      <div className="jobs-details-container">
                        <p className="job-poster">Handyman, Plumber</p>
                      </div>
                      <span className="jobs-post-date">ენები:
                        <img src="../resources/flags/ge.png" />
                        <img src="../resources/flags/de.png" />
                      </span>
                      <div id="whoWeAre" className="carousel slide sp-profileView-slider" data-ride="carousel">
                        {/* Indicators */}
                        <ul className="carousel-indicators sp-indicators">
                          <li data-target="#whoWeAre" data-slide-to={0} className="active" />
                          <li data-target="#whoWeAre" data-slide-to={1} />
                          <li data-target="#whoWeAre" data-slide-to={2} />
                        </ul>
                        {/* The slideshow */}
                        <div className="carousel-inner">
                          <div className="jobs-gallery-tag tag-interested"><i className="bi bi-tag" />დაინტერესებული</div>
                          <div className="carousel-item active">
                            <img src="../images/gallery/sp1.jpg" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp2.jpg" className="about-slider" />
                          </div>
                          <div className="carousel-item">
                            <img src="../images/gallery/sp3.jpg" className="about-slider" />
                          </div>
                        </div>
                      </div>
                      <div className="sp-profileView-feedback">
                        <p className="feedback-positive job-top-section-left no-margin">76% positive feedback</p>
                        <p className="jobs-price-label no-margin">{`All Feedbacks (${feedbacks ? feedbacks.length : '0'})`}</p></div>
                      <p className="user-attribute">I'm experienced Craftsman with 12 year working experience in this field. I will be glad to work on this project</p>
                      <div className="customer-jobs-inner">
                        <div className="profile-title portfolio-title">Portfolio</div>
                        <div className="nav-prev sp-portfolio-prev"><img src="../images/left-arrow.png" className="sp-portfolio-arrow-l" /></div>
                        <div className="nav-next sp-portfolio-next"><img src="../images/right-arrow.png" className="sp-portfolio-arrow-r" /></div>
                        <div className="cata-sub-nav sp-portfolio">
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/1.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/2.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/3.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/4.png" className="sp-portfolio-img" />
                          </div>
                          <div className="card sp-portfolio-img-container">
                            <img src="../images/gallery/5.png" className="sp-portfolio-img" />
                          </div>
                        </div>
                        <div className="profile-title portfolio-title">Certificates</div>
                        <div className="sp-certificates">
                          <div className="col-lg-6 col-12 vacancy-row">
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Electrician certificate</p>
                            </div>
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Certificate</p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 vacancy-row">
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Handyman certificate</p>
                            </div>
                            <div className="sp-certificate-container">
                              <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                              <p className="jobs-title sp-certificate-title">Plumber certificate</p>
                            </div>
                          </div>
                        </div>
                        <div className="profile-title portfolio-title">{`All Feedbacks (${feedbacks ? feedbacks.length : '0'})`}</div>
                        <div className="navv-prev sp-allFeedback-prev"><img src="../images/left-arrow.png" className="sp-portfolio-arrow-l" /></div>
                        <div className="navv-next sp-allFeedback-next"><img src="../images/right-arrow.png" className="sp-portfolio-arrow-r" /></div>
                        <div className="all-feedbacks-nav sp-portfolio">
                          {feedbacks && feedbacks.length > 0 ? feedbacks.map((value, index) => (
                            <div className="card sp-portfolio-img-container">
                              <div className="sp-allFeedbacks-container">
                                <div className="jobs-details-container">
                                  <p className="jobs-title job-top-section-left">{value.feedbackByDisplayName}</p>
                                  <p className="vacancy-date job-top-section-right">{formatDate(value.feedbackDate)}</p>
                                </div>
                                <p className="contact-detail">{value.feedbackCategory}</p>
                                <div className="jobs-content-footer no-margin">
                                  <p className="feedback-positive sp-listing-feedback">76% positive feedback</p>
                                </div>
                                <span className="quotation-mark">„</span>
                                <span className="feedback-txt">{value.feedbackDescription}</span>
                              </div>
                            </div>
                          )) : <p className="jobs-title sp-certificate-title cert-fallback">No feedbacks have been recorded for this Service Provider</p>
                          }
                        </div>
                      </div>

                    </div>
                  </div> : ''}
                </div>
              </div>
              {/*LEAVE FEEBACK MODAL*/}
              {showFeedbackModal ?
                <div className="modal fade" id="feedbackModal" data-backdrop="true" data-keyboard="false" tabIndex={-1} aria-labelledby="feedbackModal" aria-hidden="true">
                  <div className="modal-dialog cat-modal-dialog">
                    <div className="modal-content">
                      <div className="cat-modal-header">
                        <h5 className="modal-title" id="jobcatmodalLabel">Leave feedback</h5>
                        <button type="button" className="close" onClick={() => setShowFeedbackModal(false)} aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row no-gutters">
                          <div className="col-4" id="feedback-pos">
                            <img src="../../resources/icons/positive-emoji.png" alt="positive rating" className="positive-feedback-img" onClick={() => { feedback_pos(); setFeedbackRating('Positive'); }} />
                            <p className="feedback-type-pos">Positive</p>
                          </div>
                          <div className="col-4" id="feedback-neu">
                            <img src="../../resources/icons/neutral-emoji.png" alt="neutral rating" className="neutral-feedback-img" onClick={() => { feedback_neu(); setFeedbackRating('Neutral'); }} />
                            <p className="feedback-type-neu">Neutral</p>
                          </div>
                          <div className="col-4" id="feedback-neg">
                            <img src="../../resources/icons/negative-emoji.png" alt="negative rating" className="negative-feedback-img" onClick={() => { feedback_neg(); setFeedbackRating('Negative'); }} />
                            <p className="feedback-type-neg">Negative</p>
                          </div>
                        </div>
                        <div className="input-group post-job-form">
                          <textarea className="form-control" aria-label="With textarea" rows={5} placeholder="Write review comment" defaultValue={""} onChange={handleChange} />
                          <img src="../resources/icons/pencil.png" width="17px" height="17px" className="pencil-icon" />
                          <p className="max-symbols">0 / 200 symbols</p>
                        </div>
                      </div>
                      <div className="cat-modal-footer">
                        <p className="not-found-txt">Your feedback will be public on our platform</p>
                        <button type="button" className="btn btn-primary btn-block cat-submit" data-toggle="modal" onClick={(event) => addFeedback(event)}>Leave feedback</button>
                      </div>
                    </div>
                  </div>
                </div>
                : ''
              }
              {/*FEEDBACK SUCCESS MODAL*/}
              {showSuccessFeedbackModal ?
                <div className="modal fade" id="feedbackSuccessModal" tabIndex={-1} aria-labelledby="feedbackSuccessModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-sm feedback-modal-desktop">
                    <div className="modal-content">
                      <div className="modal-header feedback-modal-header">
                        <h5 className="modal-title" id="feedbackSuccessModalLabel">Leave feedback</h5>
                        <button type="button" className="close" onClick={() => setShowSuccessFeedbackModal(false)} aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <i className="bi bi-check2 jobs-apply-success-icon" />
                        <p className="jobs-invited apply-success">Feedback has successfully posted</p>
                      </div>
                      <div className="modal-footer feedback-modal-footer">
                      </div>
                    </div>
                  </div>
                </div>
                : ''
              }
              <div className="modal fade" id="formModal" tabIndex={-1} data-backdrop="static" aria-labelledby="formModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="formModalLabel">Chat</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="chat-msg-line">
                        <img src="../images/profile-picture.jpg" className="profile-button-img chat-profile-pic" />
                        <span className="apply-form">
                          <div className="form-dot" /><p className="job-start-date">I can start at 12 Feb. 2021</p>
                          <div className="form-dot" /><p className="work-time">I need 2 month</p>
                          <p className="salary">I will tell time &amp; price later</p>
                        </span>
                        <div className="chat-msg-time">16 Jul<p>14:00</p></div>
                      </div>
                    </div>
                    <div className="white-space" /><div className="white-space" />
                    <div className="send-new-form"><a href data-dismiss="modal" data-toggle="modal" data-target="#applyModal">Send new form</a></div>
                    <div className="modal-footer form-modal-footer">
                      <span className="user-attribute">You can start conversation with this customer after shortlisting the job</span>
                    </div>
                  </div>
                </div>
              </div>
              {/*CHAT MODAL*/}
              {chatModalVisible &&
                (<div className="modal fade " id="feedbackModal" tabIndex={-1} data-backdrop="static" aria-labelledby="chatModalLabel" aria-hidden="true">

                  <div className="modal-dialog modal-sm chat-modal modal-dialog-scrollable mt-5">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="chatModalLabel">პირობების ფორმა</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setChatModalVisible(false)}>
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">

                        <div className="row no-gutters">
                          <div className="jobs-img-container">
                            <img src="../images/gallery/1.png" className="sp-listing-img" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                          </div>
                          <div className="col jobs-inner">
                            <p className="jobs-title">{activeSP?.firstName} {activeSP?.lastName}</p>
                            <p className="job-poster"> {activeSP?.jobCategory}
                            </p>
                          </div>
                        </div>
                        <p style={{ color: '#ccc', marginTop: '15px', fontSize: '12px' }}>ბოლო განახლდების თარიღი: 12.02.2022</p>
                        <div style={{ width: '100%', height: '200px', background: '#f1f1f1', padding: '15px', fontSize: '13px', marginBottom: '20px', borderRadius: '5px' }}>
                          <div style={{ marginBottom: '20px' }}>
                            <div>როდის შეძლებთ სამუშაოს დაწყებას?</div>
                            <div style={{ fontWeight: 'bold' }}>{activeSP?.canStartWorkDate && moment(activeSP?.canStartWorkDate).format('DD.MM.YYYY')}-ში</div>
                          </div>

                          {renderTimeNeededandBudget()}



                        </div>

                        <p style={{ color: '#ccc', marginTop: '15px', fontSize: '12px', width: '100%', textAlign: 'center' }}>თუ თქვენ დაინტერესდით ამ შემოავაზებით, დააჭირეთ ღილაკს "შერჩევა" და თქვენ დაინახავთ საკონტაქტო ტელეფონის ნომერს</p>

                        {<button type="button" className={`btn btn-primary btn-lg sp-buttons `} onClick={() => setChatModalVisible(false)}>დახურვა</button>}

                        {/* {activeSP?.jobState==="Shortlisted" && <button type="button" className={`btn btn-primary btn-lg sp-buttons ${inHiredState ? 'disabled' : ''}`} onClick={(event) => updateJobState(event, activeSP, 'Hired')}>დაქირავება</button>   }                */}
                      </div>


                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
        </div><div className="white-space mobile-spacing" />
      </div>
      {/*?php include 'mobilemenu-customer.php';?*/}
      <div className="footer footer-mobile">
        {/*?php include '../includes/footer.php';?*/}
      </div>
    </div>
  );
};

export default CustomerJobs;