import "./how-it-works.scss"

const HowItWorksPage = () => {
  return (
    <div className="HIW__container">
        <div className="HIW__title">
            <p>დააჭირეთ ღილაკს „იპოვე ხელოსანი“, შეავსეთ დეტალები და შეარჩიეთ შესაფერისი შემსრულებელი.</p>
            <a href='/post-a-job' className='btn btn-primary fcaps HIW__button' type='button'>იპოვე ხელოსანი</a>
        </div>
        <div className='HIW__diagram'>            
            <div className="HIW__section">
                <img className='' src="images/flow-step1.png"/>                 
                <p>
                <h3>01. გამოაქვეყნეთ სამუშაო</h3>
                გამოაქვეყნეთ სამუშაო გვითხარით შესასრულებელი სამუშაოს შესახებ და თქვენი რაიონის ფარგლებში უფასოდ მოვიძიებთ სამუშაოს შესაფერის შემსრულებელს. დაინტერესებული შემსრულებლისგან პასუხს ვებსაიტის საშუალებით მიიღებთ.                    
                </p>
            </div>
            <div className="HIW__section">
                <img className='' src="images/flow-step2.png"/>
                
                <p><h3>02. შეადარეთ და შეარჩიეთ</h3>შეადარეთ და შეარჩიეთ თქვენთვის სასურველი სამუშაოს შემსრულებელი. დაათვალიერეთ პროფილები, უკუკავშირები და გამოავლინეთ თქვენი რჩეული შემსრულებელი, რომელთანაც თქვენი დეტალების გაზიარებას შეძლებთ.
                </p>
            </div>
            <div className="HIW__section">
                <img className='' src="images/flow-step3.png"/>                 
                <p><h3>03. დაიქირავეთ</h3>დაუკავშირდით სამუშაოს შემსრულებელს პირდაპირ ან ვებსაიტის საშუალებით. დააჭირეთ ღილაკს „დაქირავება“ და თქვენთვის სასურველი სამუშაოს შემსრულებელი მონიშნეთ, როგორც „დაქირავებული“. </p>
            </div>
            <div className="HIW__section">
                <img className='' src="images/flow-step4.png"/>                 
                <p><h3>04. შეაფასეთ</h3>შეაფასეთ სამუშაოს შემსრულებელი და მოგვწერეთ, რამდენად კმაყოფილი ხართ შესრულებული სამუშაოთი. დატოვეთ უკუკავშირი და წაახალისეთ შემსრულებელი კარგად შესრულებული სამუშაოსთვის. ცუდად შესრულებული სამუშაოსთვის კი ნეგატიური შეფასება დაუტოვეთ. ეს საშუალებას მოგვცემს მომავალში კიდევ უფრო გავაუმჯობესოთ ჩვენი მომსახურება.
                </p>
            </div>
        </div>
    </div>
  )
}

export default HowItWorksPage