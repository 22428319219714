import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';

const PaymentDetails = () => {

    const [showAddCard, setShowAddCard] = useState(false);
    const [showDeleteCardConf, setShowDeleteCardConf] = useState(false);
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const { register, handleSubmit, errors } = useForm();
    const card = JSON.parse(localStorage.getItem("card") || "[]");

    console.log(card);

    const onSubmit = (data) => {
        setShowAddCard(false);
      console.log(data);
      localStorage.setItem("card",JSON.stringify(data))
    };

    const redirectToInvoices = (e) => {
        console.log(e.target.value);
        window.location.href = '/invoices';
    };

    return (
        <div className="mobile-sidebar payment-details">
            <div className="sidebar-menu-containers">
                <div className="sidebar-header-mobile notifications-page-header">
                    <div className="profile-picture">
                        <img src="../images/profile-picture.jpg" className="profile-picture-img" />
                    </div>
                    <div className="col-9 col-md-10 col-lg-8">
                        <div className="row">
                            <p className="profile-name">Mishka B.</p>
                        </div>
                        <div className="row">
                            <p className="profile-feedback">76% positive feedback</p>
                        </div>
                    </div>
                </div>
            </div>
                <div className="edit-details notifications-page-title">
                    <div className="editDetails-title-container">
                        <a onClick={redirectToInvoices}>
                            <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                        </a>
                        <p className="edit-details-title">Payment details</p>
                    </div>
                </div>
                            <div id="added-card" className="added-card-container">
                            <div className="automatic-payment-container">
                                <p className="automatic-payment">Payment will be taken from your card on the due date of your invoice</p>
                                <label className="auto-pay-switch">
                                    <input type="checkbox" />
                                    <span className="auto-pay-slider round" />
                                </label>
                            </div>
                            <div className="card-background card-mobile">
                                <div className="card-details-top">
                                    <div className="card-owner-name" id="cardOwnerVal">{card.fullName}</div>
                                    <a data-toggle="modal" data-target="#delCardModal"><img className="delete-card-btn" src="../resources/icons/delete-white.png" /></a>
                                </div>
                                <div className="card-details-bottom">
                                    <div className="card-number" id="cardNumberVal">{card.cardNumber}</div>
                                    <img className="delete-card-btn" src="../resources/icons/mastercard.png" />
                                </div>
                            </div>
                        </div>

            {showDeleteCardConf ?
                <div className="modal fade bd-example-modal-sm" id="delCardModal" tabIndex={-1} role="dialog" aria-labelledby="deleteTemplateModal" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Card</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body default-font">
                                Are you sure, you want to delete card?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="dismiss-btn-modal default-font" data-dismiss="modal">Cancel</button>
                                <button type="button" className="delete-btn-modal default-font" data-dismiss="modal" onClick="">Delete</button>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }

            <div className="addCard-container" id="addCardBtn">
                <p className="addCard-title">Add card</p>
                <p className="user-attribute">If you want add card to your account to pay more easily, click on the plus button below</p>
                <div className="addCard-button" data-toggle="modal" data-target="#addCardModal" onClick={() => setShowAddCard(true)}><div className="addCard-plus">+</div></div>
            </div>
            {showAddCard ?
                <div className="modal fade bd-example-modal-sm" id="addCardModal" tabIndex={-1} role="dialog" aria-labelledby="addCardModal" aria-hidden="true">
                    <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                    <form onSubmit={handleSubmit(onSubmit)} className="add-card-form">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Card</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowAddCard(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body default-font">
                                <form className="form-group">
                                        <input type="text" className="form-control" placeholder="First Name, Last Name" id="cardOwner"  {...register("fullName", {

                                            required: "Required",
                                        })} />
                                </form>
                                <form className="form-group">
                                        <input type="number" className="form-control cardNumber" maxLength={16} oninput="this.value=this.value.slice(0,this.maxLength)"
                                            placeholder="Card number (16 digit)" id="cardNumber"  {...register("cardNumber", {

                                                required: "Required",
                                        })} />
                                </form>
                                <div className="row form-group">
                                    <div className="col-6 card-month">
                                            <select className="custom-select form-control"  {...register("cardMonth", {
                                                required: "Required",
                                            })}>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                            <option>07</option>
                                            <option>08</option>
                                            <option>09</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option>
                                        </select>
                                    </div>
                                    <div className="col-6 card-year">
                                        <select className="custom-select form-control" {...register("cardYear", {
                                                required: "Required",
                                            })}>
                                            <option>2022</option>
                                            <option>2023</option>
                                            <option>2024</option>
                                            <option>2025</option>
                                            <option>2026</option>
                                            <option>2026</option>
                                            <option>2027</option>
                                            <option>2028</option>
                                            <option>2029</option>
                                            <option>2030</option>
                                            <option>2031</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <form className="form-group">
                                            <input type="number" className="form-control cvcNumber" maxLength={3} oninput="this.value=this.value.slice(0,this.maxLength)" placeholder="CVC code"
                                            {...register("cvv", {
                                                required: "Required",
                                            })} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary btn-block default-font" data-dismiss="modal" onClick="">Add card</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
                : ''}
            <div className="white-space" /><div className="white-space" />
        </div>
    );
}

export default PaymentDetails;