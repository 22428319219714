import './select-theme.scss';
import 'react-phone-number-input/style.css';
import 'react-phone-number-input/style.css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { useStateMachine } from 'little-state-machine';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
// import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { withRouter } from 'react-router-dom';

import { API_BASE_URL, TYPES } from '../../constants/apiConstants';
import cities from './cities.json';
import SignUpHeader from './sign-up-header';
import updateAction from './updateAction';
import TermsAndConditionsText from "./terms-and-conditions-text"

const RegistrationStep3 = (props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      category: ''
    }
  });


  const [termsAndCondModalVisible, SetTermsAndCondModalVisible] = useState(false);

  const { actions, state } = useStateMachine({ updateAction });
  const categoryObj = localStorage.getItem("reg_categories");
  const categoryOptions  = JSON.parse(categoryObj);
  const mappedCategories = [];

  const multiselectRef = useRef();

  categoryOptions.forEach((item, index) => {
    mappedCategories.push({name: item.Properties.jobCategoryName, id: item.Id});
  });
  console.log("categoryOptions", mappedCategories);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    successMessage: null,
  });

  const [items, setItems] = React.useState([
    { label: "Loading ...", value: "" },
  ]);

  //Content from Local storage
  const regObj = localStorage.getItem('reg_labels');
  const regLabels = JSON.parse(regObj);

  const busObj = localStorage.getItem('businessTypeList');
  const businessTypeList = JSON.parse(busObj);

  const verifyObj = localStorage.getItem('mobileVerificationLabels');
  const mobileVerificationLabels = JSON.parse(verifyObj);

  const [loginText, setLoginText] = React.useState(regLabels.finishRegButton);
  const [newMobile, setNewMobile] = React.useState(state.mobileNumber);
  const [loginState, setLoginState] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [passwordEqual, setPasswordEqual] = useState(false);
  const [cookies, setCookie] = useCookies(["name"]);
  const [isCompany, setIsCompany] = useState(false);
  const [selectedJobCategory, setSelectedJobCategory] = useState('');
  const [selectedBusinessType, setBusinessType] = useState('');
  const [ltdAddress, setLtdAddress] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([])
  const [regFinished, setRegFinished] = useState(false);

  // const [rtoken, setRToken] = React.useState(null);
  // const reCaptchaRef = useRef();

  const savedPostJob = window.localStorage.getItem("savedPostJob");
  let password = document.querySelector('#password');

  state.type = TYPES.serviceProvider;

  const onSelect  = (selectedList, selectedItem) => {
    const value = selectedList;    
    setValue("category", value);
    console.log(selectedList.length);
    setSelectedCategories(selectedList);
    console.log(selectedCategories.length);
  };

  const redirectToHome = () => {
    props.updateTitle("Home");
    window.location.href = "/";
  };

  const redirectToJobPosted = () => {
    props.updateTitle("Post a job");
    // props.history.push("/");
    window.location.href = "/job-posted";
  };

  // const onRecaptchaChange = (value) => {
  //   setRToken(value);
  // };

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value)
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setNewMobile(value)
  };

  const handlePinSubmitClick = (e) => {
    e.preventDefault();

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    setLoginText(regLabels.verifyingBtnLabel);

    const payload = {
      email: state.email,
      password: state.password,
      pin: pin,
      // token: rtoken
    };

    const loginAxios = axios.create({
      withCredentials: false,
      baseUrl: API_BASE_URL
    });

    loginAxios
      .post(`${API_BASE_URL}/api/access/confirm`, payload)
      .then(function (response) {
        if (response.data.is_successful) {

          setLoginText("ვერიფიკაცია...")

          const expirytime = response.data.token_data.expires_in * 1000;

          // setCookie(
          //   "ContactiAccessToken",
          //   // window.btoa(response.data.token_data.access_token),
          //   response.data.token_data.access_token,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );

          // setCookie(
          //   "ContactiUsername",
          //   response.data.user_data.Username,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );

          // if (savedPostJob && savedPostJob.length > 0) {
          //   redirectToJobPosted();
          // } else {
          //   redirectToHome();
          // }

          setRegFinished(true)

          props.showError(null);
        }
        else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
          setLoginState("not verified");
        } else if (response.data.code === 204) {
          props.showError("Username and password do not match");
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
        } else {
          props.showError("Pin Validation failed. Please enter valid pin.");
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const resendPin = (e) => {
    e.preventDefault();
    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    setLoginText("იგზავნება...");
    axios
      // .post(API_BASE_URL + "/api/access/resendverify", { email: state.email, token: rtoken })
      .post(API_BASE_URL + "/api/access/resendverify", { email: state.email })
      .then(function (response) {
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          // setCookie(
          //   "ContactiAccessToken",
          //   window.btoa(response.data.token_data.access_token),
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );
          // setCookie(
          //   "ContactiUsername",
          //   // window.btoa(response.data.token_data.access_token),
          //   response.data.user_data.Username,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );

          // redirectToHome();

          setRegFinished(true)

          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState("not verified");
          setLoginText(mobileVerificationLabels.headerLabel)
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateNumber = (e) => {
    e.preventDefault();

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    const payload = {
      email: state.email,
      mobileNumber: newMobile,
      // token: rtoken
    };

    setLoginText(mobileVerificationLabels.sendingTextLabel);
    axios
      .post(API_BASE_URL + "/api/access/resendverify", payload)
      .then(function (response) {
        // setRToken(null);
        // reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          // setCookie(
          //   "ContactiAccessToken",
          //   window.btoa(response.data.token_data.access_token),
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );
          // setCookie(
          //   "ContactiUsername",
          //   // window.btoa(response.data.token_data.access_token),
          //   response.data.user_data.Username,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );

          // redirectToHome();

          setRegFinished(true)

          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState(mobileVerificationLabels.unverifiedLabel);
          setLoginText(mobileVerificationLabels.headerLabel)
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (data) => {

    // if (!rtoken || rtoken === null || rtoken.length === 0) {
    //   props.showError("Make sure you verify you are not a robot.");
    //   return;
    // }

    actions.updateAction(data);
    state.jobCategory = selectedCategories;
    state.businessCategory = selectedBusinessType;
    state.ltdAddress = ltdAddress;
    state.password = data.password;
    state.repeatPassword = data.repeatPassword;
    state.companyName = data.companyName;
    state.companyId = data.companyId;
    state.idNumber = data.idNumber;

    setLoginText(regLabels.validatingBtnLabel);
    setDisabled(true);

    console.log('to be sent', state);
    console.log('data:', data);
    axios
    // .post(API_BASE_URL + "/api/access/register", {...state, token: rtoken})
      .post(API_BASE_URL + "/api/access/register", {...state})
      .then(function (response) {
        // setRToken(null);
        // reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setDisabled(false);

          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;


          //                  droebit gavtishet
          // setCookie(
          //   "ContactiAccessToken",
          //   window.btoa(response.data.token_data.access_token),
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );
          // setCookie(
          //   "ContactiUsername",
          //   // window.btoa(response.data.token_data.access_token),
          //   response.data.user_data.Username,
          //   {
          //     path: "/",
          //     expires: new Date(Date.now() + expirytime),
          //   }
          // );
          // redirectToHome();

          setRegFinished(true)

          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState("not verified");
          setLoginText("ვერიფიკაცია")
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
        props.showError("არასწორი მომხმარებელი! თქვენს მიერ მითითებული ელ-ფოსტა არ არის სწორია, ან უკვე დერეგისტრირებულია!");
      });
  };

  const onChange = (livingAddress) => {
    console.log(livingAddress);
    return livingAddress;
  };

  const getJobCategory = (cat) => {
    console.log(cat);
    setSelectedJobCategory(cat.value);
  };

  const getLtdAddress = (address) => {
    setLtdAddress(address.value);
  };

  const getBusinessType = (e) => {
    if (e.target.value.toLowerCase().includes('self')) {
      setIsCompany(false);
    } else {
      setIsCompany(true);
    }

    setBusinessType(e.target.value);
  };

const renderJobCategoriesMultiselect = () =>{
  return (
    <>
    <Multiselect
      options={mappedCategories} // Options to display in the dropdown
      displayValue="name" // Property name to display in the dropdown options
      onSelect={onSelect}
      ref={multiselectRef}
      showCheckbox="true"
      avoidHighlightFirstOption="false"
      placeholder={regLabels.categoriesLabel}
      closeOnSelect="true"
      className='JobCatInput'
      name='category'
      {...register("category", {        
        required: "Required",
      })}
    />
    {errors["category"] && selectedCategories.length === 0 && (
        <p className="error-message">სპეციალობის ველის შევსება სავალდებულოა!</p>
      )}

    </>    
  )
}



  return (
    <>
    {!regFinished ?
(    <div className="card col-12 col-lg-6 col-xl-3 login-card mt-5 hv-center">
      <div className="form-wrapper">
        {loginState === "not verified" ?
          <form>

            <div className="sign-up-header">
              <h3>{mobileVerificationLabels.headerLabel}</h3>
              <small>
                {mobileVerificationLabels.headerSmallLabel}
              </small>
            </div>
            <div className="form-inner">
              <div className="form-group text-left">
                <input
                  type="text"
                  id="regPin"
                  placeholder={regLabels.pinLabel}
                  value={pin}
                  onChange={handlePinChange}
                  className={`form-control ${!pin || pin.length < 4 || pin.length > 4 ? "error" : ""
                    }`}
                />
              </div><div className="forgot-password">
                <span>{mobileVerificationLabels.resendText} <a href="#" onClick={resendPin}>{mobileVerificationLabels.resendBtnLabel}</a> {mobileVerificationLabels.orTextLabel} <a href="#" onClick={() => { setLoginState("update number"); }}>{mobileVerificationLabels.updateMobileLabel}</a> </span>
              </div>

              {/* <div className="form-group text-left">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                  onChange={onRecaptchaChange}
                  hl="ka"
                />
              </div> */}
            </div>
            <button
              type="submit"
              className="btn btn-primary sign-in-btn verify-btn"
              onClick={handlePinSubmitClick}
            >
              {loginText}
            </button>
          </form> : loginState === "update number" ?
            <form>

              <div className="sign-up-header">
                <h3>Update Mobile Number</h3>
                <small>
                  Send pin to new mobile number.
                </small>
              </div>
              <div className="form-inner">
                <div className="form-group text-left">
                  <Controller
                    name="newMobile"
                    control={control}
                    value={newMobile}
                    onChange={handleMobileChange}
                    {...register("newMobile", { required: true, maxLength: 30 })}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value)
                    }}

                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        placeholder="New Mobile Number"
                        value={value}
                        onChange={onChange}
                        defaultCountry="GE"
                        id="newMobile"
                      />
                    )}
                  />
                  {errors["newMobile"] && (
                    <p className="error-message">{mobileVerificationLabels.invalidNumberLabel}</p>
                  )}

                </div><div className="forgot-password">
                  <span>{mobileVerificationLabels.registrationNumberLabel}</span>
                </div>

                {/* <div className="form-group text-left">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                    onChange={onRecaptchaChange}
                  />
                </div> */}
              </div>
              <button
                type="submit"
                className="btn btn-primary sign-in-btn"
                onClick={updateNumber}
              >
                {loginText}
              </button>
            </form> :
            <form onSubmit={handleSubmit(onSubmit)}>
              <SignUpHeader />

              <div className="reg-form-inner">
                {/* <div className="form-group text-left">
                  <input
                    type="text"
                    className={`form-control ${errors && errors.idNumber ? "error" : ""
                      }`}
                    {...register("idNumber", { required: true, maxLength: 25 })}
                    id="idNumber"
                    placeholder={regLabels.idNumberLabel}
                    defaultValue={''}
                  />
                </div> */}

                <div className="form-group">
              {renderJobCategoriesMultiselect()}
              {/* <input type="text" name="email" style={{visibility:''}} /> */}
                  {/* <select className="form-control" id="sel1" {...register("jobCategory", {
                          required: "Required",
                        })}>
                        <option value="" disabled selected>{regLabels.categoriesLabel}</option>
                  {categoryOptions && categoryOptions.map((x,y) => <option key={y} value={x.Name}>{x.Properties.jobCategoryName}</option>)}
                  </select> */}
                </div>
                <div className="form-group">
                  <select className="form-control" id="sel1" {...register("businessCategory", {
                    required: "Required",
                  })}
                    onChange={(e) => getBusinessType(e)}>
                    <option value="" disabled selected>{regLabels.businessTypeLabel}</option>
                    {businessTypeList.map((x, y) => <option key={y} value={x.Name}>{x.Properties.dropdownOption}</option>)}
                  </select>
                </div>

                {/* {isCompany ? */}
                  <div className={isCompany ? '': 'hidden'}>
                    <div className="form-group text-left">
                      <input
                        type="text"
                        className={`form-control ${errors && errors.companyName ? "error" : ""
                          }`}
                        {...register("companyName", { required: false, maxLength: 30 })}
                        id="companyName"
                        placeholder={regLabels.companyNameLabel}
                        defaultValue={""}
                        name="companyName"
                      />
                    </div>
                    <div className="form-group text-left">
                      <input
                        type="text"
                        className={`form-control ${errors && errors.companyId ? "error" : ""
                          }`}
                        {...register("companyId", { required: false, maxLength: 30 })}
                        id="companyId"
                        placeholder={regLabels.companyIdLabel}
                        defaultValue={""}
                        name="companyId"
                      />
                    </div>
                    <Controller
                      render={({ ...props }) => (
                        <Autocomplete
                          options={cities}
                          getOptionLabel={(option) => option.value}
                          renderOption={(option) => <span>{option.value}</span>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={regLabels.companyAddressTypeLabel}
                              variant="outlined"
                            />
                          )}
                          onChange={(e, data) => getLtdAddress(data)}
                          {...props}
                        />
                      )}
                      onChange={([, data]) => data}
                      defaultValue={""}
                      name="ltdAddress"
                      control={control}
                    />
                  </div>
                  {/* : ''
                } */}

                <div className="form-group text-left">
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${errors && errors.password ? "error" : ""
                      }`}
                    {...register("password", { required: true, minLength: 8, maxLength: 30 })}
                    id="password"
                    placeholder={regLabels.passwordLabel}
                    defaultValue={''}
                    Autocomplete="off"
                    autocomplete="new-password"
                  />
                </div>

                <div className="form-group text-left">
                  <input
                    type="password"
                    name="repeatPassword"
                    className={`form-control ${errors && state.repeatPassword !== state.password ? "error" : ""
                      }`}
                    {...register("repeatPassword", { required: true, minLength: 8, maxLength: 30 })}
                    id="repeat-password"
                    placeholder={regLabels.confirmPasswordLabel}
                    defaultValue={''}
                    Autocomplete="off"
                    autocomplete="new-password"
                  />
                  {errors["repeatPassword"] && (
                       <p className="error-message">{regLabels.passwordErrorLabel}</p>
                  )}
                </div>
               
                <div className='text-primary tandclink__register' style={{display:'flex', justifyContent:'end', gap:'5px', marginTop:'30px', fontSize:'12px'}} >
                  <div onClick={() => SetTermsAndCondModalVisible(prev => !prev)}>
                    ვეთანხმები პლატფორმის წესებსა და პირობებს
                  </div>
                  <input 
                    type="checkbox" 
                    {...register("tandccheckbox", {
                      required: {
                        value: true,
                        message: "თქვენ უნდა დაეთანხმოთ პლატფორმის წესებსა და პირობებს!",
                      },
                    })} 
                  />
                </div>
                <p style={{display:'flex', justifyContent:'end', fontSize:'11px', color:'red'}}>{errors.tandccheckbox?.message}</p>

                
                {termsAndCondModalVisible && 
                      (
                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">წესები და პირობები</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body text-justify">
                                <TermsAndConditionsText />
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {SetTermsAndCondModalVisible(prev => !prev)}}>დახურვა</button>
                                <button type="button" className="btn btn-primary" onClick={() => {SetTermsAndCondModalVisible(prev => !prev); setValue("tandccheckbox", true); clearErrors(["tandccheckbox"])}}>ვეთანხმები</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )

                    }
                {/* <div className="form-group text-left">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                    onChange={onRecaptchaChange}
                    hl="ka"
                    className="g-recaptcha fcaps"
                  />
                </div> */}
              </div>

              {/* <div className="row  justify-content-center">
                <a href="/step2" type="button" className="btn btn-primary col-sm-5 sign-in-btn d-flex justify-content-center align-items-center fcaps">
                 {regLabels.previousButtonLabel}
                </a>
                <button type="submit" className="btn btn-primary col-sm-5 sign-in-btn fcaps" disabled={disabled}>
                {loginText}
              </button>
              </div> */}
              <button type="submit" className="btn btn-primary register-btn first-step-btn fcaps">
              {loginText}
          </button>
            </form>
        }
      </div>
    </div>)
:
(<div className='container'>
  <div className='row'>
    <div className='col-12 mt-5 text-center' >
    <p style={{color:'#fff', fontSize:'18px'}}>{regLabels.landingPreRegLabel}</p>
  <button className="btn btn-primary col-sm-3 sign-in-btn mt-5" onClick={()=>redirectToHome()} >მთავარ გვერდზე დაბრუნება</button>
    </div>
  </div>
  
</div>)}
    </>
  );
};

export default withRouter(RegistrationStep3);
