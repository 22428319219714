import React from 'react'

const TermsAndConditionsText = () => {
  return (
    <div>
    <div style={{fontWeight:'bold'}}>1. შესავალი</div>
    <br />1.1. ამ ვებგვერდზე შემოთავაზებული მომსახურების გამოყენებით, თქვენ ეთანხმებით წინამდებარე ხელშეკრულების პირობებს. თუ  არ გსურთ დაეთანხმოთ ამ ხელეშეკრულების პირობებს, თქვენ ვერ გამოიყენებთ და არ გექნებათ წვდომა ჩვენს მომსახურებაზე.
    <br /><br /><div style={{fontWeight:'bold'}}>2. განმარტებები</div> 
    <br />2.1. "ანგარიშის კრედიტი" გულისხმობს ანგარიშზე დარიცხულ  ბალანსს, რომელიც შეიძლება გამოყენებულ იქნას „Contactis“-ის მიერ „შერჩევის“ საკომისიოს გადასახდელად. 
    <br />2.2. "კონტაქტი"  და “კონტაქტები”  გულისხმობს ნებისმიერს თვითდასაქმებულს დაა შეზღუდულ პასუხისმგებლობას,  რომელსაც შეუძლია სერვისი მიწოდება და  რეგისტრირდება ვებგვერდზე მომხმარებლებთან დასაკავშირებლად.
    <br />2.3. "შინაარსი/კონტენტი" გულისხმობს ყველა პოსტს, შეტყობინებას, ტექსტს, ფაილებს, ფოტოსურათებს, ვიდეოებს, ხმოვან ან სხვა მასალებს, რომლებიც განთავსებულია თქვენს მიერ ვებგვერდზე.
    <br />2.4. "კომპანია", "Contacti.ge" ("ჩვენ","ჩვენი" და "ჩვენს") გულისხმობს „Contacti.ge“-ს, რომლეიც შედის „შ.პ.ს დისფეის“-ის მფლობელობაში, კომპანიის საიდენტიფიკაციო კოდით ID 405420744, საქართველო, თბილისი, საირმის ქუჩა 35
    <br />2.5. "მომხარერბელი" არის ნებისმიერი პირი, რომელიც აგზავნის ან აქვეყნებს საქმეს/დავალებას ვებგვერდზე, რომელის  ნახვა და ინტერესის გამოხატვა  შესაძლებელია „Contacti“-ის ანგარიშის მფლობელებისთვის.
    <br />2.6. "ინტერესის გამოხატვა" გულისხმობს ჩვენი სერვისის საშუალებით აღმოჩენილ მომხმარებელთან დაკონტაქტებას, შეფასებას ან შეტყობინებების გაცვლას.
    <br />2.7. "საქმე/დავალება" გულისხმობს პროექტს/დავალებას, რომელსაც მომხმარებელი წარადგენს ჩვენი სერვისის საშუალებით.
    <br />2.8. "ლიდი" არის საქმე/დავალება, რომელზე ინტერესის გამოხატვის შესაძლებლობას ვაძლევთ "Contacti"-ის ანგარიშის მფლობელებს.
    <br />2.9. "სერვისი" გულისხმობს ნებისმიერ მომსახურებას, რომელსაც Contacti.ge გაწვდით თქვენ. ეს მოიცავს, თუმცა არ შემოიფარგლება მხოლოდ: საკონტაქტო, დავალებების შესახებ ინფორმაციის, კონკრეტული სფეროს და სხვა პროდუქტების შესახებ ინფორმაციის მიწოდებით. მომსახურების გაწევა შესაძლებელია ვებგვერდის, ტელეფონის ან სხვა ელექტრონული მოწყობილობების საშუალებით.
    <br />2.10. "მომსახურების (სერვის) ხელშეკრულება" გულისხმობს ნებისმიერ შეთანხმებას „Contacti“-ის ანგარიშის მფლობელებსა და მომხმარებელს შორის გასაწევ სერვისთან/მომსახურებასთან დაკავშირებით. მომსახურების გაწევის შესახებ ხელშეკრულება შეიძლება დაიდოს: ზეპირსიტყვიერად, ტელეფონით, SMS-ით, ელექტრონული ფოსტით ან წერილობითი განაცხადის/კონტრაქტის (ხელშეკრულების) სახით.
    <br />2.11. "შერჩევა" გულისხმობს მოვლენას, როდესაც მომხმარებელი იღებს გადაწყვეტილებას გაუზიაროს თავისი საკონტაქტო ინფორმაცია „Contacti“-ის ანგარიშის მფლობელებს ჩვენი ვებგვერდის საშუალებით, ხოლო მომხმარებელი იღებს „Contacti“-ის ანგარიშის მფლობელის  ტელეფონის ნომერს.
    <br />2.12. "შერჩევის საკომისიო" გულისხმობს შეთანხმებულ თანხას, რომელიც „Contacti“-ს ანგარიშის მფლობელებს ეკისრება Contacti.ge–ის მიმართ, ცალკეულ დავალებაზე/საქმეზე შერჩევის შედეგად საკონტაქტო ინფორმაციის გაცვლის თვის. რაც მოიცავს შერჩეული საქმის ბიუჯეტის 1 ან 2% და მაქსიმაური თანხა შერჩევის საკომისიოსთვის არის განსაზღვრული 9 ლარი 
    <br />2.13. "კონტაქტის ანგარიში" გულისხმობს ჩვენს ვებგვერდზე რეგისტრირებულ „Contacti“-ს ონლაინ პროფილს.
    <br />2.14. „ანგარიშის კონტაქტი/საკონტაქტო პირი" გულისხმობს პირს, რომელიც მითითებულია ანგარიშზე და ყოველდღიურად იყენებს ანგარიშს. ეს მოიცავს აქტივობებს, როგორიცაა სამუშაოებზე/დავალებებზე ინტერესის გამოხატვა და მომხმარებლებთან კომუნიკაცია.
    <br />2.15. "შენ/თქვენ" გულისხმობს ნებისმიერ პირს, ვინც Contacti.ge-ის სერვისით სარგებლობს, მათ შორის, და არ მხოლოდ „კონტაქტს“ მომხმარებლებს.
    <br />2.16. "ვებ-გვერდი" გულისხმობს Contacti.ge ვებ-გვერდს და (ან სხვა ნებისმიერ დომეინს, რომელსაც ფლობს და აკონტროლებს Contacti.ge) და შიდა გვერდს, ასევე მათზე განთავსებულ კონტენტსა და ქვე-დომენებს.
    <br /><br /><div style={{fontWeight:'bold'}}>3. ზოგადი მიმოხილვა</div>
    <br />3.1. Contacti.ge გთავაზობთ ერთგვარ საბაზრო პლატფორმას, რომელიც ეხმარება მომხმარებელს იპოვოს სანდო "კონტაქტები"-ები (თვითდასაქმებულები) და ეხმარება კარგ "კონტაქტებს" - ს (თვითდასაქმებულეებს)  მეტი საქმის/დავალების მოძიებაში. უკუკავშირის საშუალებით ანგარიშვალდებულების პრინციპზე მომუშავე „კონტაქტი“ ფასდება მისი შესრულებული სამუშაოს მიხედვით, დამქირავებლის მიერ. („კონტაქტის“ შეფასება შესაძლებელია მხოლოდ ჩვენი პლატფორმიდან მიღებული მომსახურეობის შემთხვევაში)
    <br /><br /><div style={{fontWeight:'bold'}}>4. ინფორმაციის სიზუსტე</div>
    <br />4.1. მიუხედავად იმისა, რომ ჩვენ ვცდილობთ დავრწმუნდეთ, ინფორმაციის სისწორეში Contacti.ge ვერ იძლევა გარანტიას ვებგვერდზე განთავსებული ინფორმაციის სიზუსტეზე და არ იღებს პასუხისმგებლობას კონტენტში აღმოჩენილი შეცდომების შედეგად წარმოქმნილ პრობლემებზე ან ზარალზე.
    <br />4.2. Contacti.ge- ს მიერ უზრუნველყოფილი სერვისით სარგებლობისას თქვენ აცნობიერებთ, რომ Contacti.ge არ მოქმედებს როგორც კონტრაქტორი ან აგენტი და არ არის პასუხისმგებელი ვებგვერდზე რეგისტრირებული "კონტაქტის" (კონტაკტი) მიერ შესრულებულ ნებისმიერ სამუშაოზე. მომხმარებელი პასუხისმგებელია შეარჩიოს შესაფერისი "Contacti"(კონტაქტი), აწარმოოს მოლაპარაკება და შეათანხმოს მათ მიერ შესასრულებელი საქმის/დავალების პირობები. Contacti.ge არ იქნება ჩართული და ვერ იქნება პასუხისმგებელი მომხმარებელ/კლიენტსა და "Contacti"-ს შორის დადებულ მომსახურების ხელშეკრულებაზე.
    <br />4.3. ყველა მომხმარებელი თანხმდება, რომ ნებისმიერ "კონტაქტთან“ მომსახურების ხელშეკრულების გაფორმებამდე, ისინი გადაამოწმებენ დამატებით დეტალებს, როგორიცაა "კონტაქტის" (თვითდასაქმებული) გარანტიები,  კვალიფიკაცია და ნებისმიერი სხვა დოკუმენტაცია ან ინფორმაცია, რაც განსაზღვრავს კონკრეტული "კონტაქტის"-ის დაქირავების საიმედოობას.
    <br />4.4. Contacti.ge არ წარმოადგენსარცერთ მხარეს მომსახურების ხელშეკრულების, კონტრაქტის ან სხვა ნებისმიერი შეთანხმების, რომელიც შეიძლება წარმოიშვას მომხმარებელსა და „კონტაქტებს“ შორის ჩვენი სერვისით სარგებლობის შედეგად. შესაბამისად, ჩვენ არ ვართ პასუხისმგებელი თქვენთვის რაიმე სახის შეთანხმების შედეგად მოყენებულ ზარალზე ან დანაკარგზე. ეს პუნქტი ძალაში რჩება წინამდებარე ხელშეკრულების შეწყვეტის შემდეგაც.
    <br /><br /><div style={{fontWeight:'bold'}}>5. კონტენტი / შინაარსი</div>
    <br />5.1. თქვენ ეთანხმებით ვებ–გვერდის გამოყენებას მხოლოდ კანონიერი მიზნებისთვის და ისე, რომ არ: შეილახოს უფლებები, შეიზღუდოს ან შეფერხდეს ნებისმიერი სხვა მომხმარებლის მიერ ვებ–გვერდის გამოყენება და სარგებლობა.
    <br />5.2. შეტყობინებები, პროფილები, საქმის/დავალების აღწერილობა, გამოხმაურება და სხვა კონტენტი:
    <br />5.2.1. არ უნდა შეიცავდეს უხამს ლექსიკას;
    <br />5.2.2. არ უნდა შეიცავდეს რასობრივ შეურაცხყოფას, სექსისტურ გამონათქვამებს ან სხვა შეურაცხმყოფელ ლექსიკას; და
    <br />5.2.3. არ უნდა შეიცავდეს თქვენს საკონტაქტო მონაცემებს ან ბმულებს სხვა ვებ-გვერდზე.
    <br />5.3. თქვენ პასუხისმგებელი ხართ ყველა სახის კონტენტზე, რომელსაც აქვეყნებთ, წარადგენთ ან სხვაგვარად ხდით ხელმისაწვდომს ჩვენი სერვისის საშუალებით.
    <br />5.4. თქვენ აცნობიერებთ და იაზრებთ, რომ Contacti.ge არ აკონტროლებს და არ არის პასუხისმგებელი სერვისის საშუალებით გამოქვეყნებულ კონტენტზე. გარდა ამისა, ვებგვერდი და ხელმისაწვდომი კონტენტი შესაძლოა შეიცავდეს ბმულებს სხვა ვებ-გვერდებზე, რომლებიც სრულიად დამოუკიდებელია Contacti.ge- სგან. Contacti.ge არ იძლევა რაიმე სახის გარანტიას ამგვარ ვებგვერდებზე განთავსებული ინფორმაციის სიზუსტეზე, სისრულეზე, უსაფრთხოებასა ან ნამდვილობაზე.
    <br />5.5. სხვა ვებ-გვერდებზე ბმულების მონახულებისას თქვენ პასუხისმგებელი ხართ გაწეეულ რისკზე. თქვენ ეთანხმებით, რომ შეაფასეთ და პასუხისმგებელი ხართ ყველანაირ რისკზე, რომელიც დაკავშირებულია ამ ბმულებით სარგებლობასთან.
    <br />5.6. Contacti.ge არ არის პასუხისმგებელი რაიმე სახის დანაკარგზე ან ზარალზე, რომელიც გამოწვეულია თქვენს მიერ Contacti.ge-ზე გამოქვეყნებული ან სხვაგვარად ხელმისაწვდომი კონტენტის გამოყენების შედეგად.
    <br />5.7. თქვენ აცნობიერებთ, რომ Contacti.ge არ ახდენს ყველა სახის კონტენტის წინასწარ ფილტრაციას/შემოწმებას ან დადასტურებას. ამასთან, Contacti.ge- ს აქვს უფლება, თუმცა არა ვალდებულება თავისი შეხედულებისამებრ, გააკონტროლოს, წაშალოს ან შეცვალოს ნებისმიერი კონტენტის შინაარსი, რომელიც ხელმისაწვდომია ვებგვერდზე ნებისმიერი მიზეზით.
    <br /><br /><div style={{fontWeight:'bold'}}>6. ინტელექტუალური საკუთრება</div>
    <br />6.1. Contacti.ge ფლობს ინტელექტუალური საკუთრების ყველა უფლებას ვებგვერდზე, მათ შორის დიზაინის, ტექსტის, მონაცემთა ბაზის, გრაფიკისა და განლაგების. თქვენ აცხადებთ თანხმობას, რომ არ გამოიყენებთ ან დააკოპირებთ ვებგვერდის რომელიმე ნაწილს ჩვენი ნებართვის გარეშე.
    <br />6.2. Contacti.ge-ის ლოგო შეიძლება გამოყენებულ იქნას „Contactis“– ის მიერ თუკი ისინი დაკავშირებულნი არიან აქტიურ ანგარიშთან. იმ შემთხვევაში, თუ სავაჭრო ანგარიში დაიხურება, ნებისმიერმა „Contacti“-იმ, რომელიც დაკავშირებულია დახურულ ანგარიშთან, უნდა ამოიღოს Contacti.ge-ის ლოგო და მითითება მის კავშირზე Contacti.ge– სთან მარკეტინგული/საინფორმაციო მასალიდან რაც შეიძლება სწრაფად, ანგარიშის დახურვის დღიდან მაქსიმუმ 28 დღის განმავლობაში.
    <br />6.3. იმ შემთხვევაში, თუ თქვენ არა სათანადოდ ან უკანონოდ იყენებთ Contacti.ge–ის საკუთრებაში არსებულ ნებისმიერ ინტელექტუალურ საკუთრებას, თქვენი ანგარიში შეიძლება დაიხუროს და ჩვენ ვიტოვებთ თქვენს წინააღმდეგ შესაბამისი სამართლებრივი ქმედების განხორციელების უფლებას.
    <br />6.4. ინტელექტუალური საკუთრების უფლება ნებისმიერ კონტენტზე, რომელსაც ატვირთავთ Contacti.ge-ზე, ეკუთვნის საავტორო უფლებების მფლობელს. მათი ვებგვერდზე ატვირთვის რისკი გეკისრებათ თქვენ. წინამდებარედ თქვენ მიანიჭებთ Contacti.ge-ს მსოფლიო, არა ექსკლუზიურ, სრულად ანაზღრაურებულ, გადაცემულ, შეუქცევად ლიცენზიას, გამოიყენოს, შეინახოს, დააკოპიროს, შეცვალოს, გადასცეს და გაავრცელოს მონაცემები ნებისმიერი მიზნით.
    <br />6.5. ეჭვის თავიდან აცილების მიზნით, 6.1, 6.2, 6.3 და 6.4 პუნქტები, ისევე როგორც მათში განსაზღვრული უფლებები და მოვალეობები, ძალაში რჩება წინამდებარე ხელშეკრულების შეწყვეტის შემდეგაც.
    <br /><br /><div style={{fontWeight:'bold'}}>7. ურთიერთობა ორგანიზაციებთან და ინდივიდებთან</div>
    <br />7.1. თქვენი ურთიერთქმედება (მათ შორის საქონლის ან მომსახურების და/ან ხელშეკრულების სხვა პირობების საფასურის გადახდა) ვებგვერდის საშუალებით მოძიებულ ორგანიზაციებთან და/ან პირებთან შემოიფარგლება მხოლოდ თქვენსა და ასეთ ორგანიზაციებს შორის.
    <br />7.2. თქვენ ეთანხმებით, რომ Contacti.ge არ იქნება პასუხისმგებელი რაიმე სახის ზარალზე ან ზიანზე, რომელიც წარმოიშვება ნებისმიერ ამგვარი გარიგების შედეგად. თუ თქვენსა და სხვა მომხმარებელს/ Contacti-ს შორის წარმოიქმნება დავა ამ ვებგვერდზე, ან თქვენსა და ნებისმიერ მესამე პირს შორის, თქვენ აცნობიერებთ და ეთანხმებით, რომ Contacti.ge არ არის ვალდებული ჩაერიოს და ჩაერთოს.
    <br /><br /><div style={{fontWeight:'bold'}}>8. უკუკავშირი</div>
    <br />8.1. „Contactis“-ები დაექვემდებარება მომხმარებელთა უკუკავშირს, თუ ინტერესის გამოხატვის შემდეგ მიიღწევა შეთანხმება მომსახურების გაწევის შესახებ რეკლამირებული ან მსგავსი ან დაკავშირებული სამუშაოს ნაწილობრივ ან მთლიანად შესასრულებლად. „Contactis“-ებს ეძლევათ შესაძლებლობა პასუხი გასცენ ნებისმიერ უარყოფით გამოხმაურებას.
    <br />8.2. „Contactis“-ებს ესმით და აცნობიერებენ, რომ Contacti.ge არ არის პასუხისმგებელი კლიენტის/მომხმარებლის მიერ გამოქვეყნებულ გამოხმაურებაზე/უკუკავშირზე ან რაიმე სახის ზიანზე, რაც მსგავსი კონტენტის გამოქვეყნების შედეგად დადგა. კლიენტი/მომხმარებელი, რომელიც აქვეყნებს გამოხმაურებას/უკუკავშირს, უშუალოდ არის პასუხისმგებელი მის შინაარსზე და ნებისმიერი ასეთი გამოხმაურების სიზუსტეზე/კანონიერებაზე.
    <br />8.4. ჩვენ ვატარებთ გადამოწმების სერიას იმის უზრუნველსაყოფად, რომ გამოხმაურებები იყოს ავთენტური და გამოქვეყნებული ნამდვილი მომხმარებლების მიერ. ჩვენ, ჩვენი შეხედულებისამებრ, ამოვიღებთ გამოხმაურებებს, რომლებიც ვთვლით, რომ ყალბია, თუმცა ჩვენ არ ვიძვლევით გარანტიას გამოხმაურებების  სიზუსტეზე ან ნამდვილობაზე. Contacti.ge- ს შეუძლია, თავისი შეხედულებისამებრ, ასევე წაშალოს გამოხმაურება ავტორის მოთხოვნით.
    <br /><br /><div style={{fontWeight:'bold'}}>9. შერჩევის საკომისიო</div>
    <br />9.1. თუ თქვენ ხართ "Contacti", თქვენ თანახმა ხართ გადაიხადოთ Contacti.ge- ს შეფასების საკომისიო ყოველ ჯერზე, როდესაც თქვენ მოხვდებით საქმის/დავალების შერჩვეის სიაში ჩვენი სერვისის მეშვეობით.
    <br />9.2. შეფასების საკომისიო ნაჩვენებია თითოეული ლიდის გვერდზე და ასევე ნათლად გამოჩნდება, როდესაც თქვენ გამოხატავთ ინტერესს საქმის/დავალების მიმართ. თითოეული შეფასების საფასური გამოითვლება სამუშაოს სავარაუდო მოცულობისა და ღირებულების საფუძველზე.
    <br />9.3. თქვენ აცნობიერებთ, რომ Contacti.ge-ის არ შეუძლია გავლენა იქონიოს რომელიმე კონკრეტული შორტლისტის შედეგზე და არ იძლევა გარანტიას, რომ თქვენ შეძლებთ წარადგინოთ ანგარიში ან მოიპოვოთ დასტური იმ საქმეზე/დავალებაზე, რომლის შორტლისთშიც ხართ შერჩეული.
    <br /><br /><div style={{fontWeight:'bold'}}>10. გადახდის პირობები</div>
    <br />10.1. თქვენ ეთანხმებით, რომ ხართ პასუხისმგებელი ჩვენი ინვოისების გადახდაზე ინვოისის გადახდის პირობების შესაბამისად. თუ თქვენს ანგარიშზე წარდგენილია ინვოისი, რომელიც 14 დღეზე მეტია ვადაგადაცილებული, თქვენი ანგარიში ავტომატურად შეიზღუდება.
    <br />10.2. თქვენ აცნობიერებთ, რომ ჩვენ ვიღებთ მხოლოდ ონლაინ ანგარიშსწორებას საკრედიტო ან სადებეტო ბარათით, საბანკო გადარიცხვით ან ან გადახდის აპარატებით. Contacti.ge– ს შეუძლია დანიშნოს ან შეცვალოს ნებისმიერი მესამე მხარე - პროვაიდერი, თქვენი გადახდების მისაღებად.
    <br />10.3. თქვენ თანახმა ხართ მიიღოთ ყველა ინვოისი ელექტრონული ფოსტით თქვენს სავაჭრო ანგარიშზე მითითებული ელ.ფოსტის მისამართზე. თქვენი პასუხისმგებლობაა იმის უზრუნველყოფა, რომ ელ.ფოსტის ეს მისამართი დაცულია და მუშაობს სწორად  
    <br /><br /><div style={{fontWeight:'bold'}}>11. თანხის დაბრუნება</div>
    <br />11.1. თანხის დაბრუნება იშვიათად ხდება, თუმცა ზოგჯერ შესაძლებელია შემოთავავზებული იყოს გარკვეულ ვითარებაში. თანხის დაბრუნების ნებისმიერი გადაწყვეტილება მიიღება ჩვენი შიდა პოლიტიკის შესაბამისად (რომელიც შეიძლება დროდადრო შეიცვალოს) და მიიღება ჩვენი შეხედულებისამებრ.
    <br />11.2. თანხის დაბრუნება „Contactis“-სთვის ხორციელდება მხოლოდ ანგარიშის კრედიტის საშუალებით.
    <br /><br /><div style={{fontWeight:'bold'}}>12. ანგარიშის კრედიტი</div>
    <br />12.1. დროდადრო, Contacti.ge-იმ შეიძლება შემოგთავაზოთ ანგარიშის კრედიტი. ანგარიშის კრედიტი არ შეიძლება შეიცვალოს ან დაბრუნდეს ნაღდი ანგარიშსწორებით. ანგარიშის კრედიტი არ შეიძლება გადაეცეს სხვა სავაჭრო ანგარიშს.
    <br />12.2. ყოველ ჯერზე, როდესაც თქვენ დაგეკისრებათ შეფასების საკომისიო, თქვენი ანგარიშის ბალანსი შემცირდება ლიდის გვერდზე ნაჩვენები შეფასების საკომისიო ღირებულებით. თუ შერჩეული საკომისიო სრულად არ გადაიხდება თქვენი საკრედიტო ანგარიშის ბალანსით, დარჩენილი თანხა გამოიწერება ინვოისის სახით.
    <br />12.3. ანგარიშის კრედიტი გადმოგეცემათ განსაზღვრული სარგებლობის ვადით. ვადის გასვლისთანავე, დარჩენილი საკრედიტო ანგარიში ჩამოგეწერებათ თქვენი ანგარიშის ბალანსიდან.
    <br /><br /><div style={{fontWeight:'bold'}}>13. წარუმატებელი და სადავო გადახდები</div>
    <br />13.1. თუ გაქვთ შეკითხვა თქვენი საკრედიტო ან სადებეტო ბარათიდან ჩამოჭრილ გადასახადთან დაკავშირებით, თანახმა ხართ დაგვიკავშირდეთ ჩვენს საკონტაქტო გვერდზე არსებული ფორმის გამოყენებით.
    <br />13.2. თუ რომელიმე გადახდის მეთოდით, რომელსაც თქვენ იყენებთ, ვერ შესრულდება გადახდა ან ბანკის ან ბარათის გამცემის მიერ მოგვიანებით გამოითხოვება, თქვენ თანახმა ხართ გადაიხადოთ ჩვენს მიერ გაწეული ნებისმიერი დამატებითი ხარჯები
    <br /><br /><div style={{fontWeight:'bold'}}>14. ანგარიშის მფლობელობის ცვლილება</div>
    <br />14.1. არსებობს მხოლოდ შეზღუდული გარემოებები, როდესაც ანგარიშის მფლობელი ან კონტაქტი შეიძლება ჩანაცვლდეს სხვა ფიზიკური ან იურიდიული პირით. ანგარიშის მფლობელი არავითარ შემთხვევაში არ შეიძლება შეიცვალოს იმ პირით ან ორგანიზაციით, რომელიც არ არის დაკავშირებული ანგარიშის მფლობელთან.
    <br />14.2. იმ შემთხვევაში, თუ ანგარიშის მფლობელი არის შეზღუდული პასუხისმგებლობის საზოგადოება /პარტნიორობა და ეს შპს ლიკვიდირდა ან პარტნიორობა წყდება, თქვენ თანახმა ხართ, რომ შეგვატყობინებთ ამის შესახებ დაუყოვნებლივ ჩვენს საკონტაქტო გვერდზე არსებული ფორმის გამოყენებით.
    <br />14.3. ნებისმიერ ანგარიშის მფლობელის და სავაჭრო ანგარიშის კონტაქტის ცვლილება ხდება ჩვენი შეხედულებისამებრ, ჩვენი შიდა პოლიტიკის შესაბამისად, რომელიც შეიძლება დროდადრო შეიცვალოს.
    <br /><br /><div style={{fontWeight:'bold'}}>15. შეზღუდვები</div>
    <br />15.1. თქვენ აცნობიერებთ, რომ Contacti.ge- მ შეიძლება დააწესოს შეზღუდვები ჩვენი ვებგვერდის გამოყენებასთან დაკავშირებით, მათ შორის, თუმცა არ მხოლოდ ვებ-გვერდზე კონტენტის შენახვის დღეების მაქსიმალურ ოდენობა, საქმის/დავალებების გამოქვეყნების, ელ.ფოსტის შეტყობინებებს, ან სხვა კონენტის მაქსიმალური რაოდენობა და მოცულობა, რომელიც შეიძლება გადაეცეს ან ინახებოდეს ვებგვერდზე, ასევე სიხშირეზე, რომლითაც შეგიძლიათ შეხვიდეთ ვებგვერდზე.
    <br />15.2. ყველა მომხმარებელი აცნობიერებს, რომ Contacti.ge არ იძლევა გარანტიას, რომ რომელიმე Contacti გამოთქვამს ინტერესს მათი საქმის/დავალების მიმართ. გარდა ამისა, შეფასების შემთხვევაში ჩვენ არ ვიძლევით გარანტიას, რომ Contacti ხელმისაწვდომი იქნება რომ წარმოგიდგინოთ ანგარიში ან შეასრულოს დავალება. 
    <br />15.3. თქვენ აცნობიერებთ, რომ Contacti.ge არ არის პასუხისმგებელი ვებ – გვერდზე შენახული ან გადაცემული ნებისმიერი შინაარსის წაშლაზე ან შენახვაზე. თქვენ ასევე აცნობიერებთ, რომ Contacti.ge იტოვებს უფლებას ნებისმიერ დროს შეცვალოს ან დახუროს ვებგვერდი (ან მისი ნაწილი) გაფრთხილებით ან მის გარეშე. Contacti.ge არ იქნება პასუხისმგებელი თქვენ ან რომელიმე მესამე მხარის წინაშე ნებისმიერი სერვისის ნებისმიერი ცვლილების, შეჩერების ან დახურვის გამო.
    <br /><br /><div style={{fontWeight:'bold'}}>16. მომსახურების შეწყვეტა</div>
    <br />16.1. თქვენ აცხადებთ თანხმობას,  რომ Contacti.ge- ს, თავისი შეხედულებისამებრ, აქვს უფლება (მაგრამ არა ვალდებულება) მოახდინოს თქვენი ანგარიშის დეაქტივაცია, დაბლოკოს თქვენი ელ.ფოსტის მისამართი, ან სხვაგვარად შეწყვიტოს ან შეზღუდოს თქვენი წვდომა ან სარგებლობა ჩვენი ვებგვერდით. ჩვენ შეგვიძლია გავაკეთოთ ეს დაუყოვნებლივ და თქვენი გაფრთხილების გარეშე, ნებისმიერი მიზეზით, მათ შორის იმ შემთხვევაში, თუ Contacti.ge მიიჩნევს, რომ თქვენ მოქმედებდით ამ შეთანხმების სულისკვეთების საწინააღმდეგოდ.
    <br />16.2. თქვენ აცხადებთ თანხმობას, რომ Contacti.ge არ იქნება პასუხისმგებელი თქვენ ან რომელიმე მესამე მხარის წინაშე ვებგვერდზე თქვენი წვდომის შეწყვეტის ან შეზღუდვის გამო.
    <br />16.3. თქვენ თანახმა ხართ არ სცადოთ ვებგვერდის გამოყენება თქვენი ანგარიშის შეწყვეტის შემდეგ.
    <br /><br /><div style={{fontWeight:'bold'}}>17. ცვლილებები</div>
    <br />17.1. ჩვენ ვიტოვებთ უფლებას დროდადრო შევცვალოთ ან განვაახლოთ ეს შეთანხმება/ხელშეკრულება. როგორც ვებგვერდის მომხმარებელი, თქვენ ხართ პასუხისმგებელი აღიაროთ და დაეთანხმოთ ამ ხელშეკრულების ნებისმიერ განახლებას. თუ თქვენ ამას ვერ შეძლებთ, ჩვენ ვებგვერდზე წვდომა შეიძლება შეგეზღუდოთ.
    <br /><br /><div style={{fontWeight:'bold'}}>18. მმართველი კანონმდებლობა და იურისდიქცია</div>
    <br />18.1. ეს ხელშეკრულება რეგულირდება და განიმარტება საქართველოს კანონმდებლობის შესაბამისად. წინამდებარე ხელშეკრულებასთან დაკავშირებული დავები ექვემდებარება საქართველოს სასამართლოების ექსკლუზიურ იურისდიქციას.
    
    
    </div>
  )
}

export default TermsAndConditionsText