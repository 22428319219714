import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';

const MobileMenuAccordion = ({ title, children, iconClass, url, isLogOut }) => {
  const [isOpen, setOpen] = useState(false);

  const redirectToHome = () => {
    Cookies.remove("ContactiAccessToken", { path: "/" });
    Cookies.remove("ContactiUsername", { path: "/" });
    console.log("gets here");
    window.location.href = "/";
    // props.updateTitle("Home");
  };

  const renderContentByType = () => {
    if (children) {
      return (<div className="accordion-wrapper">

        <div
          className={`accordion-title ${iconClass} ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
          style={{ height: '40px', marginBottom: '5px', paddingBottom: '15px', borderBottom: 'solid 1px #f7f7f7' }}
        >
          {title}
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>)
    } else if (isLogOut) {
      return (<div
        className={`accordion-title logoutdv ${iconClass} ${isOpen ? "open" : ""}`}
        onClick={() => redirectToHome()}
        style={{ height: '40px', marginBottom: '5px', paddingBottom: '15px', borderBottom: 'solid 1px #f7f7f7' }}
      >
        {title}
      </div>)
    }
    return (<div className="accordion-wrapper">
      <a  href={url}>
        <div
          className={`accordion-title pageitems ${iconClass} ${isOpen ? "open" : ""}`}

          style={{ height: '40px', marginBottom: '5px', paddingBottom: '15px', borderBottom: 'solid 1px #f7f7f7' }}
        >
          {title}
        </div>
      </a>
    </div>)
  }

  return renderContentByType()
};

export default MobileMenuAccordion;