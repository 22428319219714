import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { useHistory } from "react-router";
import axios from "axios";
import { StateMachineProvider, createStore } from "little-state-machine";
import { CookiesProvider } from "react-cookie";
import { isMobile } from "react-device-detect";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import _ from "lodash";
import createActivityDetector from "activity-detector";
import rootReducers from "./store/reducer/index";
import io from "socket.io-client";

import Home from "./components/home";
import RegistrationStep1 from "./components/registration/step1";
import RegistrationStep2 from "./components/registration/step2";
import RegistrationStep3 from "./components/registration/step3";
import RegistrationForm from "./components/registration";
import LoginForm from "./components/login";
import AlertComponent from "./components/alert";
import "./App.scss";
import Header from "./components/header";
import Footer from "./components/footer";
import AuthHeader from "./components/header/auth-header";
import Profile from "./components/profile";
import JobPost from "./components/jobPost";
import { PostJobRequest } from "./components/jobPost/postAuthJob";
import Jobs from "./components/jobs";
import { API_BASE_URL } from "./constants/apiConstants";
import Modal from "./components/modal/modal";
import ModalBody from "./components/modal/modalBody";
import ModalHeader from "./components/modal/modalHeader";
import SessionReminder from "./components/session-reminder";
import PaymentDetails from "./components/invoices/payment-details";
import LandingPage from "./components/landing-page";
import Chat from "./chat/chat";
import Process from "./process/process";
import Homepage from "./home/home";
import CustomerJobs from "./components/customer/customer-job/customer-jobs";
import CustomerJobView from "./components/customer/customer-job/jobview";
import CustomerProfile from "./components/customer/customer-details/customer-profile";
import EditCustomerDetails from "./components/customer/customer-details/edit-customer-details";
import ContactUs from "./components/contact-us";
import CustomerJobsMobile from "./components/customer/customer-job/customer-jobs-mobile";
import SPListing from "./components/customer/sp-listing/sp-listing";
import FooterBottom from "./components/footer/footer-bottom";
import CustomerRegistration from "./components/customer-registration/customer-registration";
import ResetPassword from "./components/reset-password";
import ResetConfirmPassword from "./components/reset-confirm-password";
import AboutUs from "./components/aboutus";
import HowItWorksPage from "./components/howitworks";
import MyDetails from "./components/profile/my-details";
import Notifications from "./components/profile/notifications";
import Faq from "./components/FAQ"
createStore({});

const delay = 20;

// const socket = io.connect('/');

// function Appmain(props) {
//   return (
//     <React.Fragment>
//       <div className="chat-app">
//       <div className="right">
//         <Chat
//           username={props.match.params.username}
//           roomname={props.match.params.roomname}
//           socket={socket}
//         />
//       </div>
//       <div className="left">
//         <Process />
//       </div>
//       </div>
//     </React.Fragment>
//   );
// }

function storeLanguageInSessionStorage(language) {
  sessionStorage.setItem("language", language);
}

function App(props) {
  const [title, updateTitle] = useState(null);
  const [errorMessage, updateErrorMessage] = useState(null);
  const paths = [
    "/step2",
    "/register",
    "/registration",
    "/login",
    "/step3",
    "/post-a-job",
  ];
  const authPaths = [
    "/register",
    "/registration",
    "/step2",
    "/step3",
    "/login",
    "/post-a-job",
  ];
  const loggedInPaths = ["/profile", "/job-posted"];
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const contactiCookie = cookies.ContactiAccessToken;
  const durationTimeInterval = 20;



  const username = Cookies.get("ContactiUsername");
  // const derivedLogout = createDerivedLogout(Number(durationTimeInterval ? durationTimeInterval : 60));

  const userType = sessionStorage.getItem("userType");

  //Stop SP from going to customer routes


  if (
    (userType !== "customer" || !username) &&  window.location.pathname.includes("customer")
  ) {
    // window.location.href = "/";
  }

  let languageStoredSessionStorage = sessionStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredSessionStorage ? languageStoredSessionStorage : "en"
  );

  const [show, setShow] = useState(false);

  if (authPaths.includes(window.location.pathname)) {
    document.querySelector("#root").classList.add("auth-background");
    document.querySelector("body").classList.add("body-background");
  }

  const activityDetector = createActivityDetector({
    timeToIdle: 2000, // wait 20s of inactivity to consider the user is idle
  });

  activityDetector.init();

  activityDetector.on("idle", () => {
    console.log("The user is not interacting with the page");
  });

  if (window.location.pathname === "/landing-page") {
    document.querySelector("#root").classList.add("landing-page-shown");
  }

  useEffect(() => {
    if (
      contactiCookie === undefined &&
      loggedInPaths.includes(window.location.pathname)
    ) {
      window.location.href = "/";
    }
  }, []);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  // const routerHistory = useHistory();
  // routerHistory.push('/session-reminder');

  //inactivity then show a pop
  //  if(contactiCookie !== undefined && show) window.location.href = '/session-reminder';

  return (
    <CookiesProvider>
      <StateMachineProvider>
        <Router>
          {/* <div className=""> */}
          {paths.includes(window.location.pathname) ? (
            <AuthHeader />
          ) : (
            <Header />
          )}
          <Switch>
            <Route exact={true} path="/" component={Home} />
            {/* <Home  language={language} /> */}
            {/* <Route path="/join-chat">
                  <Homepage socket={socket} />
                </Route> */}
            {/* <Route path="/chat/:roomname/:username" component={Appmain} /> */}
            <Route path="/aboutus">
              <AboutUs />
            </Route>
            <Route path="/how-it-works">
              <HowItWorksPage />
            </Route>
            <Route path="/contact-us">
              <ContactUs
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/FAQ">
              <Faq
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/registration">
              <CustomerRegistration
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
              {/* <RegistrationForm
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              /> */}
            </Route>
            <Route path="/register">
              <RegistrationStep1
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
              {/* <RegistrationForm
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              /> */}
            </Route>
            <Route path="/step2">
              <RegistrationStep2
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/step3">
              <RegistrationStep3
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/login">
              <LoginForm
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            {/*  */}
            <Route path="/reset-password">
              <ResetPassword
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            <Route path="/reset-confirm-password">
              <ResetConfirmPassword
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            {isMobile ? (
              <Route path="/customer/customer-jobs/:jobid?">
                <CustomerJobsMobile
                  showError={updateErrorMessage}
                  updateTitle={updateTitle}
                />
              </Route>
            ) : (
              <Route path="/customer/customer-jobs/:jobid?">
                <CustomerJobs
                  showError={updateErrorMessage}
                  updateTitle={updateTitle}
                />
              </Route>
            )}
            <Route path="/customer/sp-listing">
              <SPListing
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            <Route path="/customer/jobview">
              <CustomerJobView
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
            <Route path="/customer/customer-profile">
              <CustomerProfile
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            <Route path="/customer/edit-customer-profile">
              <EditCustomerDetails
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>

            <Route path="/customer/customer-jobs/:jobid?">
              <CustomerJobs
                showError={updateErrorMessage}
                updateTitle={updateTitle}
              />
            </Route>
          </Switch>
          {/* <Route path="/post-a-job">
                <PostJob
                  showError={updateErrorMessage}
                  updateTitle={updateTitle}
                />
            </Route> */}

          <Route path="/post-a-job">
            <JobPost showError={updateErrorMessage} updateTitle={updateTitle} />
          </Route>

          <Route path="/job-posted">
            <PostJobRequest
              showError={updateErrorMessage}
              updateTitle={updateTitle}
            />
          </Route>

          <Route path="/jobs/:tab?">
            <Jobs showError={updateErrorMessage} updateTitle={updateTitle} />
          </Route>

          <Route path="/payment-details">
            <PaymentDetails
              showError={updateErrorMessage}
              updateTitle={updateTitle}
            />
          </Route>
          <Route path="/profile">
            <Profile showError={updateErrorMessage} updateTitle={updateTitle} />
          </Route>
          <Route path="/mprofile">
            <MyDetails />
          </Route>
          <Route path="/notifications">
            <Notifications />
          </Route>
          <Route path="/landing-page">
            <LandingPage
              showError={updateErrorMessage}
              updateTitle={updateTitle}
              language={language}
            />
          </Route>
          <AlertComponent
            errorMessage={errorMessage}
            hideError={updateErrorMessage}
          />
          {paths.includes(window.location.pathname) ? "" : <Footer />}

          <FooterBottom
            language={language}
            handleSetLanguage={(language) => {
              setLanguage(language);
              storeLanguageInSessionStorage(language);
            }}
          />

          <Route path="/session-reminder">
            <SessionReminder
              showError={updateErrorMessage}
              updateTitle={updateTitle}
            />
          </Route>
          {/* </div> */}
        </Router>
      </StateMachineProvider>
    </CookiesProvider>
  );
}

export default App;
