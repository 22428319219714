import React, { useEffect } from 'react';

const CustomerJobView = ()=> {

 
      return (
        <div>
          <div className="container">
            <div className="header">
              {/*?php include '../includes/header.php';?*/}
            </div>
          </div>
          <div className="edit-details">
            <div className="editDetails-title-container">
              <a onclick="goBack()">
                <i className="bi bi-arrow-left-circle editDetails-back-btn" />
              </a>
              <p className="edit-details-title">Your job</p>
            </div>
          </div>
          <div className="carousel slide" data-ride="carousel">
            <ul className="carousel-indicators">
              <li data-target="#jobCarousel" data-slide-to={0} className="active" />
              <li data-target="#jobCarousel" data-slide-to={1} />
              <li data-target="#jobCarousel" data-slide-to={2} />
            </ul>
            <div className="carousel-inner">
              <div id="customer-jobs-gallery">
                <form action method="post" encType="multipart/form-data">
                  <input type="file" multiple className="custom-file-input" name="image" id="fileToUpload" onChange="SelectChange()" />
                  <label className="customer-jobs-addImg-btn" id="uploadLabel" htmlFor="image"><i className="bi bi-plus plus-icon" /> Add more photos</label>
                </form>
              </div>
              <div id="customer-jobs-gallery-del">
                <div className="customer-jobs-del-btn" data-toggle="modal" data-target="#delCustomerJobImgModal"><i className="bi bi-trash trash-icon" /> Delete</div>
              </div>
              <div className="carousel-item job-carousel active">
                <img src="../images/gallery/house.jpg" className="customer-jobs-img" />
              </div>
              <div className="carousel-item job-carousel">
                <img src="../images/gallery/interior.jpg" className="customer-jobs-img" />
              </div>
              <div className="carousel-item job-carousel">
                <img src="../images/gallery/slide1.jpg" className="customer-jobs-img" />
              </div>
            </div>
          </div>
          {/*This div is for a job with no photo
          <div className="jobs-img-expand no-photo">
              <img src="../resources/icons/no-photo-lg.png" className="no-photo-expanded" />
          </div>*/}
          <div className="modal fade" id="delCustomerJobImgModal" tabIndex={-1} aria-labelledby="delCustomerJobImgModal" aria-hidden="true">
            <div className="modal-dialog modal-sm">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="delCustomerJobImgModal">Delete images</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="default-font">Do you really want to delete gallery image/images?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger default-font" data-dismiss="modal" onclick="remove_customerJobImg();">Delete</button>
                  <button type="button" className="btn btn-danger default-font" data-dismiss="modal">Delete all</button>
                </div>
              </div>
            </div>
          </div>
          <div className="container jobsItem-inner">
            <div className="customer-job-details">
              <p className="jobs-category">I need to clean my house and garage</p>
              <p className="jobs-post-date no-margin">2 days ago</p>
            </div>
            <div className="customer-job-details">
              <p className="job-poster">Handyman</p>
              <span className="jobs-price jobsItem-price">150₾</span>
            </div>
            <div className="jobs-location user-attribute">
              <i className="bi bi-geo-alt jobs-details-location" /><span className="jobs-location-name jobs-details-location">Dighomi District</span>
            </div>
            <div className="jobs-apply-container">
              <p className="jobs-apply-info">In publishing and graphic design, Lorem ipsum is a placeholder text commonly
                used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
              </p>
            </div>
            <div className="form-group" id="customer-edit-container">
              <textarea className="form-control customer-job-description shadow-none" placeholder="Add more text to your description..." rows={3} defaultValue={""} />
            </div>
            <button type="button" className="btn btn-primary btn-lg btn-block apply-btn" id="edit-job" onclick="editJob();">Edit your job</button>
            <button type="button" className="btn btn-primary btn-lg btn-block apply-btn" id="submit-edited-job" onclick="submitEditedJob();">Submit</button>
            <button type="button" className="btn btn-primary btn-lg btn-block apply-btn" id="remove-job" data-toggle="modal" data-target="#removeJobModal">Remove your job</button>
            <div className="modal fade" id="removeJobModal" tabIndex={-1} aria-labelledby="removeJobModal" aria-hidden="true">
              <div className="modal-dialog modal-lg remove-job-modal">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="removeJobModal">Reason of removing the job</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="remove-job-modal-body">
                    <div className="form-check remove-job-options">
                      <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option1" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option1">
                        I don't want to post a job
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="form-check remove-job-options">
                      <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option2" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option2">
                        I have already found service provider outside of platform
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="form-check remove-job-options">
                      <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option3" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option3">
                        I haven't found anyone on this platform
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="form-check remove-job-options">
                      <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option4" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option4">
                        This platform is clumsy
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="form-check remove-job-options">
                      <input className="form-check-input remove-job-radio" type="radio" name="exampleRadios" id="option5" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option5">
                        I want to post a job from the beginning
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="form-check remove-job-options remove-job-radio">
                      <input className="form-check-input" type="radio" name="exampleRadios" id="option6" defaultValue="option1" />
                      <label className="form-check-label paid-invoices" htmlFor="option6">
                        Other reason
                      </label>
                    </div>
                    <hr className="remove-job-options-hr" />
                    <div className="input-group modal-body">
                      <textarea className="form-control" aria-label="With textarea" rows={5} placeholder="Write review comment" defaultValue={""} />
                      <img src="../resources/icons/pencil.png" width="17px" height="17px" className="remove-job-pencil-icon" />
                      <p className="remove-job-max-symbols">0 / 200 symbols</p>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-block default-font" data-dismiss="modal" onclick="remove_customerJobImg();">Submit</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="white-space" />
          </div>
        </div>
      );
    }

  export default CustomerJobView;