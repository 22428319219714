import "./sp-listing.scss";
import SpProfileDetails from "./sp-profile-details"
import ReactPaginate from "react-paginate"

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

import { API_BASE_URL } from "../../../constants/apiConstants";

const SPListing = () => {

  /*Pagination Setup */

  const [pageNumber, setPageNumber] = useState(0);

  const postsPerPage = 4;
  const pagesVIsited = pageNumber * postsPerPage;

  /*Pagination Setup  */

  const [openModal, setOpenModal] = useState(false);
  const [selectedSpId, setSelectedSpId] = useState(null);

  const location = useLocation();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [serviceProviders, setServiceProviders] = useState(false);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [mustUpdateSPs, setMustUpdateSPs] = useState(0);

  useEffect(() => {
    const { jobId } = location.state;
    const feedbackUrl = `${API_BASE_URL}/api/sp/all/get/${jobId}`;

    const feedbackAxios = axios.create({
      withCredentials: false,
      baseURL: feedbackUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const getServiceProviderData = async () => {
      try {
        //setIsLoading(true);
        const result = await feedbackAxios.post(`${feedbackUrl}`);
        setServiceProviders(result.data);
        //setIsLoading(false)

        //setMustUpdateSPs(Math.floor((Math.random() * 100000000000) + 1));
      } catch (err) {
        console.log("Request faied", err);
      }
    };
    getServiceProviderData();
  }, [mustUpdateSPs, cookies.ContactiAccessToken]);

  const sendJobInvite = async (e, serviceProviderId) => {
    e.preventDefault();
    const { jobId } = location.state;
    const updateUrl = API_BASE_URL + "/api/cs/jobs/invite/add";

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const response = await authAxios.post(`${updateUrl}`, {
      serviceProviderId: serviceProviderId,
      jobPostId: jobId,
    });

    if (response.data) {
      const filteredSPs = serviceProviders.filter(
        (sp) => sp.id === serviceProviderId
      );

      console.log("filteredSPs", filteredSPs);
      filteredSPs[0].isInvited = true;

      setServiceProviders(filteredSPs);
    }

    setMustUpdateSPs(Math.floor(Math.random() * 100000000000 + 1));
  };



  const getSelectedSpId = (id) => {
    setSelectedSpId(id);
    // console.log(selectedSpId);

  }


  /*Pagination Functionality*/
  const pageCount = Math.ceil(serviceProviders.length / postsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displaySpListingPaging = serviceProviders
    ? serviceProviders
        .slice(pagesVIsited, pagesVIsited + postsPerPage)
        .map((value, index) => {
          {
            index = postsPerPage * pageNumber + index;
          }
          return (
            <>
                <div className="sp-listing-item">
                  <div className="row no-gutters">
                    <div className="jobs-img-container">
                      <img
                        style={{cursor: 'pointer'}}
                        src={value.Avatar ? `${API_BASE_URL}${value.Avatar}` : "../images/profile-picture.jpg"}
                        className="sp-listing-img"
                        onClick={() => {setOpenModal(true); getSelectedSpId(value.id)}}
                      />
                    </div>
                    <div className="col jobs-inner">
                      <p className="jobs-title-profile" onClick={() => {setOpenModal(true); getSelectedSpId(value.id)}}>{`${value.firstName} ${value.lastName}`}</p>
                      <p className="job-poster">{value.jobCategory}</p>
                      <div className="user-attribute">
                        <i className="bi bi-geo-alt" />
                        <span className="jobs-location-name">{`${value.address}, ${value.district}`}</span>
                      </div>
                      {value.isInvited ? (
                        <button
                          type="button"
                          className="btn btn-secondary sp-listing-inviteBtn disabled"
                        >
                          თქვენ უკვე მოიწვიეთ
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary sp-listing-inviteBtn"
                          onClick={(e) => sendJobInvite(e, value.id)}
                        >
                          მოწვევა
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters jobs-content-footer">
                    <p className="feedback-positive sp-listing-feedback">
                      {value.positiveFeedbackPercentage}% პოზიტიური შეფასება
                    </p>
                  </div>
                  <div className="sp-listing-last-feedback">
                    <p className="jobs-title">ბოლო შეფასება</p>
                    <p className="jobs-apply-info">
                      აქ გამოჩნდება ბოლოს დაწერილი შეფასება <a href="#">მეტის ნახვა</a>
                    </p>
                  </div>
                  <div className="sp-listing-last-feedback">
                    <p className="jobs-title">სერვისის პროვაიდერის შესახებ</p>
                    <p className="user-attribute">
                      {value.description} <a href="#">მეტის ნახვა</a>
                    </p>
                  </div>
                  <div className="sp-listing-last-feedback">
                    <button
                      type="button"
                      className="btn btn-primary btn-block sp-listing-inviteBtn-mobile disabled"
                    >
                      You have sent invitation
                    </button>
                  </div>
                </div>
            </>
          );
        })
    : "";

  /* Pagination Functionality */




  return (
    <div>
      <div className="sp-listing-bg">
        <div className="container">
          <p className="profile-title">სერვისის პროვაიდერები</p>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters sp-listing-search">
          <div className="col-10 col-lg-4 col-md-10">
            <img
              className="ic-search"
              src="../resources/icons/search-icon.png"
            />
            <div className="input-group">
              <input
                type="text"
                className="form-control search-form"
                id="jobsFilter"
                placeholder="მოძებნე კატეგორიით..."
              />
            </div>
            
            {openModal && <SpProfileDetails closeModal={setOpenModal} selectedSpId={selectedSpId} serviceProvidersInfo={serviceProviders}/>}
          </div>
          {/* <div className="col-2 col-lg-2 offset-lg-6 col-md-2">
            <div
              data-target="#exampleModalCenter"
              className="listing-filter"
              data-toggle="modal"
              onClick={() => setShowFilterModal(true)}
            >
              <i className="bi bi-sliders listing-filter-icon" />
              <span className="listing-filter-txt">ფილტრი</span>
            </div>
          </div> */}
        </div>
        <div className="job-posted-container">
          <div className="job-posted-success">
            <i className="bi bi-check2 send-success-icon" />
            <p className="send-success-txt">The form has sent successfully</p>
            <p className="contact-detail">
              Scroll down and check suitable for you service providers
            </p>
            <button
              type="button"
              className="btn btn-primary btn-block default-font"
            >
              View your job
            </button>
          </div>
        </div>
      </div>
      {/* {showFilterModal ? (
        <div
          className="modal fade"
          id="listingFilterModal"
          data-backdrop="true"
          data-keyboard="false"
          tabIndex={-1}
          aria-labelledby="filterModal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm cat-modal-dialog">
            <div className="modal-content">
              <div className="cat-modal-header">
                <h5 className="modal-title" id="jobcatmodalLabel">
                  ფილტრი
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowFilterModal(false)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row cat-container">
                  <div className="cat-checkbox">
                    <label htmlFor="catInput" id="catValue">
                      <input type="checkbox" id="catInput" />
                      <span className="cr">
                        ახალი სერვის პროვაიდერები
                        <i className="bi bi-check" id="checkId" />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="row cat-container">
                  <div className="cat-checkbox">
                    <label>
                      <input type="checkbox" defaultValue />
                      <span className="cr">
                        საუკეთესო შეფასებები
                        <i className="bi bi-check" />
                      </span>
                    </label>
                  </div>
                </div>
                <div className="row cat-container">
                  <div className="cat-checkbox">
                    <label>
                      <input type="checkbox" defaultValue />
                      <span className="cr">
                        ყველაზე მაღლი საფასურის
                        <i className="bi bi-check" />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="cat-modal-footer">
                <button
                  type="button"
                  className="btn btn-primary btn-block cat-submit"
                  onClick={() => setShowFilterModal(false)}
                >
                  განახლება
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )} */}
      <div className="sp-listing-container">
        <div className="container">
          {displaySpListingPaging}
          {
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={"paginationButtons"}
              // previousLinkClassName={"prevButton"}
              // nextLinkClassName={"nextBUtton"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              // pageLinkClassName={"pagingButtons"}
              renderOnZeroPageCount={null}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default SPListing;
