import React from "react";

const SignUpHeader = () => {
  return (
    <div className="sign-up-header">
      <h3>დარეგისტრირდით</h3>
      <small>
      {/* შეავსეთ ქვემოთ მოცემული ველები <strong>(ყველა მათგანი სავალდებულოა)</strong> */}
      შეავსეთ ქვემოთ მოცემული ველები
      </small>
    </div>
  );
};

export default SignUpHeader;
