import { useStateMachine } from 'little-state-machine';
import React, {useEffect, useState} from 'react';
import { useForm,Controller } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput , { isValidPhoneNumber } from 'react-phone-number-input';

import SignUpHeader from './sign-up-header';
import updateAction from './updateAction';
import { getRegistrationFields, getAddressList } from '../../services/content';
import Footer from '../footer';
import PreSignUpHeader from './pre-sign-up-header';

const RegistrationStep1 = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  
  const { actions, state } = useStateMachine({ updateAction });
  const [value, setValue] = useState();
  const [errorClass ,setErrorClass] = useState(false);
  const phoneInput = document.querySelector('.PhoneInputInput');
  
  const test = getRegistrationFields();
  console.log('Renders this',test);
  const districtList = getAddressList();
  console.log('Renders these districts',districtList);


  const regObj = localStorage.getItem('reg_labels');
  const regLabels = JSON.parse(regObj);
  console.log('From lS',regLabels);

  const onSubmit = (data) => {
    console.log(data);
 
    if (data.email !== data.confirmEmail) {
      props.showError("შეყვანილი ელ-ფოსტები არ ემთხვევა!");
      return;
    }

    actions.updateAction(data);
    props.history.push("./step2");
  };

  return (
    <div className="card col-12 col-lg-6 col-xl-3 login-card mt-5 hv-center">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <PreSignUpHeader />
          <div className="reg-form-inner">
            <div className="form-group text-left">
              <input
                type="text"
                className={`form-control ${
                  errors && errors.firstName ? "error" : ""
                }`}
                {...register("firstName", { required: true, maxLength: 30 })}
                id="firstName"
                placeholder={regLabels.firstName}
                defaultValue={""}
              />
            </div>
            <div className="form-group text-left">
              <input
                type="text"
                className={`form-control ${
                  errors && errors.lastName ? "error" : ""
                }`}
                id="lastName"
                placeholder={regLabels.lastName}
                {...register("lastName", { required: true, maxLength: 30 })}
                defaultValue={""}
              />
            </div>

            <div className="form-group text-left">
              <Controller
                name="mobileNumber"
                control={control}
                {...register("mobileNumber", { required: true, maxLength: 30 })}
                rules={{
                  validate: (value) => isValidPhoneNumber(value)
                }}

                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    placeholder={regLabels.mobileNumber}
                    value={value}
                    onChange={onChange}
                    defaultCountry="GE"
                    id="mobileNumber"
                  />
                )}
              />
              {errors["mobileNumber"] && (
                <p className="error-message">შეცდომა! არასწორი მობილურის ნომერი</p>
              )}
            </div>
        
            <div className="form-group text-left">
              <input
                type="email"
                className={`form-control ${
                  errors && errors.email ? "error" : ""
                }`}
                id="email"
                placeholder={regLabels.emailAddress}
                defaultValue={""}
                {...register("email", { required: true, maxLength: 60 })}
              />
            </div>
            <div className="form-group text-left">
              <input
                type="email"
                className={`form-control ${
                  errors && errors.confirmEmail ? "error" : ""
                }`}
                id="confirmEmail"
                placeholder={regLabels.cofirmEmailAddress}
                defaultValue={""}
                {...register("confirmEmail", { required: true, maxLength: 60 })}
              />
            </div>
            
          </div>
          <button type="submit" className="btn btn-primary register-btn first-step-btn fcaps">
            {regLabels.nextButtonLabel}
          </button>
        </form>
      </div>
      
    </div>
  );
};

export default withRouter(RegistrationStep1);
