import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { API_BASE_URL } from '../../constants/apiConstants';

const JobCategory = () => {
    const [showDeleteCatItem, setShowDeleteCatItem] = React.useState(-1);
    const [spCategories, setSPCategories] = React.useState([]);
    const [cmsCategories, setCMSCategories] = React.useState([]);
    const [spSelectJobCategoriesModal, setSPSelectJobCategoriesModal] = React.useState(false);
    const [mustUpdateCategories, setMustUpdateCategories] = useState(0)
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);

    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/sp/jobcategories/get";

        const authAxios = axios.create({
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                const result = await authAxios.post(`${apiUrl}`);
                setSPCategories(result.data);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [mustUpdateCategories, cookies.ContactiAccessToken]);

    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/metadata/ka/jobcategories";

        const fetchCMSCats = async () => {
            try {
                const result = await axios.get(`${apiUrl}`);
                setCMSCategories(result.data);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchCMSCats();
    }, []);

    const deleteCatItem = async (catId) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobcategories/remove";

        const authAxios = axios.create({
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "id": catId });
        setMustUpdateCategories(Math.floor((Math.random() * 100000000000) + 1));
        setShowDeleteCatItem(-1);
    };

    const addCatItem = async (catId) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobcategories/add";

        const authAxios = axios.create({
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "jobCategoryId": catId });
        setMustUpdateCategories(Math.floor((Math.random() * 100000000000) + 1));
    };

    const isPartOfSpCats = (cmsCatId) => {
        return spCategories.findIndex(c => c.cmsJobCategoryId === cmsCatId) >= 0;
    };

    const addOrRemoveCat = async (cmsCatId, e) => {
        if (e.target.checked) await addCatItem(cmsCatId);
        else await deleteCatItem(cmsCatId);
    };

    return (
        <div className="job-cat-container">
            <div className="job-cat-inner">
                {spCategories ? spCategories.map((value, index) => (
                    <>
                        <div className="row job-category" id={'catItem' + value.id}>
                            <div className="col-lg-10">
                                <p className="job-category-txt">{value.name}</p>
                            </div>
                            <div className="col-lg-2">
                                <img className="delete-btn" alt="კატეგორიის წაშლა"
                                    src="../resources/icons/delete.png" width="24px"
                                    data-toggle="modal" data-target="#deleteCatModal" onClick={() => setShowDeleteCatItem(value.id)} />
                            </div>
                        </div>
                        {showDeleteCatItem === value.id ?
                            <div className="modal fade bd-example-modal-sm" id={'deleteCatModal' + value.id} tabIndex={index} role="dialog" aria-labelledby="deleteCatModal" aria-hidden="true">
                                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">კატეგორიის წაშლა</h5>
                                            <button onClick={() => setShowDeleteCatItem(-1)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body default-font">
                                            დარწმუნებული ხართ რომ გსურთ "{value.name}" კატეგორიის წაშლა?
                                </div>
                                        <div className="modal-footer">
                                            <button type="button" className="dismiss-btn-modal default-font" data-dismiss="modal">გაუქმება</button>
                                            <button type="button" className="delete-btn-modal default-font" data-dismiss="modal" onClick={() => deleteCatItem(value.id)}>წაშლა</button>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                        }
                    </>
                )) : ''}
            </div>
            <div className="addcat-btn-container">
                <button type="button" data-toggle="modal" data-target="#jobcatmodal" onClick={() => setSPSelectJobCategoriesModal(true)} className="btn btn-primary btn-block"><span className="add-category-btn">+ &nbsp; სხვა კატეგორიების დამატება</span></button>
            </div>
            {spSelectJobCategoriesModal ?
                <div className="modal fade" id="jobcatmodal" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable cat-modal-dialog">
                        <div className="modal-content">
                            <div className="cat-modal-header">
                                <h5 className="modal-title" id="jobcatmodalLabel">აირჩიე კატეგორია</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSPSelectJobCategoriesModal(false)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <img className="ic-search" alt="search categories" src="./resources/icons/search-icon.png" width="16px" height="16px" />
                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control search-form" id="cat-search" placeholder="საძიებო სიტყვა..." />
                                        </div>
                                    </div>
                                </div>
                                {cmsCategories ? cmsCategories.map((value, index) => (
                                    <div className="row cat-container">
                                        <div className="cat-checkbox" >
                                            <label htmlFor={'input' + value.id} id={'label' + value.id}>
                                                <input type="checkbox" id={'input' + value.id} defaultChecked={isPartOfSpCats(value.id)} onChange={e => addOrRemoveCat(value.id, e)} />
                                                <span className="cr">{value.name}<i className="bi bi-check" id="checkId" /></span>
                                            </label>
                                        </div>
                                    </div>)) : ''
                                }
                            </div>
                            <div className="cat-modal-footer">
                                <button type="button" className="btn btn-primary btn-block cat-submit" data-dismiss="modal" onClick={() => setSPSelectJobCategoriesModal(false)}>დამატება</button>
                            </div>
                        </div>
                    </div>
                </div> : ''
            }
        </div >
    )
}

export default JobCategory;