import styles from "./styles/modal.module.scss";

export default function Modal(props) {
  return (
    <div className="modal d-block modal-background" className={`modal d-block modal-background ${styles.modal}`}>
      <div className="modal-dialog">
        <div className="modal-content">{props.children}</div>
      </div>
    </div>
  );
}
