import React, { useEffect, useState} from "react";

var EditCustomerDetails = () => {
    return (
        <div className="mobile-sidebar">
            <div className="sidebar-menu-containers">
                <div className="sidebar-header-mobile">
                    <div className="profile-picture">
                        <img src="../images/profile-picture.jpg" className="profile-picture-img" />
                    </div>
                    <div className="col-9 col-md-10 col-lg-8">
                        <div className="row">
                            <p className="profile-name">Mishka B.</p>
                        </div>
                        <div className="row">
                            <p className="profile-feedback">76% positive feedback</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="edit-details">
                <div className="editDetails-title-container">
                    <a onclick="goBack()">
                        <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                    </a>
                    <p className="edit-details-title">My details</p>
                </div>
            </div>
            <span className="details-text details-text-mobile">Contact details are displayed to job posters only when you are shortlisted</span>
            <div className="details-container">
                <div className="row">
                    <div className="col-12">
                        <i className="bi bi-person-circle edit-details-icon" />
                        <span className="edit-details-label">First and last name</span>
                        <div className="input-group mb-2">
                            <input className="form-control details-form" type="text" defaultValue="Mishka Bakhtadze" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <i className="bi bi-telephone edit-details-icon" />
                        <span className="edit-details-label">Mobile number</span>
                        <div className="input-group mb-2">
                            <input className="form-control details-form" type="tel" defaultValue={555177228} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <i className="bi bi-envelope edit-details-icon" />
                        <span className="edit-details-label">E-mail</span>
                        <div className="input-group mb-2">
                            <input className="form-control details-form" type="email" defaultValue="mishbakhtadze@gmail.com" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <i className="bi bi-eye edit-details-icon" />
                        <span className="edit-details-label">Password</span>
                        <div className="input-group mb-2">
                            <input className="form-control details-form" type="password" defaultValue="Mishka" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cat-modal-footer">
                <button type="button" className="btn btn-primary btn-block cat-submit">Submit</button>
            </div>
        </div>
    );
}

export default EditCustomerDetails;