import "./index.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ka from "javascript-time-ago/locale/ka.json";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
// import ReactGA from "react-ga";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import rootReducers from "./store/reducer";
import { createBrowserHistory } from "history";
TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ka);
const store = createStore(rootReducers);
const AppC = () => {
  const history = createBrowserHistory();

  // useEffect(() => {
  //   const TRACKING_ID = "UA-233909580-1";
  //   ReactGA.initialize(TRACKING_ID);

  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [history]);

  return <App />;
};

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AppC />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
