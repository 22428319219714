import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { isMobile } from "react-device-detect";
import { API_BASE_URL } from '../../constants/apiConstants';
import { de } from 'date-fns/locale';
// change test
const MyDetails = () => {
    const { register, handleSubmit } = useForm();
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [profile, setProfile] = useState([])
    const [mustUpdateProfile, setMustUpdateProfile] = useState(0)
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [spCategoriesMobile, setSpCategoriesMobile] = useState([]);
    const [mustUpdateCategoriesMobile, setMustUpdateCategoriesMobile] = useState(0);
    const [selectSPJobCategoriesModalMobile, setSelectSPJobCategoriesModalMobile] = useState(false);
    const [cmsCategoriesMobile, setCMSCategoriesMobile] = useState([]);
    const [selectedCatId, setSelectedCatId] = useState(-1);


    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/user/profile";

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                const result = await authAxios.post(`${apiUrl}`);
                setProfile(result.data);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [mustUpdateProfile, cookies.ContactiAccessToken]);

    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/sp/jobcategories/get";

        const authAxios = axios.create({
            baseURL: apiUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                const result = await authAxios.post(`${apiUrl}`);
                setSpCategoriesMobile(result.data);
                console.log("SDFSDFSDF", result.data)

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [mustUpdateCategoriesMobile, cookies.ContactiAccessToken]);

    useEffect(() => {

        const apiUrl = API_BASE_URL + "/api/metadata/ka/jobcategories";

        const fetchCMSCats = async () => {
            try {
                const result = await axios.get(`${apiUrl}`);
                setCMSCategoriesMobile(result.data);
                // console.log("CMS", result.data)

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchCMSCats();
    }, []);

    const addCategoryMobile = async (catId) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobcategories/add";

        const authAxios = axios.create({
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "jobCategoryId": catId });
        setMustUpdateCategoriesMobile(Math.floor((Math.random() * 100000000000) + 1));
        
    }
    const removeCategoryMobile = async (catId) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobcategories/remove";

        const authAxios = axios.create({
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "id": catId });
        setMustUpdateCategoriesMobile(Math.floor((Math.random() * 100000000000) + 1));

    }

    const isPartOfSpCatsMobile = (cmsCatId) => {
        return spCategoriesMobile.findIndex(c => c.cmsJobCategoryId === cmsCatId) >= 0;
    };

    const addOrRemoveCategoryMobile = async (cmsCatId, e) => {

        if (e.target.checked) await addCategoryMobile(cmsCatId)
        else await removeCategoryMobile(cmsCatId)

    }

    // console.log(profile.firstName)


    const onSubmit = async (data) => {
        const updateUrl = API_BASE_URL + "/api/user/updateprofile";

        const authAxios = axios.create({
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        data.CompanyId = 28; // Hardcoded CompanyId value. Because CompanyId value is required on endpoint. This is a temporary solution 

        await authAxios.post(`${updateUrl}`, data);
        setMustUpdateProfile(Math.floor((Math.random() * 100000000000) + 1));
        setModalIsOpen(false);
    };

    return (<>
        <div className={`${isMobile} && mx-2`}>

            {isMobile ?
                <div className="details-text">
                    <i className="bi bi-pencil-square edit-myDetailsm" onClick={() => setModalIsOpen(true)} />
                </div>
                :
                <span className="details-text">
                    <i className="bi bi-pencil-square edit-myDetails" onClick={() => setModalIsOpen(true)} />
                </span>
            }


            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">სახელი</p>
                    <p className="user-parameter" id="detailsItem">{profile.FirstName}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">გვარი</p>
                    <p className="user-parameter">{profile.LastName}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">მობილური</p>
                    <p className="user-parameter">{profile.MobileNumber}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">ელ. ფოსტა</p>
                    <p className="user-parameter">{profile.Email}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">ფაქტობრივი მისამართი</p>
                    <p className="user-parameter">{profile.Email}</p>
                </div>
            </div>
            {/* <hr className="details-hr" />
        <div className="row">
            <div className="col-12">
                <p className="user-attribute">Job Category</p>
                <p className="user-parameter">{profile.jobCategory}</p>
            </div>
        </div> */}
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">სამუშაოს კატეგორია</p>
                    <p className="user-parameter">{profile.BusinessCategory}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">კომპანიის დასახელება</p>
                    <p className="user-parameter">{profile.BusinessCategory}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">კომპანიის მისამართი</p>
                    <p className="user-parameter">{profile.BusinessCategory}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">მოგვიყევით თქვენი ბიზნესის შესახებ</p>
                    <p className="user-parameter">{profile.Description}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">სამუშაო ადგილი</p>
                    <p className="user-parameter">-</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">პირადი ნომერი</p>
                    <p className="user-parameter">{profile.IdNumber}</p>
                </div>
            </div>
            <hr className="details-hr" />
            <div className="row">
                <div className="col-12">
                    <p className="user-attribute">პირადობის ატვირთვა (წინა მხარე)</p>
                    <img className="id-card" src="./images/id-card.jpg" width="130px" />
                </div>
            </div>
        </div>
        {/*DETAILS EDIT*/}
        {modalIsOpen ?
            <div className="modal fade" id="editdetails" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="cat-modal-header">
                                <h5 className="modal-title" id="editdetailsLabel">პროფილის დეტალების რედაქტირება</h5>
                                <button type="button" onClick={() => setModalIsOpen(false)} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-person-circle edit-details-icon" />
                                        <span className="edit-details-label">სახელი</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" id="detailsInput" type="text" defaultValue={profile.FirstName} {...register("FirstName", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-person-circle edit-details-icon" />
                                        <span className="edit-details-label">გვარი</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="text" defaultValue={profile.LastName} {...register("LastName", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-telephone edit-details-icon" />
                                        <span className="edit-details-label">მობილური</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="number" defaultValue={profile.MobileNumber} {...register("MobileNumber", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-envelope edit-details-icon" />
                                        <span className="edit-details-label">ელ. ფოსტა</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="email" defaultValue={profile.Email} {...register("Email", {
                                                required: "Required",
                                            })} readonly />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-eye edit-details-icon" />
                                        <span className="edit-details-label">პაროლი</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="password" defaultValue="must this be here. use maybe some other process here" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-geo-alt edit-details-icon" />
                                        <span className="edit-details-label">ფაქტობრივი მისამართი</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="text" defaultValue={profile.LivingAddress} {...register("LivingAddress", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <img src="./resources/icons/pencil.png" width="17px" height="17px" className="edit-details-icon" />
                                        <span className="edit-details-label">მოგვიყევით თქვენი ბიზნესის შესახებ</span>
                                        <textarea className="form-control details-textarea" aria-label="With textarea" rows={4} defaultValue={profile.Description} {...register("Description", {
                                            required: "Required",
                                        })} />
                                        <p className="max-symbols details-symbol-limit">0 / 200 სიმბოლო</p>
                                    </div>
                                </div>
                                {/* <div className="row">
                                <div className="col-12">
                                    <span className="edit-details-label">Job category</span>
                                    <div className="input-group mb-2">
                                        <select className="custom-select details-form" {...register("firstName", {
                                                required: "Required",
                                            })}>
                                            <option selected>Select category</option>
                                            <option value={1}>Plumber</option>
                                            <option value={2}>Electrician</option>
                                            <option value={3}>Designer</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* <span className="edit-details-label">სამუშაოს კატეგორია</span>
                                        <textarea onClick={() => setSelectSPJobCategoriesModalMobile(true)} className="form-control details-textarea" aria-label="With textarea" rows={4} defaultValue={profile.Description} {...register("Description", {
                                            required: "Required",
                                        })} /> */}
                                        <span className="edit-details-label">სამუშაოს კატეგორია</span>
                                        {/* <div >დამატება/წაშლა</div> */}
                                        <div className='add__remove__cat' onClick={() => setSelectSPJobCategoriesModalMobile(true)}></div>
                                        <div style={{border:'2px solid #d3d3d3', borderRadius:'4px', marginBottom:'10px', padding:'15px', paddingTop:'30px'}}>
                                            {spCategoriesMobile ? spCategoriesMobile.map((category, index) => (
                                                <>
                                                    <div className='category__list__mobile'>
                                                        <div className='category__item' key={index}>{category.name}</div>
                                                        <div className='delete__category__trash'>
                                                            <img className="delete__btn__mobile" alt="კატეგორიის წაშლა"
                                                                src="../resources/icons/delete.png" width="20px" onClick={() => {setSelectedCatId(category.id)}}
                                                            />
                                                        </div>                                                    
                                                    </div>
                                            

                                                    {selectedCatId === category.id ? <div className="modal fade bd-example-modal-sm" id={'deleteCatModal' + category.id} tabIndex={index} role="dialog" aria-labelledby="deleteCatModal" aria-hidden="true">
                                                        <div className="modal-dialog modal-sm modal-dialog-centered" style={{width:'300px'}} role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title">კატეგორიის წაშლა</h5>
                                                                    <button onClick={() => (setSelectedCatId(-1))} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body default-font">
                                                                    დარწმუნებული ხართ რომ გსურთ "{category.name}" კატეგორიის წაშლა?
                                                                </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="dismiss-btn-modal default-font" data-dismiss="modal" onClick={() => setSelectedCatId(-1)}>გაუქმება</button>
                                                                        <button type="button" className="delete-btn-modal default-font" data-dismiss="modal" onClick={() => removeCategoryMobile(category.id)}>წაშლა</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ''
                                                    }
                                                </>                                                  
                                                )) : ''
                                            } 
                                        </div>
                                    </div>
                                </div>
                                
                                {selectSPJobCategoriesModalMobile ?
                                    <div className="modal fade" id="jobcatmodal" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-scrollable">
                                            <div className="modal-content">
                                                <div className="cat-modal-header">
                                                    <h5 className="modal-title" id="jobcatmodalLabel">აირჩიე კატეგორია</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setSelectSPJobCategoriesModalMobile(false)}>
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <img className="ic-search" alt="search categories" src="./resources/icons/search-icon.png" width="16px" height="16px" />
                                                            <div className="input-group mb-2">
                                                                <input type="text" className="form-control search-form" id="cat-search" placeholder="საძიებო სიტყვა..." />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {cmsCategoriesMobile ? cmsCategoriesMobile.map((value, index) => (
                                                        <div className="row cat-container">
                                                            <div className="cat-checkbox" >
                                                                <label htmlFor={'input' + value.id} id={'label' + value.id}>
                                                                    <input type="checkbox" id={'input' + value.id} defaultChecked={isPartOfSpCatsMobile(value.id)} onChange={e => addOrRemoveCategoryMobile(value.id, e)} />
                                                                    <span className="cr">{value.name}<i className="bi bi-check" id="checkId" /></span>
                                                                </label>
                                                            </div>
                                                        </div>)) : ''
                                                    }
                                                </div>
                                                <div className="cat-modal-footer">
                                                    <button type="button" className="btn btn-primary btn-block cat-submit" data-dismiss="modal" onClick={() => setSelectSPJobCategoriesModalMobile(false)}>დამატება</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-building edit-details-icon" />
                                        <span className="edit-details-label">კომპანიის დასახელება</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="text" defaultValue={profile.CompanyName} {...register("CompanyName", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-geo-alt edit-details-icon" />
                                        <span className="edit-details-label">კომპანიის მისამართი</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="text" defaultValue={profile.LtdAddress} {...register("LtdAddress", {
                                                required: "Required",
                                            })} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <img src="./resources/icons/pencil.png" width="17px" height="17px" className="edit-details-icon" />
                                        <span className="edit-details-label">მოგვიყევით თქვენი ბიზნესის შესახებ</span>
                                        <textarea className="form-control details-textarea" aria-label="With textarea" rows={4} defaultValue={profile.Description} {...register("Description", {
                                            required: "Required",
                                        })} />
                                        <p className="max-symbols details-symbol-limit">0 / 200 სიმბოლო</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="edit-details-label">სამუშაო ადგილი</span>
                                        <div className="input-group mb-2">
                                            <select className="custom-select details-form">
                                                <option value={1}>თბილისი</option>
                                                <option value={2}>ბათუმი</option>
                                                <option value={3}>ქუთაისი</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <i className="bi bi-credit-card-2-front edit-details-icon" />
                                        <span className="edit-details-label">პირადი ნომერი (11 სიმბოლო)</span>
                                        <div className="input-group mb-2">
                                            <input className="form-control details-form" type="text" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="edit-details-label">პირადობის ატვირთვა (წინა მხარე)</span> <i className="bi bi-cloud-arrow-up edit-details-icon upload-id-icon" />
                                        <div className="input-group mb-2">
                                            <div className="form-control upload-details-form">
                                                <img className="id-card-edit" src="../images/id-card.jpg" />
                                                <input type="file" multiple className="upload-form" id="inputGroupFile02" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary btn-block cat-submit" data-dismiss="modal">განახლება</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div> : ''
        }
    </>
    )
}

export default MyDetails;