import "./job-post.scss";
import "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/src/stylesheets/datepicker.scss";
import TermsAndConditionsText from "./terms-and-conditions-text"

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import { Controller, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStateMachine } from "little-state-machine";

import updateAction from "./updateAction";
import LoginForm from "../login";
import SignUpHeader from "../customer-registration/sign-up-header";

import { API_BASE_URL, TYPES } from "../../constants/apiConstants";
import cities from "./cities.json";
import { getRegistrationFields, getAddressList } from "../../services/content";
import CustomerRegistration from "../customer-registration/customer-registration";

import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import AfterJobPostRegistration from "../customer-registration/after-job-post-registration";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const JobPost = (props) => {

  registerLocale("ka", ka);
  const [index, setIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const { actions, state } = useStateMachine({ updateAction });
  const {
    register,
    control,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();
  const ref = useRef(null);
  const [cookies, setCookie] = useCookies(["ContactiAccessToken"]);
  const username = Cookies.get("ContactiUsername");

  const userExistUrl = API_BASE_URL + "/api/user/exist";

  const [termsAndCondModalVisible, SetTermsAndCondModalVisible] = useState(false);

  const [categories, setCategories] = useState([]);
  const [contentLoad, setContentLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [file, setFile] = useState("");
  const [base64, setBase64] = useState("");
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [isFirstOption, setIsFirstOption] = useState(false);
  const isLoggedIn = cookies.ContactiAccessToken !== undefined;
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const [files, setFiles] = React.useState([]);
  const [imageNumber, setImageNumber] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [livingAddress, setLivingAddress] = useState("");
  const [custEmaiExists, setCustEmaiExists] = useState(false);
  // const [custNotEmaiExists, setCustNotEmaiExists] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const clickedCat = localStorage.getItem("clickedCat");
  const clickedCatId = localStorage.getItem("clickedCatId");
  // const [clickedCat, setClickedCat] = useState("");
  const regObj = localStorage.getItem("reg_labels");
  const regLabels = JSON.parse(regObj);

  const tester = regLabels;


   // validation components 

   
// console.log(clickedCat)
   const [category, setCategory] = useState(clickedCatId);
   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");
   const [budget, setBudget] = useState("");
   const [cityName, setCityName] = useState("");
   const [street, setStreet] = useState("");
   const [startingDate, setStartingDate] = useState(null);

 
   const onCategoryChange = ({ target }) => {
    setSelectedOption(target.value);
    setCategory(target.value);
    localStorage.removeItem("clickedCatId");    
    
   }
 
   const onTitleChange = ({ target }) => {
     setTitle(target.value)
   }
 
   const onDescriptionChange = ({ target }) => {    
     setDescription(target.value)
   }
 
   const onBudgetChange = ({ target }) => {    
     setBudget(target.value)
   }
 
   const onCityChange = (data) => {    
     setCityName(data ? data.value : "")
   }
 
   const onStreetChange = (data) => {    
     setStreet(data.value)
   }

   const onStartingDateChange = (e) => {    
    // alert(target.value)
    setStartingDate(e)
    
  }
 
  // validation components 



  //======================================Alerts Start=============================================//

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //======================================Alerts End=============================================//

  const getLivingAddress = (e, livingAddress) => {
    // console.log(e.target.value);
    // console.log(livingAddress);
    if (livingAddress && livingAddress.name === "Tbilisi") {
      setIsFirstOption(true);
    } else {
      setIsFirstOption(false);
    }
    setLivingAddress(livingAddress ? livingAddress.value : "");
  };

  useEffect(() => {
    const res = async () => {
      if (!contentLoad) {
        const result = await axios.get(
          API_BASE_URL + "/api/metadata/ka/jobcategories"
        );
        const data = result.data;
        if (data) {
          //  console.log('gets in');
          // localStorage.clear();
          localStorage.setItem("categories", JSON.stringify(data));
          setCategories((categories) => [...categories, data]);

          setContentLoaded(true);
        }
      }
    };
    res();
  }, []);

  const authAxios = axios.create({
    headers: {
      Authorization: `Bearer ${cookies.ContactiAccessToken}`,
    },
  });

  const selectFiles = (e) => {
    e.preventDefault();
    setImageNumber(e.target.files.length);
    setFiles([...files, ...e.target.files]);
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          data: reader.result.replace(/^data:image\/[a-z]+;base64,/, ""),
          name: file.name,
        });
      reader.onerror = (error) => reject(error);
    });
  };

  const createImagesUploadRequest = async () => {
    const filePathsPromises = [];
    files.forEach((file) => {
      filePathsPromises.push(toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);

    const mappedFiles = filePaths.map((bf) => ({
      filename: bf.name,
      description: bf.name,
      image: bf.data,
    }));
    return mappedFiles;
  };

  const userExists = useCallback(async (userData) => {
    try {
      setUserEmail(userData.email);
      setMobileNumber(userData.mobileNumber);

      const result = await authAxios.post(`${userExistUrl}`, {
        email: userData.email,
      });
      console.log("email exists`", result.data);
      const emailExists = result.data;
      emailExists ? setCustEmaiExists(true) : setCustEmaiExists(false);

      if (isLoggedIn) {
        var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
        localStorage.setItem("jobImgData", strImage);
        window.localStorage.setItem("savedPostJob", JSON.stringify(userData));

        setTimeout(function () {
          redirectToPostSuccess();
        }, 1000);
      } else {
        if (emailExists) {
          var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
          localStorage.setItem("jobImgData", strImage);
          window.localStorage.setItem("savedPostJob", JSON.stringify(userData));
          // props.showError("We have successfully retrieved your account , you'll be redirected to login");

          setShowLogin(true);

          if (!isLoggedIn) {
            setTimeout(function () {
              ref.current.next();
            }, 1000);
          }
          setOpen(true);
        } else {
          // props.showError("We couldn't find your account on our system, you'll be redirected to register");

          var strImage = base64.replace(/^data:image\/[a-z]+;base64,/, "");
          localStorage.setItem("jobImgData", strImage);
          window.localStorage.setItem("savedPostJob", JSON.stringify(userData));

          setIndex(3);

          // if (!isLoggedIn) {
          //   setTimeout(function () {
          //     ref.current.next();
          //   }, 4000);
          // }
          setShowRegistration(true);
        }
      }
    } catch (err) {
      console.log("Request faied", err);
    }
  });

  const onSubmit = async (data) => {
    
    const uploadRequest = await createImagesUploadRequest();
    console.log(uploadRequest);
    data.images = uploadRequest;
    data.jobPhoto = "";
    data.city = cityName ? cityName : ""; // manually adding dynamic data for selected city due to useForm blocking selected city in dropdown menu for Autocomplete

    if(!(data.startDate)){      // checking if date is not selected in Datepicker 
      data.startDate = "2001-01-01T00:00:00Z";
    }

    if(clickedCatId){
      data.jobCategoryId = clickedCatId;   /// temporary fix for clicked category to be automatically selected when selecting catogory from home page
    }
  
    userExists(data);
  };


  const onSubmitReg = () => {
 
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const photoUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setFile(file);
        setName(file.name);
        setBase64(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // console.log( contentLoad )
  // console.log(categories)

  const onPrevClick = (e) => {
    e.preventDefault();
    ref.current.prev();
  };


  const onNextClick = (e) => {
    e.preventDefault();   


    const firstPageValidation = category && title && description && index === 0;
    const secondPageValidation = budget && startingDate && cityName && index === 1;
    const thirdPageValidation = street && index === 2;


    if (firstPageValidation || secondPageValidation || thirdPageValidation) {
      ref.current.next();
    }
  };

  //======================================Redirects=============================================//
  const redirectToLogin = () => {
    window.location.href = "/login";
    props.updateTitle("Login");
  };

  const redirectToReg = () => {
    window.location.href = "/registration";
    props.updateTitle("registration");
  };

  const redirectToPostSuccess = () => {
    window.location.href = "/job-posted";
    props.updateTitle("Job POsted");
  };
  //======================================Redirects End=============================================//
 
  return (
    <div>
      <div className="register">
        <div className="container-fluid">
          <div className="row post-job-wrapper">
            {/* <div className="col-lg-4 post-job-left-col">
              <h1><p className="post-job-title">Post a job</p></h1>
              <div className="post-job-text">
                <p>If you want post a job, then</p>
                <p>please, fill the fields</p>
                <p>and wait for a success message.</p>
                <p>All of the fields are mandatory</p>
                <div className="white-space" />
                <p>If you have questions about</p>
                <p>job posting please,</p>
                <p><a href="#" className="post-job">Contact us</a></p>
              </div>
            </div> */}

            <form onSubmit={handleSubmit(onSubmit)} className="col-lg-4 col-12">
              <div
                id="carouselExampleIndicators"
                className={`carousel slide post-job-carousel ${
                  errors ? "disable-indicators" : ""
                }`}
              >
                <div className="row">
                  <div className="col-9">
                    <p>მომს. მოთხოვნის პროგრესი</p>
                  </div>
                  {index == 0 ? (
                    <div className="col-3">
                      <p id="percentage">25%</p>
                    </div>
                  ) : (
                    ""
                  )}

                  {index == 1 ? (
                    <div className="col-3">
                      <p id="percentage-1">50%</p>
                    </div>
                  ) : (
                    ""
                  )}

                  {index == 2 ? (
                    <div className="col-3">
                      <p id="percentage-2">75%</p>
                    </div>
                  ) : (
                    ""
                  )}

                  {index == 3 ? (
                    <div className="col-3">
                      <p id="percentage-3">100%</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {!custEmaiExists ? (
                  <>
                  <div className="progress">
                    {index == 0 ? (
                      <div
                        className="progress-bar"
                        id="progressbar"
                        role="progressbar"
                        aria-valuenow={55}
                        aria-valuemin={0}
                        aria-valuemax={80}
                      />
                    ) : (
                      ""
                    )}
                    {index == 1 ? (
                      <div
                        className="progress-bar"
                        id="progressbar-1"
                        role="progressbar"
                        aria-valuenow={55}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    ) : (
                      ""
                    )}
                    {index == 2 ? (
                      <div
                        className="progress-bar"
                        id="progressbar-2"
                        role="progressbar"
                        aria-valuenow={55}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    ) : (
                      ""
                    )}
                    {index == 3 ? (
                      <div
                        className="progress-bar"
                        id="progressbar-3"
                        role="progressbar"
                        aria-valuenow={55}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="warningasterisk text-primary">(<span className="text-danger">*</span>) სიმბოლოთი აღნიშნული ველების შევსება სავალდებულოა!</div>
                  </>
                ) : (
                  ""
                )}
                <Carousel
                  ref={ref}
                  activeIndex={index}
                  onSelect={handleSelect}
                  interval={null}
                  className="post-job-carousel-inner"
                  touch={false}
                >
                  <Carousel.Item>
                    <div id="catasterisk" className="form-group first-step pt-2 pl-1">
                      <div className="asteriskpos">*</div>
                      <select
                        {...register("jobCategoryId", {
                          // required: "Required",
                        })}
                        className="form-control"
                        value={clickedCatId ? clickedCatId : selectedOption}
                      //  value={selectedOption}
                        onChange={onCategoryChange}
                      >
                        <option value="">აირჩიე კატეგორია</option>    

                        {contentLoad
                          ? categories &&
                            categories.length > 0 &&
                            categories[0].map(({ name, id }, index) => (
                              <option value={id}>{name}</option>
                            ))
                          : ""}
                      </select>
                      
                    </div>
                    <div id="titleasterisk" className="post-job-form pt-2 pl-1">
                      <div className="titleasterisk">*</div>
                      <input
                        className={"form-control"}
                        // className={`form-control form-control-lg ${
                        //   errors.jobTitle ? "error" : ""
                        // }`}
                        type="text"
                        placeholder={"სათაური"}
                        {...register("jobTitle", {
                          required: "Required",
                        })}
                        
                        onChange={onTitleChange}
                      />
                    </div>
                    <div id="descasterisk" className="input-group post-job-form pl-1 pt-2">
                      <div className="descasterisk">*</div>                      
                      <textarea
                      className="form-control"
                        // className={`form-control ${
                        //   errors.jobDescription ? "error" : ""
                        // }`}
                        aria-label="With textarea"
                        rows={5}
                        placeholder="აღწერეთ მომსახურება"
                        defaultValue={""}
                        {...register("jobDescription", {
                          required: "Required",
                        })}
                        onChange={onDescriptionChange}
                      />
                      <img
                        src="../resources/icons/pencil.png"
                        width="17px"
                        height="17px"
                        className="pencil-icon"
                      />
                      <p className="max-symbols">0 / 200 სიმბოლო</p>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="second-step">
                      {/* <div className="error-text-style text-danger text-left">{errorMessage}</div> */}
                      <div id="budgcasterisk" className="form-group pl-1 pt-2">
                        <div className="budgcasterisk">*</div>
                        <select
                          id="inputState"
                          className="form-control"
                          {...register("budgetRange", {
                            required: true,
                          })}
                          onChange={onBudgetChange}
                        >
                          <option value="" selected>ბიუჯეტი</option>
                          <option>0 - 50₾</option>
                          <option>50 - 100₾</option>
                          <option>100 - 200₾</option>
                          <option>200 - 300₾</option>
                          <option>300 - 400₾</option>
                          <option>400 - 500₾</option>
                          <option>500 - 600₾</option>
                          <option>600 - 700₾</option>
                          <option>700 - 800₾</option>
                          <option>800 - 900₾</option>
                          <option>900 - 1000₾</option>
                          <option>1000+₾</option>
                        </select>
                      </div>
                      <div id="startdataasetrisk" className="form-group pl-1 pt-2">
                        <div className="startdataasetrisk">*</div>
                        
                        {/* <span className="label label-default">Start Date</span> */}
                        {/* <input className={`form-control ${errors.startDate ? 'error' : ''}`} id="startDate" type="datetime-local" placeholder="Start Date" onfocus="(this.type = 'date')" name="startDate"
                          {...register("startDate", {

                            required: "Required",
                          })} /> */}

                        {/* <DatePicker
                           className={`form-control ${errors.startDate ? 'error' : ''}`}
                           showTimeSelect
                           selected={startDate}
                           onChange={(date) => setStartDate(date)}
                        /> */}

                        <Controller
                          onChange
                          control={control}
                          name="startDate"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePicker  
                              className={"form-control"}                       
                              onChange={(e, data) => {setStartingDate(e); onChange(e, data)}} 
                              onBlur={onBlur}
                              selected={value}
                              placeholderText="დაწყების თარიღი"
                              locale="ka"
                              value={startingDate}
                            />
                          )}
                        />
                      </div>
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          {/* <input type="file" className={`custom-file-input ${errors.jobPhoto ? 'error' : ''}`} name="jobPhoto" id="jobPhoto" accept=".jpef, .png, .jpg" onInput={photoUpload}
                            {...register("jobPhoto", {
                              required: "Required"
                            })} /> */}
                          <input
                            type="file"
                            multiple
                            className="custom-file-input"
                            name="jobPhoto"
                            id="jobPhoto"
                            accept=".jpef, .png, .jpg"
                            onInput={selectFiles}
                          />
                          <label
                            className="post-job-file-label"
                            htmlFor="jobPhoto"
                          >
                            ატვირთე ფოტო ({imageNumber})
                            <i className="bi bi-cloud-arrow-up post-job-file-label-icon" />
                          </label>
                        </div>
                        {/* <span className="image-count">{imageNumber}</span> */}
                      </div>
                      <div id="citycasterisk" className="form-group pl-1 pt-2">
                        <div className="citycasterisk">*</div>
                        <Controller
                          render={({ ...props }) => (
                            <Autocomplete
                              options={cities}
                              getOptionLabel={(option) => option.value}
                              renderOption={(option) => (
                                <span value={option.value}>{option.value}</span>
                              )}
                              onChange={(e, data) => {getLivingAddress(e, data); onCityChange(data)}}
                              
                              // {...register("cityName", {
                              //   required: true,
                              // })}
                              
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={"აირჩიე ქალაქი"}
                                  variant="outlined"
                                />
                              )}
                              
                              {...props}
                            />
                          )}
                          onChange={([, data]) => data}
                          value={(option) => option.name}
                          defaultValue={""}
                          name="city"
                          control={control}
                        />
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    {/* <div className="error-text-style text-danger text-left">{errorMessage}</div> */}
                    <div className="form-group">
                      {isFirstOption ? (
                        <select
                          id="inputState"
                          className="form-control"
                          {...register("district", {
                            required: "Required",
                          })}
                        >
                          <option>ვაკე</option>
                          <option>საბურთალო</option>
                          <option>მთაწმინდა</option>
                          <option>ძველი თბილისი</option>
                          <option>კრწანისი</option>
                          <option>ჩუღურეთი</option>
                          <option>დიდუბე</option>
                          <option>ნაძალადევი</option>
                          <option>გლდანი</option>
                          <option>ისანი</option>
                          <option>სამგორი</option>
                          <option>დიდი დიღომი</option>
                          <option>ლილო</option>
                          <option>აეროპორტის დასახლება </option>
                          <option>ავჭალა</option>
                          <option>ფონიჭალა</option>
                          <option>წავკისი </option>
                          <option>წყნეთი</option>
                          <option>ტაბახმელა</option>
                          <option>კოჯორი</option>
                          <option>კაკლები</option>
                        </select>
                      ) : (
                        <select
                          id="inputState"
                          className={`form-control ${
                            isFirstOption ? "" : "hidden"
                          }`}
                          {...register("district")}
                        >
                          <option></option>
                        </select>
                      )}
                    </div>
                    <div id="streetasterisk" className="post-job-form pl-1 pt-2">
                      <div className="streetasterisk">*</div>
                      <input
                        className="form-control form-control-lg"
                        type="text"
                        placeholder="ქუჩა"
                        {...register("street", {
                          required: "Required",
                        })}
                        onChange={onStreetChange}
                      />
                    </div>
                    {!username ? (
                      <div style={{ display: "flex" }}>
                        
                        <div
                          id="nameasterisk"
                          className="post-job-form pl-1 pt-2"
                          style={{ width: "50%", paddingRight: "7px" }}
                        >
                          <div className="nameasterisk">*</div>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="თქვენი სახელი "
                            {...register("fullName", {
                              required: "Required",
                            })}
                          />
                          <i className="bi bi-person-circle post-job-input-label-icon" />
                        </div>

                        <div
                        id="surnameasterisk"
                          className="post-job-form pl-1 pt-2"
                          style={{ width: "50%", paddingLeft: "7px" }}
                        >
                          <div className="surnameasterisk">*</div>
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="თქვენი გვარი "
                            {...register("LastName", {
                              required: "Required",
                            })}
                          />
                          <i className="bi bi-person-circle post-job-input-label-icon" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}            

                    {!username ? (
                      <div>
                        <div id="emailasterisk" className="post-job-form  pl-1 pt-2">
                          <div className="emailasterisk">*</div>  
                          <input
                            className="form-control form-control-lg"
                            type="text"
                            placeholder="ელ-ფოსტა"
                            {...register("email", {
                              required: "Required",
                            })}
                          />
                          <i className="bi bi-envelope post-job-input-label-icon" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {!username ? (
                      <>
                        <div className='text-primary tandclink__jobpost' style={{display:'flex', justifyContent:'end', gap:'5px', marginTop:'30px', fontSize:'12px'}}>
                          <div onClick={() => SetTermsAndCondModalVisible(prev => !prev)}>
                            ვეთანხმები პლატფორმის წესებსა და პირობებს
                          </div>
                          <input 
                            type="checkbox" 
                            {...register("tandccheckbox", {
                              required: {
                                value: true,
                                message: "თქვენ უნდა დაეთანხმოთ პლატფორმის წესებსა და პირობებს!",
                              },
                            })} 
                          />
                        </div>
                        <p style={{display:'flex', justifyContent:'end', fontSize:'11px', color:'red'}}>{errors.tandccheckbox?.message}</p>
                      </>
                    ) : (
                      ""
                    )}
                    
                    {termsAndCondModalVisible && 
                      (
                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">წესები და პირობები</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body text-justify">
                                <TermsAndConditionsText />
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => {SetTermsAndCondModalVisible(prev => !prev)}}>დახურვა</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {SetTermsAndCondModalVisible(false); setValue("tandccheckbox", true); clearErrors(["tandccheckbox"])}}>ვეთანხმები</button>
                                
                                {/* <div>
                                  <div onClick={() => SetTermsAndCondModalVisible(prev => !prev)}>
                                    ვეთანხმები
                                  </div>
                                  <input 
                                    type="checkbox" 
                                    {...register("tandccheckbox", {
                                      required: "Required",
                                    })} 
                                    onClick={() => {SetTermsAndCondModalVisible(false); setCheckboxStatus(true)}}
                                  />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )

                    }
                  </Carousel.Item>
                  {showLogin ? (
                    <Carousel.Item className="login-item">
                      <LoginForm username={userEmail} />
                    </Carousel.Item>
                  ) : (
                    ""
                  )}
                  {/* ///////////////////////////////// showRegistration */}
                  {showRegistration ? (
                    <Carousel.Item>
                      <AfterJobPostRegistration />
                    </Carousel.Item>
                  ) : (
                    ""
                  )}
                </Carousel>
                {/* Modal */}
                {/* <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">Terms &amp; Conditions</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="read-terms-text">
                            It is a long established fact that a reader will be distracted by the readable content of a page
                            when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using ‘Content here, content here’, making it look like
                            readable English. Many desktop publishing packages and web page editors now use Lorem
                            psum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites
                            still in their infancy.
                            It is a long established fact that a reader will be distracted by the readable content of a page
                            when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                            distribution of letters, as opposed to using ‘Content here, content here’, making it look like
                            readable English. Many desktop publishing packages and web page editors now use Lorem
                            psum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites
                            still in their infancy.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-primary" data-dismiss="modal">Ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                   */}
                {/* <div className="white-space" /> */}
                {!showRegistration ? (
                  <div className="row">
                    <div className="col-12">
                      {index == 2 ? (
                        <>
                          <button
                            id="post-job-sbmt-btn-lg"
                            type="submit"
                            className="btn btn-primary btn-block post-job-sbmt-btn fcaps"
                          >
                            დასრულება
                          </button>
                          <button
                          // type="submit"
                            className={`btn btn-primary fcaps btn-block post-job-btn-lg ${
                              showLogin ? "hidden" : ""
                            }`}
                            id="next"
                            onClick={onPrevClick}
                          >
                            წინა გვერდი
                          </button>
                        </>
                      ) : index == 0 ? (
                        <>
                          
                          <button
                          // type="submit"
                            className={`btn btn-primary fcaps btn-block post-job-btn-lg ${
                              showLogin ? "hidden" : ""
                            }`}
                            id="next"
                            onClick={onNextClick}
                          >
                            შემდეგი გვერდი
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            // type="submit"
                            className={`btn btn-primary fcaps btn-block post-job-btn-lg ${
                              showLogin ? "hidden" : ""
                            }`}
                            id="next"
                            onClick={onNextClick}
                          >
                            შემდეგი გვერდი
                          </button>
                          <button
                            // type="submit"
                            className={`btn btn-primary fcaps btn-block post-job-btn-lg ${
                              showLogin ? "hidden" : ""
                            }`}
                            id="next"
                            onClick={onPrevClick}
                          >
                            წინა გვერდი
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            We have successfully retrieved your account
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default withRouter(JobPost);
