import React, { useState, useReducer } from 'react';
import faqdatasp from './faqdatasp';
import faqdatacustomer from './faqdatacustomer';
import './faq.scss'

const FaqCard = () => { 
  const [showAnswer, setShowAnswer] = useState({}); 
  const [dataArray, setDataArray] = useState(faqdatasp);
  const [faqTab, setFaqTab] = useState(0);

  const faqTabs = ['ხელოსნებისთვის', 'მომხმარებლებისთვის'];

  console.log(showAnswer);

  const toggleAnswer = id => {
      setShowAnswer(prevShownAnswer => ({
      ...prevShownAnswer,
      [id]: !prevShownAnswer[id]
      }));
  };

  return (
    <>
      
      <div className='faq__container'>
        <div className='faq__tabs'>
          <ul>
            {faqTabs ? faqTabs.map((item, index) => (
              <li key={index} className={faqTab === index ? 'faq__tab' : ''} onClick={() => {setDataArray(item === 'ხელოსნებისთვის' ? faqdatasp : faqdatacustomer); setFaqTab(index); setShowAnswer({})}}>{item}</li>
            )) : ''}          
          </ul>
        </div>
          {dataArray.map((item, index) => (
            <div className='faq__content' key={item._id}>
                <div className='faq__question' onClick={() => toggleAnswer(item._id)}>
                  <div>{item.question}</div>
                  <i className={showAnswer[item._id] ? 'bi bi-caret-right rotate90' : 'bi bi-caret-right rotateneg90'} ></i>
                </div> 
                <div className='faq__answer'>          
                  {showAnswer[item._id] ? <><div class="line__before"></div><p style={{whiteSpace:'pre-line'}}>{item.answer}</p></> : null}
                </div> 
            </div>
          ))}
        <div className='faq__message' style={{color:"#007bff", fontWeight:'bold', fontSize:'14px'}}>ტექნიკური საკითხებისავის გთხოვთ მიმართოთ დახმარების გუნდს!</div>
      </div>
      
    </>
  )
}

export default FaqCard