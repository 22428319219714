import './session-reminder.scss';

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';

import { API_BASE_URL } from '../../constants/apiConstants';
import Modal from '../modal/modal';
import ModalBody from '../modal/modalBody';
import ModalHeader from '../modal/modalHeader';

const SessionReminder = () => {

    const contactiAccessToken = Cookies.get('ContactiAccessToken');
    const [show, setShow] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const extendUrl = `${API_BASE_URL}/api/access/extendsession`;

    const authAxios = axios.create({
        withCredentials: false,
        baseURL: extendUrl,
        headers: {
            Authorization: `Bearer ${contactiAccessToken}`
        }
    });

    const fetchData = async () => {
        try {
            const result = await authAxios.post(`${extendUrl}`);
            setOpenModal(false);
            window.location.href = "/";
        } catch (err) {
            console.log("Request faied", err);
        }
    };
    const logout = () => {
        Cookies.remove('ContactiAccessToken', { path: '/' });
        Cookies.remove('ContactiUsername', { path: '/' });
        window.location.href = "/";
    };

    return (
        <>
            {openModal ?
                <Modal>
                    <ModalHeader>
                        <h3>Session Reminder</h3>
                    </ModalHeader>
                    <ModalBody>
                        <div className="session-reminder-text">
                            Your session is about to expire
                        </div>
                        <div className="action-btns">
                            <button type="button" className="btn btn-danger" onClick={logout}>Logout</button>
                            <button type="button" className="btn btn-primary" onClick={fetchData}>Continue</button>
                        </div>
                    </ModalBody>
                </Modal> : ''
            }
        </>
    )
}

export default SessionReminder;

