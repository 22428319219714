import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";

const Notifications = () => {



    return (<>
    
    <div className="tabbed-card mt-3">
                <div className="notifications-container">
                  <p className="notification-date">Today, 2 Jan 2021</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
                <div className="notifications-container">
                  <p className="notification-date">Yesterday, 1 Jan 2021</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
                <div className="notifications-container">
                  <p className="notification-date">31 Dec 2020</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
              </div>
              <div className="feedback-pagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Previous">
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">წინა</span>
                      </a>
                    </li>
                    <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Next">
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">შემდეგი</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
    
    </>)

}

export default withRouter(Notifications);