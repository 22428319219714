import axios from "axios";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { API_BASE_URL } from "../../../constants/apiConstants";

var InterestedSPButton = (props) => {
  const [interestedSps, setinterestedSps] = useState([]);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { job } = props;

    const updateUrl = `${API_BASE_URL}/api/cs/jobs/get/allstates/${job.id}`;

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await authAxios.post(`${updateUrl}`);
        setinterestedSps(result.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log("Request faied", err);
      }
    };

    fetchData();
  }, [cookies.ContactiAccessToken]);

  const RenderButton = () => {
    if (props.title !== "") {
      return (
        <p
          onClick={() => props.openList(props.job, interestedSps)}
          className="jobs-title"
        >
          {props.title}
        </p>
      );
    }
    return (
      <div className="interested-sp-btn-container">
        <button
          disabled={!interestedSps.length}
          type="button"
          className="btn btn-primary interested-sp-btn"
          onClick={() => props.openList(props.job, interestedSps)}
        >
          {!isLoading
            ? `დაინტერესებული სერვისის მომწოდებლების ნახვა (${interestedSps.length})`
            : "მიმდინარეობს სერვისის მომწოდებლის ჩატვირთვა...."}
        </button>
      </div>
    );
  };

  return <>{RenderButton()}</>;
};

export default InterestedSPButton;
