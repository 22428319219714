import "./reset-confirm-password.scss";
import React, { useState } from "react";
import queryString from "query-string";
import { API_BASE_URL } from "../../constants/apiConstants";
import axios from "axios";
import { isLength, isMatch } from "../reset-password/validation";
import { showErrMsg, showSuccessMsg } from "../reset-password/notification";

const initialState = {
  password: "",
  confirmPassword: "",
  err: "",
  success: "",
};

function ResetConfirmPassword() {
  const queryParameters = queryString.parse(window.location.search);
  const token = queryParameters.token;
  const email = queryParameters.email;
  // alert(window.location.search);
  // console.log(myQuerry.email);

  const [data, setData] = useState(initialState);

  const { password, confirmPassword, err, success } = data;

  const handleOnChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setData({ ...data, [name]: value, err: "", success: "" });
  };

  const HandleResetPassword = async (e) => {
    e.preventDefault();
    if (isLength(password)) {
      return setData({
        ...data,
        err: "პაროლი უნდა შედგებოდეს სულ მცირე 8 სიმბოლოსგან",
        success: "",
      });
    }

    if (!isMatch(password, confirmPassword)) {
      return setData({
        ...data,
        err: "შეყვანილი პაროლები ერთმანეთს არ ემთხვევა",
        success: "",
      });
    }

    const payload = {
      Email: email,
      Token: token,
      Password: password,
    };

    try {
      // console.log(payload);
      const response = await axios
        .post(`${API_BASE_URL}api/access/reset-password-confirm`, payload)
        .then((apiResponse) => {
          if (apiResponse.status === 200)
            setData({
              ...data,
              err: "",
              success: "პაროლი წარმატებით შეიცვალა",
            });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="rst_cfrm_pass">
      {/* {console.log(useParams())} */}
      <h2>პაროლის შეცვლა</h2>
      <div className="rst_pass_cfrm_components">
        {err && showErrMsg(err)}
        {success && showSuccessMsg(success)}
        <label htmlFor="password">შეიყვანეთ ახალი პაროლი</label>
        <input
          type="password"
          name="password"
          id="password"
          value={password}
          onChange={handleOnChange}
        />
        {/* {console.log(password)} */}
        <label htmlFor="confirmPassword">დაადასტურეთ პაროლი</label>
        <input
          type="password"
          name="confirmPassword"
          id="confirmPasswordd"
          value={confirmPassword}
          onChange={handleOnChange}
        />
        <button onClick={HandleResetPassword}>პაროლის შეცვლა</button>
      </div>
    </div>
  );
}

export default ResetConfirmPassword;
