import './customer-profile.scss';

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { useForm } from 'react-hook-form';
import ReactPaginate from 'react-paginate';
import { Link, useHistory } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import { API_BASE_URL } from '../../../constants/apiConstants';
import InterestedSPButton from './customer-interested-sp-btn';
import { reverse } from 'lodash';

var CustomerProfile = () => {
  const { register, handleSubmit } = useForm();
  const [activeSection, setActiveSection] = useState('all');
  const [feedbackSPId, setFeedbackSPId] = useState(-1);
  const [feedbackJobId, setFeedbackJobId] = useState(-1);
  const [feedbackRating, setFeedbackRating] = useState('');
  const [feedbackDescription, setFeedbackDescription] = useState('');
  const [feedBackModalSuccess, setFeedBackModalSuccess] = useState(false);
  const [profile, setProfile] = useState([]);
  const [showEditDetails, setShowEditDetails] = React.useState(false);
  const [pName, setPName] = useState("");
  const [pLastName, setPLastName] = useState("");

  const [mustUpdateProfile, setMustUpdateProfile] = useState(0)
  const [getSpDetails, setGetSpDetails] = useState(0)
  const [jobs, setJobs] = React.useState([]);
  const [spSelectJobInterestedJobsModal, setSPSelectJobInterestedJobsModal] = React.useState(false);
  const [mustUpdateInterestedJobs, setMustUpdateInterestedJobs] = useState(0)
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [isLoading, setIsLoading] = useState(false);
  const contactiCookie = cookies.ContactiAccessToken;
  const history = useHistory();

  

  const [selectedJobJobPostId, setSelectedJobJobPostId] = useState({})
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [hiredSpDetails, setHiredSpDetails] = useState([]);
  const [profileImage, setProfileImage] = useState("");

  //pagination
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState([]);
  const [perPage] = useState(4);
  const itemsPerPage = 4;

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  function feedback_pos() {
    document.getElementById("feedback-pos").style.opacity = "1";
    document.getElementById("feedback-neu").style.opacity = ".4";
    document.getElementById("feedback-neg").style.opacity = ".4";
  }
  function feedback_neu() {
    document.getElementById("feedback-pos").style.opacity = ".4";
    document.getElementById("feedback-neu").style.opacity = "1";
    document.getElementById("feedback-neg").style.opacity = ".4";
  }

  function feedback_neg() {
    document.getElementById("feedback-pos").style.opacity = ".4";
    document.getElementById("feedback-neu").style.opacity = ".4";
    document.getElementById("feedback-neg").style.opacity = "1";
  }

  useEffect(() => {
    isMobile ? setActiveSection(''): setActiveSection('all');
  }, []);

  useEffect(() => {

    const updateUrl = `${API_BASE_URL}/api/cs/jobs/get`;

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await authAxios.post(`${updateUrl}`);
        const data = result.data;
        setJobs(reverse(data));

        setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };

    fetchData();

    const apiProfileUrl = API_BASE_URL + "/api/user/profile";

    const authProfileAxios = axios.create({
      withCredentials: false,
      baseURL: apiProfileUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchUserProfileData = async () => {
      try {
        const result = await authProfileAxios.post(`${apiProfileUrl}`);
        const { data = {} } = result;
        setProfile(data);
        setPName(data.FirstName);
        setPLastName(data.LastName)

      } catch (err) {
        console.log("Request faied", err);
      }
    };

    fetchUserProfileData();
  }, [mustUpdateInterestedJobs, mustUpdateProfile, cookies.ContactiAccessToken]);

  useEffect(() => {

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(jobs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(jobs.length / itemsPerPage));

  },[itemOffset,itemsPerPage,jobs]);

  const handleChange = (e) => {
    const { value } = e.target;
    setFeedbackDescription(value);
  };

  const addFeedback = async (e) => {
    e.preventDefault();
    const updateUrl = API_BASE_URL + "/api/cs/jobs/feedback/add";

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    await authAxios.post(`${updateUrl}`, {
      "serviceProviderId": feedbackSPId,
      "jobPostId": feedbackJobId,
      "feedbackRating": feedbackRating,
      "feedbackDescription": feedbackDescription
    });

    setFeedbackSPId(-1);
    setFeedbackJobId(-1);
    setFeedbackRating('');
    setFeedbackDescription('');
    setFeedBackModalSuccess(true);
  };

  const openInterestedServiceProviders = (job, serviceProvidersList) => {
    history.push('/customer/customer-jobs/'+job.id, {
      job,
      serviceProviders: serviceProvidersList,
      jobdetails: false
    });
  };

  const openjobDetails = (job, serviceProvidersList) => {
    history.push('/customer/customer-jobs/'+job.id, {
      job,
      serviceProviders: serviceProvidersList,
      jobdetails: true
    });
  };

  // console.log(openjobDetails)

  const onSubmit = async (data) => {
    const updateUrl = API_BASE_URL + "/api/user/updateprofile";

    const authAxios = axios.create({
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    await authAxios.post(`${updateUrl}`, data);

    profile.FirstName = data.firstName;
    profile.LastName = data.lastName;
    setPName(data.firstName);
    setPLastName(data.lastName);

    setMustUpdateProfile(Math.floor((Math.random() * 100000000000) + 1));
    setShowEditDetails(false);
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % jobs.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
  };

  const redirectToHome = () => {
    Cookies.remove('ContactiAccessToken', { path: '/' });
    Cookies.remove('ContactiUsername', { path: '/' });
    console.log('gets here');
    window.location.href = "/";
    // props.updateTitle("Home");
  };


  useEffect(() => {
    const getAllSps = `${API_BASE_URL}/api/cs/jobs/get/allstates/${selectedJobJobPostId}`;

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: getAllSps,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await authAxios.post(`${getAllSps}`);
        // Filter only hired SP from the fetched list of all SPs (Hired/Interested)
        const allSps = result.data.filter((hiredSp) => {
          return hiredSp.jobState === 'Hired';
        });
        setHiredSpDetails(...allSps);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log("Request faied", err);
      }
    };

    fetchData();

  }, [getSpDetails])



  const handleChangePicture = async (e) => {
    e.preventDefault();
    const apiUrl = API_BASE_URL + "api/cs/avatar/add";
    const authAxios = axios.create({
      withCredentials: false,
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    setProfileImage(URL.createObjectURL(e.target.files[0]));

    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const result = await authAxios.post(`${apiUrl}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (err) {
      console.log("Request faied", err);
    }

    setMustUpdateProfile(Math.floor((Math.random() * 100000000000) + 1));
  };


 //
  const getJobPostId = (index) => {
    const selectedJobPostId = currentItems[index].jobStates[0].jobPostId;
    setSelectedJobJobPostId(selectedJobPostId);    
    setGetSpDetails(Math.floor((Math.random() * 100000000000) + 1));
  }

  return (
    <div className="customer">
      <div className="profile-title-bg">
        <div className="container">
          <p className="profile-title">ჩემი პროფილი</p>
        </div>
      </div>
      <div className="container ">
        <div className="row no-gutters">
          <div className="col-lg-3 col-12">
            <div className={`profile-sidebar-menu ${activeSection && isMobile ? 'hide-on-mobile': ''}`}>
              <div className="sidebar-menu-containers">
                <div className="row">
                  <div className="profile-picture">
                    <img
                      src={
                          profile.AvatarUrl
                          ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                          : "../images/profile-picture.jpg"
                      }
                      className="profile-picture-img"
                      />
                      <input
                      onChange={(e) => handleChangePicture(e)}
                      className="profile-picture-input"
                      type="file"
                      name="profile-picture"
                      id="profile-picture"
                      />                  
                  </div>
                  <div className="col-9 col-md-10 col-lg-8">
                    <div className="row">
                      <p className="profile-name">{pName}&nbsp;{pLastName}</p>
                    </div>
                    <div className="row">
                      <p className="job-posted-time">{`${jobs ? jobs.length : '0'} მოთხოვნილი მომსახურება`}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="sidebar-hr" />
              <div className="sidebar-menu-containers">
                {/* <div className="account-complete-container">
                  <span className="account-completion">Your account</span>
                  <span className="account-completion completed">90%</span>
                </div> */}
                {/* <div className="progress">
                  <div className="progress-bar" id="account-progress" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} />
                </div> */}

                <h4 className="cust-manage-profile">
                  პროფილის მართვა
                </h4>
              </div>
              <hr className="sidebar-hr" />
              <div className="sidebar-menu-containers">
                <div className="row sidebar-menu-items">
                  <a href="#" className={`myJobs-menu-services menu-tablinks ${activeSection === 'all' ? 'active' : ''}`} id="defaultOpen" onClick={() => setActiveSection('all')}>
                    <p className="menu-item">ჩემი საქმეები</p></a>
                </div>
                <div className="row sidebar-menu-items">
                  <a href="#" className={`myJobs-menu-my-account menu-tablinks ${activeSection === 'account' ? 'active' : ''}`} onClick={() => setActiveSection('account')}>
                    <p className="menu-item">ჩემი პროფილი</p></a>
                </div>
                <div className="row sidebar-menu-items">
                  <a href="#" className={`myJobs-menu-notifications menu-tablinks ${activeSection === 'notifications' ? 'active' : ''}`} onClick={() => setActiveSection('notifications')}>
                    <p className="menu-item">შეტყობინებები</p></a>
                </div>
                <div className="row sidebar-menu-items">
                  <a href="/post-a-job" className="myJobs-menu-post-job">
                    <p className="menu-item">მომსახურების მოთხოვნა</p></a>
                </div>
                <div className="row sidebar-menu-items">
                  <a href="#" onClick={()=> redirectToHome()} className="myJobs-menu-logout-menu">
                    <p className="menu-item">გამოსვლა</p></a>
                </div>
              </div>
            </div>
          </div>
          {activeSection === 'all' ?
            <div className={`col-lg-9 profile-tabbed-menu sidebarTabs ${!activeSection && isMobile ? 'hide-on-mobile': ''}`}>
              <div className="tabbed-card mt-3">
                <div className="customer-jobs-container">
                  {isMobile ?
                    <div className="edit-details">
                      <div className="editDetails-title-container">
                        <a onClick={() => setActiveSection('')}>
                          <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                        </a>
                        {/* <p className="edit-details-title">All my jobs</p> */}
                      </div>
                    </div> : ''
                  }
                <div className="items">
                {currentItems && currentItems.map((value, idx) => (
                  <div key={value.id}>
                    <div className="job-inner-customer">
                      <div className="job-inner-container">
                        <div className="row job-top-section">
                          <div className="job-top-section-left">
                           
                            <InterestedSPButton job={value} openList={openjobDetails} title={value.jobTitle} />
           
                            <p className="job-category-customer">{value.jobCategoryName}</p>
                          </div>
                          <div className="job-top-section-right">
                            <p className="job-posted-time"><ReactTimeAgo date={moment(value.postedDate)} locale="ka" /></p> 
                            <p className="jobs-reacted">{value.hiredSpId && value.hiredSpId > 0 ? 'დაქირავებული' : ''}</p>
                          </div>
                        </div>
                        {value.hiredSpId && value.hiredSpId > 0 ?
                          <div className="row hired-job-btn-container">
                            <div className="col-6">
                              <button type="button" className="btn btn-primary btn-lg interest-btn" onClick={() => {setDetailsModalVisible(true); getJobPostId(idx)}}>ხელოსნის პირობები</button>
                              {/* <span className="customer-msg-badge">1</span> */}
                            </div>
                            <div className="col-6">
                              <button type="button" className="btn btn-primary btn-lg accept-btn" data-toggle="modal" data-target="#feedbackModal" onClick={() => { setFeedbackSPId(value.hiredSpId); setFeedbackJobId(value.id); }}>შეფასება</button>
                            </div>
                          </div> :
                          <>
                            <div className="job-bottom-section">
                              <Link className="invite-sp" to={{
                                pathname: '/customer/sp-listing',
                                state: { jobId: value.id }
                              }}>მოიწვიე სხვა სერვისის მომწოდებელი
                              </Link>
                              {/* <p className="shortlist-persons">სიაშია 3 პერსონა</p> */}
                            </div>
                            <InterestedSPButton job={value} openList={openInterestedServiceProviders}  title={""} />
                          </>
                        }
                       
                      </div>
                    </div>
                  </div>
                ))}

              {detailsModalVisible && 
                (<div className="modal fade" tabIndex={-1}>                      
                  <div className="modal-dialog modal-sm chat-modal modal-dialog-scrollable">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="chatModalLabel">პირობების ფორმა</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setDetailsModalVisible(false)}>
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row no-gutters">
                          <div className="jobs-img-container">
                            <img src="../images/gallery/1.png" className="sp-listing-img" style={{width:'50px',height:'50px',borderRadius:'50%'}} />
                          </div>
                          <div className="col jobs-inner">
                          {!isLoading ? (
                            <>
                              <p className="jobs-title">{hiredSpDetails.lastName} {hiredSpDetails.firstName}</p>
                              <p className="job-poster"> {hiredSpDetails.jobCategory}</p>
                            </>
                            ) : (<div>Loading...</div> )}  
                          </div>
                        </div>
                        <p style={{color:'#ccc',  marginTop:'15px', fontSize:'12px'}}>ბოლო განახლდების თარიღი: 12.02.2022</p>
                        <div style={{width:'100%', height:'200px', background:'#f1f1f1', padding:'15px', fontSize:'13px', marginBottom:'20px', borderRadius:'5px'}}>
                          <div style={{marginBottom:'20px'}}>
                            <div>როდის შეძლებთ სამუშაოს დაწყებას?</div>
                            <div style={{fontWeight:'bold'}}>{ moment(hiredSpDetails.canStartWorkDate).format('DD.MM.YYYY')}-ში</div>
                          </div>                                     
                          <div style={{marginBottom:'20px'}}>
                            <div>რა დრო დაგჭირდებათ?</div>
                            <div style={{fontWeight:'bold'}}>{hiredSpDetails.workTimeNumberOfUnit} {hiredSpDetails.workTimeUnit}</div>
                          </div>
                          <div style={{marginBottom:'20px'}}>
                            <div>საორიენტაციო ბიუჯეტი</div>
                            <div style={{fontWeight:'bold'}}>{hiredSpDetails.estimateSalary} </div>                                      
                          </div>                                  
                        </div>
                        {<button type="button" className={`btn btn-primary btn-lg sp-buttons `} onClick={()=>setDetailsModalVisible(false)}>დახურვა</button> }
                      </div>      
                    </div>
                  </div>
                </div>)}
              </div>
                  <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                  {/* <PaginatedItems itemsPerPage={1} items={jobs} /> */}
                  {/*LEAVE FEEBACK MODAL*/}
                  {feedbackSPId !== -1 ?
                    <div className="modal fade" id="feedbackModal" data-backdrop="true" data-keyboard="false" tabIndex={-1} aria-labelledby="feedbackModal" aria-hidden="true">
                      <div className="modal-dialog cat-modal-dialog">
                        <div className="modal-content">
                          <div className="cat-modal-header">
                            <h5 className="modal-title" id="jobcatmodalLabel">შეფასება</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setFeedbackSPId(-1)}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row no-gutters">
                              <div className="col-4" id="feedback-pos">
                                <img src="../../resources/icons/positive-emoji.png" alt="positive rating" className="positive-feedback-img" onClick={() => { feedback_pos(); setFeedbackRating('Positive') }} />
                                <p className="feedback-type-pos">პოზიტიური</p>
                              </div>
                              <div className="col-4" id="feedback-neu">
                                <img src="../../resources/icons/neutral-emoji.png" alt="neutral rating" className="neutral-feedback-img" onClick={() => { feedback_neu(); setFeedbackRating('Neutral') }} />
                                <p className="feedback-type-neu">ნეიტრალური</p>
                              </div>
                              <div className="col-4" id="feedback-neg">
                                <img src="../../resources/icons/negative-emoji.png" alt="negative rating" className="negative-feedback-img" onClick={() => { feedback_neg(); setFeedbackRating('Negative') }} />
                                <p className="feedback-type-neg">ნეგატიური</p>
                              </div>
                            </div>
                            <div className="input-group post-job-form">
                              <textarea className="form-control" aria-label="With textarea" rows={5} placeholder="დაწერეთ კომენტარი" defaultValue={""} onChange={handleChange} />
                              <img src="../resources/icons/pencil.png" width="17px" height="17px" className="pencil-icon" />
                              <p className="max-symbols">0 / 200 სიმბოლო</p>
                            </div>
                          </div>
                          <div className="cat-modal-footer">
                            <p className="not-found-txt">თქვენს მიერ დაწერილი შეფასების ნახვას პლატფორმის სხვა მომხმარებლებიც შეძლებენ</p>
                            <button type="button" className="btn btn-primary btn-block cat-submit" data-toggle="modal" data-target="#feedbackSuccessModal" data-dismiss="modal" onClick={(e) => addFeedback(e)}>გაგზავნა</button>
                          </div>
                        </div>
                      </div>
                    </div> : ''}
                  {/*FEEDBACK SUCCESS MODAL*/}
                  {feedBackModalSuccess ?
                    <div className="modal fade" id="feedbackSuccessModal" tabIndex={-1} aria-labelledby="feedbackSuccessModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                          <div className="modal-header feedback-modal-header">
                            <h5 className="modal-title" id="feedbackSuccessModalLabel"></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setFeedBackModalSuccess(false)}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <i className="bi bi-check2 jobs-apply-success-icon" />
                            <p className="jobs-invited apply-success">შეფასება წარმატებით გაიგზავნა</p>
                          </div>
                          <div className="modal-footer feedback-modal-footer">
                          </div>
                        </div>
                      </div>
                    </div> : ''
                  }
                  <div className="empty-space" />
                </div>
              </div>
              {/* <div className="feedback-pagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Previous">
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Next">
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
            </div>
            : ''}
          {activeSection === 'account' ?
            <div className="col-lg-9 profile-tabbed-menu sidebarTabs">
              <div className="tabbed-card mt-3">
                {isMobile ?
                  <div className="edit-details">
                    <div className="editDetails-title-container">
                      <a onClick={() => setActiveSection('')}>
                        <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                      </a>
                      <p className="edit-details-title">My account</p>
                    </div>
                  </div> : ''
                }
                {/*PROFILE TABBED*/}
                <div className="tab-content">

                  {/*DETAILS TAB*/}
                  <span className="details-text">
                    {/* Contact details are displayed to job posters only when you are shortlisted  */}
                    <a href="#" data-toggle="modal" data-target="#editCustomerDetails" onClick={() => setShowEditDetails(true)}><i className="bi bi-pencil-square edit-myDetails" /></a>
                    </span>
                  <div className="row">
                    <div className="col-12">
                      <p className="user-attribute">სახელი, გვარი</p>
                      <p className="user-parameter" id="detailsItem">{`${profile.FirstName} ${profile.LastName}`}</p>
                    </div>
                  </div>
                  <hr className="details-hr" />
                  <div className="row">
                    <div className="col-12">
                      <p className="user-attribute">მობილური</p>
                      <p className="user-parameter">{profile.MobileNumber}</p>
                    </div>
                  </div>
                  <hr className="details-hr" />
                  <div className="row">
                    <div className="col-12">
                      <p className="user-attribute">ელ.ფოსტა</p>
                      <p className="user-parameter">{profile.Email}</p>
                    </div>
                  </div>
                  <hr className="details-hr" />
                  <div className="row">
                    <div className="col-12">
                      <p className="user-attribute">პაროლი</p>
                      <p className="user-parameter">**********</p>
                    </div>
                  </div>
                  <hr className="details-hr" />
                </div>
              </div>
            </div> : ''}
          {/*DETAILS EDIT*/}
          {showEditDetails ?
            <div className="modal fade" id="editCustomerDetails" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-dialog modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="cat-modal-header">
                      <h5 className="modal-title" id="editCustomerDetailsLabel">პროფილის რედაქტირება</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowEditDetails(false)}>
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <i className="bi bi-person-circle edit-details-icon" />
                          <span className="edit-details-label">სახელი</span>
                          <div className="input-group mb-2">
                            <input className="form-control details-form" id="detailsInput" type="text" defaultValue={profile.FirstName} {...register("firstName", {
                              required: "Required",
                            })} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="bi bi-person-circle edit-details-icon" />
                          <span className="edit-details-label">გვარი</span>
                          <div className="input-group mb-2">
                            <input className="form-control details-form" type="text" defaultValue={profile.LastName} {...register("lastName", {
                              required: "Required",
                            })} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="bi bi-telephone edit-details-icon" />
                          <span className="edit-details-label">მობილური</span>
                          <div className="input-group mb-2">
                            <input className="form-control details-form" type="text" defaultValue={profile.MobileNumber} {...register("mobileNumber", {
                              required: "Required",
                            })} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="bi bi-envelope edit-details-icon" />
                          <span className="edit-details-label">ელ.ფოსტა</span>
                          <div className="input-group mb-2">
                            <input className="form-control details-form" type="email" defaultValue={profile.Email} {...register("email", {
                              required: "Required",
                            })} readonly />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <i className="bi bi-eye edit-details-icon" />
                          <span className="edit-details-label">პაროლი</span>
                          <div className="input-group mb-2">
                            <input className="form-control details-form" type="password" defaultValue="********" />
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary btn-block cat-submit" data-dismiss="modal">შენახვა</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            : ''}
          {activeSection === 'notifications' ?
            <div className="col-lg-9 profile-tabbed-menu sidebarTabs" >
              {isMobile ?
                <div className="edit-details">
                  <div className="editDetails-title-container">
                    <a onClick={() => setActiveSection('')}>
                      <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                    </a>
                    <p className="edit-details-title">ნოტიფიკაციები</p>
                  </div>
                </div> : ''
              }
              <div className="tabbed-card mt-3">
                <div className="notifications-container">
                  <p className="notification-date">Today, 2 Jan 2021</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
                <div className="notifications-container">
                  <p className="notification-date">Yesterday, 1 Jan 2021</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
                <div className="notifications-container">
                  <p className="notification-date">31 Dec 2020</p>
                  <p className="notification-grey">Lali has approved your offer</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                  <p className="notification-white">Contacti: please finish registration by filling all fields out</p>
                  <p className="notification-grey">Check e-mail to verify your account</p>
                </div>
              </div>
              <div className="feedback-pagination">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Previous">
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">წინა</span>
                      </a>
                    </li>
                    <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                    <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                    <li className="page-item">
                      <a className="pagination-controls" href="#" aria-label="Next">
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">შემდეგი</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div> : ''}
        </div><div className="white-space mobile-spacing" />
      </div>
    </div>
  );
}

export default CustomerProfile;