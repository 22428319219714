import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/src/stylesheets/datepicker.scss";
import "./jobsPagination.scss";
import "./new-jobs.scss"
import ReactPaginate from "react-paginate";

import axios from "axios";
import moment from "moment";
import "moment/locale/ka";
import DatePicker, { registerLocale } from "react-datepicker";
import ka from "date-fns/locale/ka";
import { isMobile } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";

import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Carousel } from "react-responsive-carousel";

import { API_BASE_URL } from "../../constants/apiConstants";
import { NavLink, useLocation } from "react-router-dom";
import { size } from "lodash";

const NewJobs = () => {
  /*Pagination Setup */

  const [pageNumber, setPageNumber] = useState(0);

  const postsPerPage = 4;
  const pagesVIsited = pageNumber * postsPerPage;

  /*Pagination Setup  */

  const [showChat, setShowChat] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showDeleteCatItem, setShowDeleteCatItem] = React.useState(-1);
  const [newJobs, setNewJobs] = React.useState([]);
  const [spJobs, setSPJobs] = React.useState([]);
  const [spSelectJobNewJobsModal, setSPSelectJobNewJobsModal] =
    React.useState(false);
  const [mustUpdateNewJobs, setMustUpdateNewJobs] = useState(0);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [isLoading, setIsLoading] = useState(false);
  const contactiCookie = cookies.ContactiAccessToken;
  const [checked, setChecked] = useState(false);
  const [selecterItemObj, setSelecterItemObj] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [expressInterestSpId, setExpressInterestSpId] = useState(-1);
  registerLocale("ka", ka);
  const onSubmit = (data) => {
    updateJobState(expressInterestSpId, "Interested", data);
    setExpressInterestSpId(-1);
  };

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("tab");
  console.log(name); //101

  useEffect(() => {
    const apiUrl = API_BASE_URL + "/api/sp/jobs/get/new";
    const authAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const result = await authAxios.post(`${apiUrl}`);
        console.log(result.data);
        setNewJobs(result.data);
        setIsLoading(false);
      } catch (err) {
        console.log("Request failed", err);
      }
    };

    fetchData();
  }, [mustUpdateNewJobs, cookies.ContactiAccessToken]);

  useEffect(() => {
    if (contactiCookie === undefined) {
      setIsLoading(false);
    }
  }, [isLoading]);

  const updateJobState = async (jobId, state, data) => {
    const updateUrl = API_BASE_URL + "/api/sp/jobs/state/update";

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: updateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    if (state === "Interested") {
      await authAxios.post(`${updateUrl}`, {
        jobPostId: jobId,
        jobState: state,
        estimateSalary: data.estimateSalary,
        canStartWorkDate: data.canStartWorkDate,
        workTimeNumberOfUnit: data.workTimeNumberOfUnit,
        workTimeUnit: data.workTimeUnit,
      });
    } else {
      await authAxios.post(`${updateUrl}`, {
        jobPostId: jobId,
        jobState: state,
      });
    }

    setMustUpdateNewJobs(Math.floor(Math.random() * 100000000000 + 1));
    setSelecterItemObj(null);
  };

  /*Pagination Functionality*/
  const pageCount = Math.ceil(newJobs.length / postsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayNewJobsPaging = newJobs
    ? newJobs
        .slice(pagesVIsited, pagesVIsited + postsPerPage)
        .map((value, index) => {
          {
            index = postsPerPage * pageNumber + index;
          }
          return (
            <>
              <div
                className={
                  index === activeTabIndex
                    ? "jobs-content jobs-tablinks active"
                    : "jobs-content jobs-tablinks"
                }
                onClick={() => {
                  setActiveTabIndex(index);
                  setSelecterItemObj(value);
                }}
                id="defaultOpen"
              >
                <div className="row no-gutters">
                  <div className="jobs-img-container">
                    {value && value.images && value.images[0] ? (
                      <img
                        src={`${API_BASE_URL}/${value.images[0]}`}
                        alt="job preview"
                        className="jobs-img"
                      />
                    ) : (
                      <img
                        src="../../images/no-image.jpg"
                        alt="job preview"
                        className="jobs-img"
                      />
                    )}
                  </div>
                  <div className="col jobs-inner">
                    <p className="jobs-title">{value.jobTitle}</p>
                    <p className="user-attribute">{value.jobCategoryName}</p>
                    <p className="jobs-price">{value.budgetRange}</p>
                    {value.invited ? (
                      <p className="jobs-invited">
                        <i className="bi bi-check2 invited-check" />
                        მოგიწვიეს
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row no-gutters jobs-content-footer">
                  <div className="jobs-location">
                    <i className="bi bi-geo-alt" />
                    <span className="jobs-location-name">{value.city}</span>
                  </div>
                  <span className="jobs-post-date">{`დაემატა ${moment(
                    value.postedDate.replace("Z", "")
                  ).fromNow()}`}</span>
                </div>
              </div>
            </>
          );
        })
    : "";

  /* Pagination Functionality */

  const renderJobList = () => {
    return (
      <div
        className="tab-pane fade show active p-3"
        id="one"
        role="tabpanel"
        aria-labelledby="all-jobs"
      >
        {isMobile && (
          <div
            style={{
              width: "100%",
              height: "52px",
              background: "#eb6c20",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{ position: "absolute", left: "0", marginLeft: "15px" }}
              onClick={() => setSelecterItemObj(null)}
            >
              <NavLink to="/profile">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#fff"
                  className="bi bi-arrow-left-short"
                  viewBox="0 0 16 16"
                >
                  <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                </svg>
              </NavLink>
            </div>
            <div
              style={{
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              ახალი საქმეები
            </div>
          </div>
        )}

        <div className="row no-gutters jobs-filter">
          <div className="col-12 md:col-4">
            <img
              className="ic-search"
              src="../resources/icons/search-icon.png"
            />
            <div className="input-group mb-2">
              <input
                type="text"
                className="form-control search-form"
                id="jobsFilter"
                placeholder="მოძებნე კატეგორიით..."
              />
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 col-md-6">
            <div className="jobs-cat-inner">
              {displayNewJobsPaging}
              {
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName={"paginationButtons"}
                  // previousLinkClassName={"prevButton"}
                  // nextLinkClassName={"nextBUtton"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  // pageLinkClassName={"pagingButtons"}
                  renderOnZeroPageCount={null}
                />
              }
              {/* {newJobs
                ? newJobs.map((value, index) => (
                    <div
                      className={
                        index === activeTabIndex
                          ? "jobs-content jobs-tablinks active"
                          : "jobs-content jobs-tablinks"
                      }
                      onClick={() => {
                        setActiveTabIndex(index);
                        setSelecterItemObj(value);
                      }}
                      id="defaultOpen"
                    >
                      <div className="row no-gutters">
                        <div className="jobs-img-container">
                          {value && value.images && value.images[0] ? (
                            <img
                              src={`${API_BASE_URL}/${value.images[0]}`}
                              alt="job preview"
                              className="jobs-img"
                            />
                          ) : (
                            <img
                              src="../../images/no-image.jpg"
                              alt="job preview"
                              className="jobs-img"
                            />
                          )}
                        </div>
                        <div className="col jobs-inner">
                          <p className="jobs-title">{value.jobTitle}</p>
                          <p className="user-attribute">
                            {value.jobCategoryName}
                          </p>
                          <p className="jobs-price">{value.budgetRange}</p>
                          {value.invited ? (
                            <p className="jobs-invited">
                              <i className="bi bi-check2 invited-check" />
                              Invited
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row no-gutters jobs-content-footer">
                        <div className="jobs-location">
                          <i className="bi bi-geo-alt" />
                          <span className="jobs-location-name">
                            {value.city}
                          </span>
                        </div>
                        <span className="jobs-post-date">{`დაემატა ${moment(
                          value.postedDate.replace("Z", "")
                        ).fromNow()}`}</span>
                      </div>
                    </div>
                  ))
                : ""} */}
            </div>
            {/* <div className="feedback-pagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className="page-item">
                <a
                  className="pagination-controls"
                  href="#"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&lt;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li className="page-number-container active">
                <a className="pagination-numbers" href="#">
                  1
                </a>
              </li>
              <li className="page-number-container">
                <a className="pagination-numbers" href="#">
                  2
                </a>
              </li>
              <li className="page-number-container">
                <a className="pagination-numbers" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a
                  className="pagination-controls"
                  href="#"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&gt;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
          </div>
          {newJobs
            ? newJobs.map((value, index) =>
                index === activeTabIndex ? renderJobDetails(value) : ""
              )
            : ""}
        </div>
      </div>
    );
  };

  const renderJobDetails = (value) => {
    return (
      <div className=" col-6 tabcontent d-none d-md-block" id="tab-1">
        <div className="jobs-tabbed-gallery">
          <div className="jobs-details-container">
            <p className="job-poster">{value.jobPostedBy}</p>
            <span className="jobs-price-label">
              კლიენტის ბიუჯეტი:{" "}
              <span className="jobs-price">{value.budgetRange}</span>
            </span>
          </div>
          <div className="jobs-details-container">
            <p className="jobs-category">{value.jobCategoryName}</p>
            <p className="jobs-post-date no-margin">{`${moment(
              value.postedDate.replace("Z", "")
            ).fromNow()}`}</p>
          </div>
          <div className="jobs-location user-attribute">
            <i className="bi bi-geo-alt jobs-details-location" />
            <span className="jobs-location-name jobs-details-location">
              {value.city}
            </span>
          </div>
          <div className="jobs-gallery-container"></div>
          <Carousel
            showStatus={false}
            showArrows={false}
            showIndicators={false}
            autoPlay={false}
          >
            {value.images.map((val, i) => (
              <div className="jobs-img-expand">
                <img src={`${API_BASE_URL}/${val}`} />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="jobs-apply-container">
          <p className="jobs-description">{value.jobDescription}</p>
          <span className="jobs-title">
            ხელოსნის გადასახადი შერჩევის შემდეგ:
          </span>
          <span className="job-poster fee">{value.fee && value.fee}</span>
          <p className="jobs-apply-info">
            ინტერესის გამოხატვა უფასოა. საფასურს იხდით მხოლოდ მაშინ, როდესაც
            მომხმარებელი შეგარჩევთ.
          </p>
        </div>
        <div className="apply-container">
          <p className="user-attribute">
            მოწვევაზე დათანხმების შემდეგ, თქვენ მყისიერად იღებთ მომხმარებლის
            საკონტაქტო ინფორმაციაზე წვდომას და მოგიწევთ შერჩევის საფასურის
            გადახდა.
          </p>
        </div>
        <div className="row">
          {value.invited ? (
            <div className="col-6">
              <button
                type="button"
                className="btn btn-primary btn-lg accept-btn"
                onClick={() => updateJobState(value.id, "Shortlisted")}
              >
                მოწვევაზე დათანხმება
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="col-6">
            <button
              type="button"
              className="btn btn-primary btn-lg interest-btn"
              onClick={() => setExpressInterestSpId(value.id)}
            >
              გამოხატე ინტერესი
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderJobDetailsMobile = (value) => {
    return (
      <div style={{ background: "#f8f8f8", padding: "0 0 30px 0" }}>
        <div
          style={{
            width: "100%",
            height: "52px",
            background: "#eb6c20",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ position: "absolute", left: "0", marginLeft: "15px" }}
            onClick={() => setSelecterItemObj(null)}
          >
            <svg
              onClick={() => setSelecterItemObj(null)}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#fff"
              class="bi bi-arrow-left-short"
              viewBox="0 0 16 16"
            >
              <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
            </svg>
          </div>
          <div
            style={{
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            საქმეების სია
          </div>
        </div>
        <div className=" col-12 tabcontent" id="tab-1">
          <div className="jobs-tabbed-gallery" style={{ marginLeft: "0" }}>
            <div className="jobs-gallery-container"></div>
            <Carousel
              showStatus={false}
              showArrows={false}
              showIndicators={false}
              autoPlay={false}
            >
              {value.images.map((val, i) => (
                <div className="jobs-img-expand">
                  <img src={`${API_BASE_URL}/${val}`} />
                </div>
              ))}
            </Carousel>
          </div>

          <div className="jobs-apply-container">
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "#3989f4",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {value.jobPostedBy}
              </div>
              <div style={{ color: "#909090", fontSize: "12px" }}>{`${moment(
                value.postedDate.replace("Z", "")
              ).fromNow()}`}</div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: "#2b2b2b",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                {value.jobCategoryName}
              </div>
              <div style={{ color: "#3989f4", fontSize: "18px" }}>
                {value.budgetRange}
              </div>
            </div>
            <div
              style={{
                color: "#3989f4",
                fontSize: "18px",
                display: "flex",
                marginTop: "5px",
              }}
            >
              <i
                className="bi bi-geo-alt jobs-details-location"
                style={{ fontSize: "16px" }}
              />
              <p
                style={{ fontSize: "14px", color: "#909090", marginTop: "5px" }}
              >
                {value.city}
              </p>
            </div>

            <p className="jobs-description">{value.jobDescription}</p>
            <span className="jobs-title">
              ხელოსნის გადასახადი შერჩევის შემდეგ:
            </span>
            <span className="job-poster fee">{value.fee && value.fee}</span>
            <p className="jobs-apply-info">
              ინტერესის გამოხატვა უფასოა. საფასურს იხდით მხოლოდ მაშინ, როდესაც
              მომხარებელი შეგარჩევთ.
            </p>
          </div>
          <div className="apply-container">
            <p className="user-attribute">
              მოწვევაზე დათანხმების შემდეგ, თქვენ მყისიერად იღებთ მომხმარებლის
              საკონტაქტო ინფორმაციაზე წვდომას და მოგიწევთ შერჩევის საფასურის
              გადახდა.
            </p>
          </div>
          <div className="row accept-interest-btn-mobile">
            {value.invited ? (
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-primary btn-lg accept-btn-mobile"
                  onClick={() => updateJobState(value.id, "Shortlisted")}
                >
                  მოწვევაზე დათანხმება
                </button>
              </div>
            ) : (
              ""
            )}

            <div className="col-12 mt-2 pt-2">
              <button
                type="button"
                className="btn btn-primary btn-lg interest-btn-mobile"
                onClick={() => setExpressInterestSpId(value.id)}
              >
                გამოხატე ინტერესი
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContentByDevice = () => {
    if (isMobile) {
      if (selecterItemObj) {
        return renderJobDetailsMobile(selecterItemObj);
      }
      return renderJobList();
    }
    return renderJobList();
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {/*NEW JOBS TAB*/}

          {/* {isMobile} */}
          {renderContentByDevice()}

          {expressInterestSpId !== -1 ? (
            <div
              className="modal fade express-interest-form"
              id="applyModal"
              tabIndex={-1}
              aria-labelledby="applyModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="applyModalLabel">
                      გამოხატე ინტერესი
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setExpressInterestSpId(-1)}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <p className="jobs-apply-modal">
                      თქვენ დაგეკისრებათ საფასური 5₾ და მიიღებთ მომხმარებლის საკონტაქტო ინფორმაციას
                    </p> */}

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <Controller
                          control={control}
                          name="canStartWorkDate"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePicker
                              className={`form-control ${
                                errors.startDate ? "error" : ""
                              }`}
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              placeholderText="როდის შეძლებთ მუშაობის დაწყებას?"
                              locale="ka"
                            />
                          )}
                        />
                      </div>
                      <div className="form-group time__dayweekmonthpicker">
                        <input
                          type="number"
                          className="form-control jobs-work-time-txt"
                          placeholder="რა დრო დაგჭირდებათ?"
                          {...register("workTimeNumberOfUnit")}
                          disabled={checked}
                        />
                        <select
                          style={{width:'60px'}}
                          className="jobs-work-time"
                          {...register("workTimeUnit")}
                          disabled={checked}
                        >
                          <option selected>დღე</option>
                          <option>კვირა</option>
                          <option>თვე</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="სავარაუდო ბიუჯეტი ლარებში"
                          {...register("estimateSalary")}
                          disabled={checked}
                        />
                          {/* <select
                          className="form-control"
                          {...register("estimateSalary")}
                          disabled={checked}
                        >
                          <option value="" disabled selected>
                            შეარჩიეთ სავარაუდო ბიუჯეტი
                          </option>
                          <option className="default-font">
                            სტანდარტული ბიუჯეტი
                          </option>
                          <option className="default-font">
                            დაბალი ბიუჯეტი
                          </option>
                          <option className="default-font">
                            მაღალი ბიუჯეტი
                          </option>
                        </select> */}
                        
                      </div>
                      <div className="form-group form-check jobs-salary">
                        <input
                          name="jobsTimePriceCheck"
                          type="checkbox"
                          onInput={() => setChecked(!checked)}
                          {...register("jobsTimePriceCheck")}
                          className={`form-check-input ${
                            errors.jobsTimePriceCheck ? "is-invalid" : ""
                          }`}
                        />
                        <label
                          htmlFor="jobsTimePriceCheck"
                          className="form-check-label obs-salary-label"
                          style={{fontSize:'13px', textAlign:"justify"}}
                        >
                          ვნახავ რა კატეგორიის სამუშაოა და შემდგომ ვიტყვი ფასსა
                          და სამუშაოს შესრულებისთვის საჭირო დროს
                        </label>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block default-font"
                          data-toggle="modal"
                          data-target="#applySuccessModal"
                          data-dismiss="modal"
                        >
                          გაგზავნა
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/*APPLY SUCCESS MODAL*/}
          {showChat ? (
            <div
              className="modal fade"
              id="applySuccessModal"
              tabIndex={-1}
              aria-labelledby="applySuccessModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="applySuccessModalLabel">
                      გამოხატე ინტერესი
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <i className="bi bi-check2 jobs-apply-success-icon" />
                    <p className="jobs-invited apply-success">
                      მადლობა! <br />
                      თქვენი ინფორმაცია წარმატებით გაიგზავნა
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary btn-block default-font"
                      data-toggle="modal"
                      data-target="#formModal"
                      data-dismiss="modal"
                    >
                      გაგზავნილი ინფორმაციის ნახვა
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default NewJobs;
