import './profile.scss';

import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { Tab, Tabs } from 'react-bootstrap-tabs';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API_BASE_URL } from "../../constants/apiConstants";
import Invoices from '../invoices';
import PaymentDetails from '../invoices/payment-details';
import Loader from '../loader';
import Certificates from './certficates';
import JobFeedback from './feedbacks';
import JobCategory from './job-category';
import MyDetails from './my-details';
import Templates from './templates';
import MobileMenuAccordion from './mobile-menu-accordion';

const Portfolio = lazy(() => import('./portfolio')); // Lazy-loadedauth
function Profile(props) {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [jobCategorySearch, setJobCategorySearch] = React.useState(false);
    const [deleteTemplate, setDeleteTemplate] = React.useState(false);
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [profile, setProfile] = useState([])
    const isLoggedIn = cookies.ContactiAccessToken !== undefined && cookies.ContactiAccessToken !== "undefined";

    const [showInvoices, setShowInvoices] = useState(false);
    const [showProfile, setShowProfile] = useState(true);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [mustUpdateProfile, setMustUpdateProfile] = useState(0);
    const [profileImage, setProfileImage] = useState("");
  

    const [showMyJobs, setShowMyJobs] = useState(false);
    const [showMyProfile, setShowMyProfile] = useState(false);
    const [showMyAccount, setShowMyAccount] = useState(false);
    const [width, setWidth] = useState(0)
    const [showMobileMenu, setMobileMenu] = useState(true);

    if (cookies.ContactiAccessToken === "undefined") {
        console.log('undefined');
    }

    
  useEffect(() => {
    const apiUrl = API_BASE_URL + "/api/user/profile";

    const authAxios = axios.create({
      withCredentials: false,
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    const fetchData = async () => {
      try {
        const result = await authAxios.post(`${apiUrl}`);
        setProfile(result.data);
      } catch (err) {
        console.log("Request faied", err);
      }
    };

    fetchData();
  }, [mustUpdateProfile, cookies.ContactiAccessToken]);

  const handleChangePicture = async (e) => {
    e.preventDefault();
    const apiUrl = API_BASE_URL + "api/sp/avatar/add";
    const authAxios = axios.create({
      withCredentials: false,
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`,
      },
    });

    setProfileImage(URL.createObjectURL(e.target.files[0]));

    let formData = new FormData();
    formData.append("file", e.target.files[0]);

    try {
      const result = await authAxios.post(`${apiUrl}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (err) {
      console.log("Request faied", err);
    }
  };


    const redirectToHome = () => {
        Cookies.remove('ContactiAccessToken', { path: '/' });
        Cookies.remove('ContactiUsername', { path: '/' });
        window.location.href = "/";
    };

    // useEffect(() => {
    //     function handleResize() {
    //       setWidth(window.innerWidth)
    //     }
        
    //     window.addEventListener("resize", handleResize)
        
    //     handleResize()
        
    //     return () => { 
    //       window.removeEventListener("resize", handleResize)
    //     }


     

    //   }, [setWidth])
      
 



 

    // if (!isLoggedIn || isLoggedOut) redirectToHome();

    // console.log(isMobile)

    return (
        <div>
            {isMobile ?
                <div className="container header-mobile">
                    <div className="header">
                        {/*?php include '../includes/header.php';?*/}
                    </div>
                </div> : ''
            }
            <div className="profile-title-bg">
                <div className="container">
                    <p className="profile-title">ჩემი პროფილი</p>
                </div>
            </div>
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-3 col-12">
                        <div className="profile-sidebar-menu">
                            <div className="sidebar-menu-containers">
                                <div className="row">
                                    <div className="profile-picture">
                                    <img
                                        src={
                                            profileImage
                                            ? profileImage.AvatarUrl
                                            : profile.AvatarUrl
                                            ? `http://staging-cms.contacti.ge${profile.AvatarUrl}`
                                            : "./images/profile-picture.jpg"
                                        }
                                        className="profile-picture-img"
                                        />
                                        <input
                                        onChange={(e) => handleChangePicture(e)}
                                        className="profile-picture-input"
                                        type="file"
                                        name="profile-picture"
                                        id="profile-picture"
                                        />
                                    </div>
                                    <div className="col-9 col-md-10 col-lg-8">
                                        <div className="row">
                                            <p className="profile-name">{profile.FirstName}&nbsp;{profile.LastName}</p>
                                        </div>
                                        <div className="row">
                                            <p className="profile-feedback">{profile.PositiveFeedbackPercentage}% პოზიტიური შეფასება</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="sidebar-hr" />
                            <div className="sidebar-menu-containers">
                                <div className="account-complete-container">
                                    <span className="account-completion">პროგრესი</span>
                                    <span className="account-completion completed">90%</span>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar" id="account-progress" role="progressbar" aria-valuenow={90} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <hr className="sidebar-hr" />
                             {!isMobile ?  
                               ( <div className="sidebar-menu-containers">
                                    <div className="row sidebar-menu-items">
                                        <a href="#" className="profile-menu" onClick={() => { setShowProfile(true); setShowInvoices(false) }}>
                                            <p className="menu-item">ჩემი პროფილი</p></a>
                                         
                                    </div>
                                    <div className="row sidebar-menu-items">
                                        <a href="#" className="account-menu" onClick={() => { setShowInvoices(true); setShowProfile(false) }}>
                                            <p className="menu-item">ჩემი ანგარიში</p></a>
                                    </div>
                                    {/* <div className="row sidebar-menu-items">
                                        <a href="/post-a-job" className="post-job-menu">
                                            <p className="menu-item">მომსახურების მოთხოვნა</p></a>
                                    </div> */}
                                    <div className="row sidebar-menu-items">
                                        <a href="#" className="notifications-menu">
                                            <p className="menu-item">ნოტიფიკაციები</p></a>
                                    </div>
                                    <div className="row sidebar-menu-items">
                                        <a href="#" className="deactivate-menu">
                                            <p className="menu-item">ანგარიშის გაუქმება</p></a>
                                    </div>
                                    <div className="row sidebar-menu-items">
                                        <a href="#" onClick={() => redirectToHome()} className="logout-menu">
                                            <p className="menu-item">გასვლა</p></a>
                                    </div>
                                    <hr className="sidebar-hr" />
                                    {/* <p className="customer-view"><a className="customer-view-btn">View my profile page as a customer</a></p> */}
                                </div>) :
                              
                                ( <div className="wrapper">
                                    <MobileMenuAccordion className="row sidebar-menu-items" iconClass="post-job-menu" title="საქმეები">
                                   
                                          <a href="/jobs/new">
                                            <div className='mobilemenu-subitem' >
                                            <div className='mobilemenu-subitem-iconcont'>
                                               <img src='../../resources/icons/all-jobs.png' className='mobilemenu-subitem-icon' />
                                            </div>
                                            <div className='mobilemenu-subitem-label'>ახალი საქმეები</div>
                                            </div></a>


                                           
                                            <a href="/jobs/interested">
                                            <div className='mobilemenu-subitem' >
                                            <div className='mobilemenu-subitem-iconcont'>
                                               <img src='../../resources/icons/interested.png' className='mobilemenu-subitem-icon' />
                                            </div>
                                            <div className='mobilemenu-subitem-label'>დაინტერესებული საქმეები</div>
                                            </div></a>

                                    
                                            <a href="/jobs/shortlisted">
                                            <div className='mobilemenu-subitem' >
                                            <div className='mobilemenu-subitem-iconcont'>
                                               <img src='../../resources/icons/shortlist.png' className='mobilemenu-subitem-icon' />
                                            </div>
                                            <div className='mobilemenu-subitem-label'>შერჩეული</div>
                                            </div></a>


                                            <a href="/jobs/hired">
                                            <div className='mobilemenu-subitem' >
                                            <div className='mobilemenu-subitem-iconcont'>
                                               <img src='../../resources/icons/hired.png' className='mobilemenu-subitem-icon' />
                                            </div>
                                            <div className='mobilemenu-subitem-label'>დაქირავებული</div>
                                            </div></a>


                                            <a href="/jobs/unavailable">
                                            <div className='mobilemenu-subitem' >
                                            <div className='mobilemenu-subitem-iconcont'>
                                               <img src='../../resources/icons/unavailable.png' className='mobilemenu-subitem-icon' />
                                            </div>
                                            <div className='mobilemenu-subitem-label'>არააქტიური</div>
                                            </div></a>




                                         
                                    </MobileMenuAccordion>
                                    <MobileMenuAccordion className="row sidebar-menu-items" iconClass="profile-menu" title="ჩემი პროფილი" url="/mprofile">
                                       
                                       </MobileMenuAccordion>
                                    <MobileMenuAccordion  className="row sidebar-menu-items" iconClass="account-menu" title="ჩემი ანგარიში" url="/mprofile">
                                       
                                    </MobileMenuAccordion>
                                 
                                    <MobileMenuAccordion className="row " iconClass="notifications-menu" title="ნოტიფიკაციები" url="/notifications">
                                      
                                    </MobileMenuAccordion>

                                    <MobileMenuAccordion className="row sidebar-menu-items" iconClass="logout-menu" title="გამოსვლა" isLogOut >
                                      
                                    </MobileMenuAccordion>
                                    
                                
                                </div> )

                             }
                            
                        </div>
                    </div>

                    {showInvoices ? <div className="invoice-section col-lg-9">
                        <Invoices />
                    </div> : ''
                    }
                    {
                        showPaymentDetails ?
                            <div className="payment-details-wrapper">
                                <PaymentDetails />
                            </div>
                            : ''
                    }
                    {showProfile && !isMobile ?
                        <div className="col-lg-9 profile-tabbed-menu">
                            <div className="tabbed-card mt-3">
                                <div className="card-header tab-card-header">
                                    <Tabs onSelect={(index, label) => console.log(label + ' selected')}>
                                        <Tab label="დეტალები">
                                            <MyDetails />
                                        </Tab>
                                        <Tab label="კატეგორიები">
                                            <JobCategory />
                                        </Tab>
                                        {/* <Tab label="My Templates">
                                            <Templates />
                                        </Tab> */}
                                        <Tab label="პორტფოლიო">
                                            <Suspense fallback={<Loader />}>
                                                <Portfolio />
                                            </Suspense>
                                        </Tab>
                                        <Tab label="სერტიფიკატები">
                                            <Certificates />
                                        </Tab>
                                        <Tab label="შეფასება">
                                            <JobFeedback />
                                        </Tab>
                                    </Tabs>

                                </div>
                                {/*PROFILE TABBED*/}
                                <div className="tab-content" id="myTabContent">

                                    {/*TEMPLATES TAB*/}
                                    <div className="tab-pane fade p-3" id="three" role="tabpanel" aria-labelledby="three-tab">

                                    </div>
                                    {/*PORTFOLIO TAB*/}
                                    <div className="tab-pane fade p-3" id="four" role="tabpanel" aria-labelledby="four-tab">

                                    </div>
                                    {/*CERTIFICATES TAB*/}
                                    <div className="tab-pane fade p-3" id="five" role="tabpanel" aria-labelledby="five-tab">

                                    </div>
                                    {/*FEEDBACKS TAB*/}
                                    <div className="tab-pane fade p-3" id="six" role="tabpanel" aria-labelledby="six-tab">

                                    </div>
                                </div>

                            </div>
                        </div>
                        : ''}
                </div><div className="white-space mobile-spacing" />
            </div>
        </div>

    );
}

export default withRouter(Profile);