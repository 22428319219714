import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ModalRoot from "../modal/modalRoot";
import ModalService from "../modal/services/modalService";
import JobPost from "../jobPost";

const MenuDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const redirectToLogin = () => {
    window.location.href = "/login";

    props.updateTitle("Login");
  };

  const redirectToRegister = () => {
    window.location.href = "/register";
    props.updateTitle("Register");
  };

  const redirectToPostJob = () => {
    window.location.href = '/post-a-job';
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle>
      <img className="user-login" src="../../images/user-login.png" />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={()=> redirectToLogin() }>ავტორიზაცია</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={()=> redirectToRegister() }>დარეგისტრირდი ხელოსნად</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={()=> redirectToPostJob() }>მომსახურების მოთხოვნა</DropdownItem>
        <DropdownItem divider />
      </DropdownMenu>
    </Dropdown>
  );
}

export default MenuDropdown;