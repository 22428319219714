import './select-theme.scss';
import 'react-phone-number-input/style.css';
import 'react-phone-number-input/style.css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'react-select';
import axios from 'axios';
import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { withRouter } from 'react-router-dom';
import { Multiselect } from "multiselect-react-dropdown";

import { API_BASE_URL, TYPES } from '../../constants/apiConstants';
// import cities from './cities.json';
import SignUpHeader from './sign-up-header';
import updateAction from './updateAction';
import "./customer-registration.scss";

const CustomerRegistration = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { actions, state } = useStateMachine({ updateAction });
  const categoryObj = localStorage.getItem("reg_categories");
  const categoryOptions  = JSON.parse(categoryObj);
  const mappedCategories = [];

  setTimeout(function () {
    categoryOptions.forEach((item, index) => {
      mappedCategories.push({ name: item.Properties.jobCategoryName, id: item.Id });
    });
  }, 1000);

  console.log("categoryOptions", mappedCategories);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    successMessage: null,
  });

  const [items, setItems] = React.useState([
    { label: "Loading ...", value: "" },
  ]);

  //Content from Local storage
  const regObj = localStorage.getItem('reg_labels');
  const regLabels = JSON.parse(regObj);

  const busObj = localStorage.getItem('businessTypeList');
  const businessTypeList = JSON.parse(busObj);

  const verifyObj = localStorage.getItem('mobileVerificationLabels');
  const mobileVerificationLabels = JSON.parse(verifyObj);

  const [loginText, setLoginText] = React.useState(regLabels.finishRegButton);
  const [newMobile, setNewMobile] = React.useState(state.mobileNumber);
  const [loginState, setLoginState] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [passwordEqual, setPasswordEqual] = useState(false);
  const [cookies, setCookie] = useCookies(["name"]);
  const [isCompany, setIsCompany] = useState(false);
  const [selectedJobCategory, setSelectedJobCategory] = useState('');
  const [selectedBusinessType, setBusinessType] = useState('');
  const [ltdAddress, setLtdAddress] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [confirmPayload, setConfirmPayload] = useState({});

  const [rtoken, setRToken] = React.useState(null);
  const reCaptchaRef = useRef();

  const savedPostJob = window.localStorage.getItem("savedPostJob");

  console.log('Zi errors',errors);

  state.type = TYPES.customer;

  const redirectToHome = () => {
    window.location.href = "/";
    props.updateTitle("Home");
  };

  const redirectToJobPosted = () => {
    window.location.href = "/job-posted";
    props.updateTitle("Post a job");
  };

  const onRecaptchaChange = (value) => {
    setRToken(value);
  };

  const handlePinChange = (e) => {
    const { value } = e.target;
    setPin(value)
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    setNewMobile(value)
  };

  const handlePinSubmitClick = (e) => {
    e.preventDefault();

    if (!rtoken || rtoken === null || rtoken.length === 0) {
      props.showError("Make sure you verify you are not a robot.");
      return;
    }

    setLoginText(regLabels.verifyingBtnLabel);
    
    console.log("confirmPayLoad",confirmPayload);

    const payload = {
      email: confirmPayload.email,
      mobileNumber: confirmPayload.mobileNumber,
      password: confirmPayload.password,
      pin: pin,
      token: rtoken
    };

    const loginAxios = axios.create({
      withCredentials: false,
      baseUrl: API_BASE_URL
    });

    loginAxios
      .post(`${API_BASE_URL}/api/access/confirm`, payload)
      .then(function (response) {
        if (response.data.is_successful) {

          setLoginText("Verified...")

          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            // window.btoa(response.data.token_data.access_token),
            response.data.token_data.access_token,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          setCookie(
            "ContactiUsername",
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          if (savedPostJob && savedPostJob.length > 0) {
            redirectToJobPosted();
          } else {
            redirectToHome();
          }
          props.showError(null);
        }
        else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
          setLoginState("not verified");
        } else if (response.data.code === 204) {
          props.showError("Username and password do not match");
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
        } else {
          props.showError("Pin Validation failed. Please enter valid pin.");
          setLoginText(mobileVerificationLabels.mobileVerificationLabels.headerLabel)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const resendPin = (e) => {
    e.preventDefault();
    if (!rtoken || rtoken === null || rtoken.length === 0) {
      props.showError("Make sure you verify you are not a robot.");
      return;
    }

    setLoginText("Sending...");
    axios
      .post(API_BASE_URL + "/api/access/resendverify", { email: state.email })
      .then(function (response) {
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState(mobileVerificationLabels.unverifiedLabel);
          setLoginText(mobileVerificationLabels.headerLabel)
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateNumber = (e) => {
    e.preventDefault();

    if (!rtoken || rtoken === null || rtoken.length === 0) {
      props.showError("Make sure you verify you are not a robot.");
      return;
    }

    const payload = {
      email: state.email,
      mobileNumber: newMobile,
      token: rtoken
    };

    setLoginText(mobileVerificationLabels.sendingTextLabel);
    axios
      .post(API_BASE_URL + "/api/access/resendverify", payload)
      .then(function (response) {
        setRToken(null);
        reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState(mobileVerificationLabels.unverifiedLabel);
          setLoginText(mobileVerificationLabels.headerLabel)
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onSubmit = (data) => {

    console.log("We got here after post a job");

    setConfirmPayload(data);

    if (!rtoken || rtoken === null || rtoken.length === 0) {
      props.showError("Make sure you verify you are not a robot.");
      return;
    }

    state.password = data.password;
    state.repeatPassword = data.repeatPassword;
    data.type = state.type;

    setLoginText(regLabels.validatingBtnLabel);
    setDisabled(true);

    console.log('to be sent', state);
    console.log('data:', data);
    axios
      .post(API_BASE_URL + "/api/access/register", {...data, token: rtoken})
      .then(function (response) {
        setRToken(null);
        reCaptchaRef.current.reset();
        if (response.data.is_successful) {
          setDisabled(false);

          setFormData((prevState) => ({
            ...prevState,
            successMessage: "Registered successful. Redirecting to home page..",
          }));
          const expirytime = response.data.token_data.expires_in * 1000;

          setCookie(
            "ContactiAccessToken",
            window.btoa(response.data.token_data.access_token),
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );
          setCookie(
            "ContactiUsername",
            // window.btoa(response.data.token_data.access_token),
            response.data.user_data.Username,
            {
              path: "/",
              expires: new Date(Date.now() + expirytime),
            }
          );

          redirectToHome();
          props.showError(null);
        } else if (response.data.failed_reason === "Waiting for user verification") {
          setLoginState("not verified");
          setLoginText("Verify")
        } else if (response.data.code === 204) {
          props.showError("Registration failed");
        } else {
          props.showError("Registration failed");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    return (
    <div className="card col-12 cust-reg">
      <div className="form-wrapper">
        {loginState === "not verified" ?
          <form>

            <div className="sign-up-header">
              <h3>{mobileVerificationLabels.headerLabel}</h3>
              <small>
                {mobileVerificationLabels.headerSmallLabel}
              </small>
            </div>
            <div className="form-inner">
              <div className="form-group text-left">
                <input
                  type="text"
                  id="regPin"
                  placeholder={regLabels.pinLabel}
                  value={pin}
                  onChange={handlePinChange}
                  className={`form-control ${!pin || pin.length < 4 || pin.length > 4 ? "error" : ""
                    }`}
                />
              </div><div className="forgot-password">
                <span>{mobileVerificationLabels.resendText} <a href="#" onClick={resendPin}>{mobileVerificationLabels.resendBtnLabel}</a> {mobileVerificationLabels.orTextLabel} <a href="#" onClick={() => { setLoginState("update number"); }}>{mobileVerificationLabels.updateMobileLabel}</a> </span>
              </div>

              <div className="form-group text-left">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                  onChange={onRecaptchaChange}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary sign-in-btn verify-btn"
              onClick={handlePinSubmitClick}
            >
              {loginText}
            </button>
          </form> : loginState === "update number" ?
            <form>
              <div className="sign-up-header">
                <h3>Update Mobile Number</h3>
                <small>
                  Send pin to new mobile number.
                </small>
              </div>
              <div className="form-inner">
                <div className="form-group text-left">
                  <Controller
                    name="newMobile"
                    control={control}
                    value={newMobile}
                    onChange={handleMobileChange}
                    {...register("newMobile", { required: true, maxLength: 30 })}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value)
                    }}

                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        placeholder="New Mobile Number"
                        value={value}
                        onChange={onChange}
                        defaultCountry="GE"
                        id="newMobile"
                      />
                    )}
                  />
                  {errors["newMobile"] && (
                    <p className="error-message">{mobileVerificationLabels.invalidNumberLabel}</p>
                  )}

                </div><div className="forgot-password">
                  <span>{mobileVerificationLabels.registrationNumberLabel}</span>
                </div>

                <div className="form-group text-left">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                    onChange={onRecaptchaChange}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary sign-in-btn"
                onClick={updateNumber}
              >
                {loginText}
              </button>
            </form> :
            <form onSubmit={handleSubmit(onSubmit)}>
              <SignUpHeader />
              <div className="reg-form-inner">
            <div className="form-group text-left">
              <input
                type="text"
                className={`form-control ${
                  errors && errors.firstName ? "error" : ""
                }`}
                {...register("firstName", { required: true, maxLength: 255 })}
                id="firstName"
                name="firstName"
                placeholder={regLabels.firstName}
              />
            </div>
            <div className="form-group text-left">
              <input
                type="text"
                className={`form-control ${
                  errors && errors.lastName ? "error" : ""
                }`}
                id="lastName"
                name="lastName"
                placeholder={regLabels.lastName}
                {...register("lastName", { required: true, maxLength: 255 })}
              />
            </div>

            <div className="form-group text-left">
              <Controller
                name="mobileNumber"
                control={control}
                {...register("mobileNumber", { required: true, maxLength: 255 })}
                rules={{
                  validate: (value) => isValidPhoneNumber(value)
                }}

                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    placeholder={regLabels.mobileNumber}
                    value={value}
                    onChange={onChange}
                    defaultCountry={'GE'}
                    id="mobileNumber"
                  />
                )}
              />
              {errors["mobileNumber"] && (
                <p className="error-message">Invalid phone number</p>
              )}
            </div>
        
            <div className="form-group text-left">
              <input
                type="email"
                className={`form-control ${
                  errors && errors.email ? "error" : ""
                }`}
                id="email"
                placeholder={regLabels.emailAddress}
                defaultValue={""}
                value={props.email ? props.email:  state.emailAddress}
                {...register("email", { required: true, maxLength: 255 })}
              />
            </div>
            <div className="form-group text-left">
              <input
                type="email"
                className={`form-control ${
                  errors && errors.confirmEmail ? "error" : ""
                }`}
                id="confirmEmail"
                placeholder={regLabels.cofirmEmailAddress}
                defaultValue={""}
                value={props.email ? props.email:  state.emailAddress}
                {...register("confirmEmail", { required: true, maxLength: 255 })}
              />
            </div>
                <div className="form-group text-left">
                  <input
                    type="password"
                    name="password"
                    className={`form-control ${errors && errors.password ? "error" : ""
                      }`}
                    {...register("password", { required: true, minLength: 8, maxLength: 30 })}
                    id="password"
                    placeholder={regLabels.passwordLabel}
                    defaultValue={''}
                  />
                </div>

                <div className="form-group text-left">
                  <input
                    type="password"
                    name="repeatPassword"
                    className={`form-control ${errors && state.repeatPassword !== state.password ? "error" : ""
                      }`}
                    {...register("repeatPassword", { required: true, minLength: 8, maxLength: 30 })}
                    id="repeat-password"
                    placeholder={regLabels.confirmPasswordLabel}
                    defaultValue={''}
                  />
                  {errors["repeatPassword"] && (
                       <p className="error-message">{regLabels.passwordErrorLabel}</p>
                  )}
                </div>
                <div className="form-group text-left">
                  <ReCAPTCHA
                    ref={reCaptchaRef}
                    sitekey="6Ldug6EcAAAAADMGdVdxPCI-jZ18sDe10m4AQQ_d"
                    onChange={onRecaptchaChange}
                  />
                </div>
          </div>
              <div className="row btn-wapper customer-reg-btn-wrapper">
                <button type="submit" className="btn btn-primary col-sm-5 sign-in-btn customer-reg-btn" disabled={disabled}>
                {loginText}
              </button>
              </div>
            </form>
        }
      </div>
    </div>
  );
};

export default CustomerRegistration;
