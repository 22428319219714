import "./jobs.scss";

import React, { useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { useCookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import InterestedJobs from "./interested-jobs";
import NewJobs from "./new-jobs";
import ShortListedJobs from "./short-listed-jobs";
import HiredJobs from "./hired-jobs";
import UnavailableJobs from "./unavalaible-jobs";

var Jobs = () => {
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [showChat, setShowChat] = useState(false);

  const contactiCookie = cookies.ContactiAccessToken;

  if (contactiCookie === undefined) {
    window.location.href = "/login";
  }

  // const { search } = useLocation();
  // const name = new URLSearchParams(this.props.location.search).get("tab")

  let { tab } = useParams();
  console.log("wwwwaaa:" + tab); //101

  // switch(param) {
  //   case 'foo':
  //     return 'bar';
  //   default:
  //     return 'foo';
  // }

  //   const handleSelect = (key) => {
  //     this.setState({ key });
  //     this.props.history.replace({
  //         hash: `${key}`
  //     })
  // }

  const activeTab = (tab) => {
    switch (tab) {
      case "new":
        return 0;
      case "interested":
        return 1;
      case "shortlisted":
        return 2;
      case "hired":
        return 3;
      case "unavailable":
        return 4;
      default:
        return 0;
    }
  };

  const renderJobTabMobile = (tab) => {
    switch (tab) {
      case "new":
        return <NewJobs />;
      case "interested":
        return <InterestedJobs />;
      case "shortlisted":
        return <ShortListedJobs />;
      case "hired":
        return <HiredJobs />;
      case "unavailable":
        return <UnavailableJobs />;
      default:
        return <NewJobs />;
    }
  };

  const renderTabs = () => {
    return (
      <Tabs
        selected={activeTab(tab)}
        onSelect={(index, label) =>
          console.log(`Selected Index: ${index}, Label: ${label}`)
        }
      >
        <Tab label="ახალი საქმეები" className="alljobsTab">
          <NewJobs />
        </Tab>
        <Tab label="დაინტერესებული საქმეები">
          <InterestedJobs />
        </Tab>
        <Tab label="შერჩეული">
          <ShortListedJobs />
        </Tab>
        <Tab label="დაქირავებული">
          <HiredJobs />
        </Tab>
        <Tab label="არააქტიური">
          <UnavailableJobs />
        </Tab>
      </Tabs>
    );
  };

  return (
    <div>
      <div className="container header-mobile">
        <div className="header">
          {/*?php include '../includes/header-profile.php';?*/}
        </div>
      </div>
      <div className="profile-title-bg">
        <div className="container">
          <p className="profile-title">საქმეები</p>
        </div>
      </div>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-12 profile-tabbed-menu">
            <div className="tabbed-card jobs-tabbed-menu">
              {/*JOBS TABBED*/}

              {!isMobile ? renderTabs() : renderJobTabMobile(tab)}

              <div className="tab-content" id="myTabContent">
                {/*INTERESTED JOBS TAB*/}
                {/*FORM MODAL*/}
                {showChat ? (
                  <div
                    className="modal fade"
                    id="formModal"
                    tabIndex={-1}
                    data-backdrop="static"
                    aria-labelledby="formModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="formModalLabel">
                            Chat
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="chat-msg-line">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span className="apply-form">
                              <div className="form-dot" />
                              <p className="job-start-date">
                                I can start at 12 Feb. 2021
                              </p>
                              <div className="form-dot" />
                              <p className="work-time">I need 2 month</p>
                              <p className="salary">
                                I will tell time &amp; price later later aaalala
                              </p>
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:00</p>
                            </div>
                          </div>
                        </div>
                        <div className="white-space" />
                        <div className="white-space" />
                        <div className="send-new-form">
                          <a
                            href
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#applyModal"
                          >
                            Send new form
                          </a>
                        </div>
                        <div className="modal-footer form-modal-footer">
                          <span className="user-attribute">
                            You can start conversation with this customer after
                            shortlisting the job
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/*SHORT LISTED TAB*/}
                {/*CHAT MODAL*/}

                {showChat ? (
                  <div
                    className="modal fade"
                    id="chatModal"
                    tabIndex={-1}
                    data-backdrop="static"
                    aria-labelledby="chatModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm chat-modal modal-dialog-scrollable">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="chatModalLabel">
                            Chat
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="chat-msg-line">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span className="chat-msg-me">
                              Hello dear customer
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:00</p>
                            </div>
                          </div>
                          <div className="chat-msg-line-right">
                            <div className="chat-msg-time">
                              16 Jul<p>14:10</p>
                            </div>
                            <span className="chat-msg-other">
                              Hello dear SP
                            </span>
                            <img
                              src="../images/pp.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                          </div>
                          <div className="chat-msg-line">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span className="chat-msg-me">
                              I'm ready to start working on Friday. If you
                              accept my offer, please let me know.
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:11</p>
                            </div>
                          </div>
                          <div className="chat-msg-line-right">
                            <div className="chat-msg-time">
                              16 Jul<p>14:10</p>
                            </div>
                            <span className="chat-msg-other">
                              Hello dear SP
                            </span>
                            <img
                              src="../images/pp.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                          </div>
                          <div className="chat-msg-line">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span className="chat-msg-me">
                              I'm ready to start working on Friday. If you
                              accept my offer, please let me know.
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:11</p>
                            </div>
                          </div>
                          <div className="chat-msg-line-right">
                            <div className="chat-msg-time">
                              16 Jul<p>14:10</p>
                            </div>
                            <span className="chat-msg-other">
                              Hello dear SP
                            </span>
                            <img
                              src="../images/pp.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                          </div>
                          <div className="chat-msg-line">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span className="chat-msg-me">
                              I'm ready to start working on Friday. If you
                              accept my offer, please let me know.
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:11</p>
                            </div>
                          </div>
                          <div className="chat-msg-line" id="chat-template-msg">
                            <img
                              src="../images/profile-picture.jpg"
                              className="profile-button-img chat-profile-pic"
                            />
                            <span
                              className="chat-msg-me"
                              id="chat-template-msg-txt"
                            >
                              I'm ready to start working on Friday. If you
                              accept my offer, please let me know.
                            </span>
                            <div className="chat-msg-time">
                              16 Jul<p>14:11</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="send-new-form use-template"
                          onClick="openTemplateToast()"
                        >
                          Use template
                        </div>
                        <div id="chat-template">
                          <p
                            className="chat-template-closebtn"
                            onClick="closeChatTemplate()"
                          >
                            ×
                          </p>
                          <div
                            className="chat-template-container"
                            onClick="useTemplate()"
                          >
                            <p className="chat-template-header">
                              Message template
                            </p>
                            <p className="chat-template-txt" id="templateTxt">
                              Some text to describe the SP or Customer message.
                              Use template or close it.
                            </p>
                          </div>
                          <div className="chat-template-container">
                            <p className="chat-template-header">
                              2nd message template
                            </p>
                            <p className="chat-template-txt">
                              Some text to describe the SP or Customer message.
                              Use template or close it.
                            </p>
                          </div>
                        </div>
                        <div className="modal-footer chat-modal-footer">
                          <input
                            type="text"
                            className="chat-input"
                            placeholder="Write a message..."
                          />
                          <button
                            type="button"
                            className="btn btn-primary default-font send-msg-btn"
                            data-toggle="modal"
                            data-target
                          >
                            <i className="bi bi-cursor send-msg-icon" />
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="white-space mobile-spacing" />
      </div>
      {/*?php include 'mobilemenu.php';?*/}
      <div className="footer footer-mobile">
        {/*?php include '../includes/footer.php';?*/}
      </div>
    </div>
  );
};

export default Jobs;
