// import React from "react";
import Footer from "../footer";
import Header from "../header";
// import Body from "./body";
import "./home.scss";
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Alert } from "react-bootstrap";

import { API_BASE_URL } from "../../constants/apiConstants";
import ModalRoot from "../modal/modalRoot";
import ModalService from "../modal/services/modalService";
import { useCookies } from "react-cookie";

import JobPost from "../jobPost";
import Categories from "../categories";
import ServiceProviders from "./service-providers";
import HowItWorks from "./howItWorks";

function Home(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [categories, setCategories] = useState([]);
  const [contentLoad, setContentLoaded] = useState(false);
  const [catsliceState, setCatsliceState] = useState(false);
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const isLoggedIn =
    !cookies.ContactiAccessToken || cookies.ContactiAccessToken !== "/";
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let catslice = "18";

  useEffect(() => {
    const res = async () => {
      if (!contentLoad) {
        const result = await axios.get(
          API_BASE_URL +
            `/api/metadata/${languageStoredInLocalStorage}/jobcategories`
        );
        const data = result.data;
        if (data) {
          setCategories((categories) => [...categories, data]);
          setContentLoaded(true);
        }
      }
    };
    res();
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleToggleAllCats = () => {
    setCatsliceState(!catsliceState);

    if (catsliceState) {
      catslice = "searchResults.length";
    } else {
      catslice = "18";
    }
    console.log(catslice);
  };

  const renderCats = () => {
    return <Categories language={props.language} slice={catsliceState} />;
  };

  useEffect(() => {
    if (contentLoad) {
      const results =
        categories &&
        categories[0].filter((value) =>
          value.name.toLowerCase().includes(searchTerm)
        );
      setSearchResults(results);
    }
  }, [searchTerm]);

  const redirectToPostJob = () => {
    window.location.href = "/post-a-job";
  };

  const redirectToLogin = () => {
    window.location.href = "/login";
    props.updateTitle("Login");
  };

  const postAjob = (category) => {
    localStorage.setItem("clickedCat", category);
    window.location.href= "/post-a-job";
  };

  return (
    <>
      <div>
        {/* <div>
            {postJobResponse ?
                <Alert variant="success">
                    <p>
                        Your Job has been posted successfully!
                    </p>
                    <hr />
                </Alert> :
                <Alert variant="danger">
                    <p>
                        There was an error while posting your job
                    </p>
                    <hr />
                </Alert>
            }
        </div> */}
        <ModalRoot />
        <div className="hero-text">
          <h1>
            <p className="banner-top-main-text">მოიძიე სანდო პროფესიონალი</p>
          </h1>
          <p className="banner-top-text">
            სწრაფად და მარტივად ჩვენს პლატფორმაზე
          </p>
        </div>
        <div className="banner-top-button">
          <div className="d-flex flex-column justify-content-center align-items-center">

            <a type="button" className="btn btn-success fcaps" href="/register">
              დარეგისტრირდი ხელოსნად
            </a>
            {isLoggedIn ? (
              <button
                type="button"
                onClick={redirectToPostJob}
                className="btn btn-primary fcaps mt-4"
              >
                იპოვე ხელოსანი
              </button>
             ) : (
              <button
                type="button"
                onClick={() => redirectToLogin()}
                className="btn btn-success"
              >
                Post a job
              </button>
            )}           
          </div>
        </div>
        <div className="hero">
          <img src="images/top-banner.jpg" width="100%" height="407px" />
        </div>
        <div className="white-space"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <p className="categories-title fcaps">კატეგორიები</p>
              <p className="seeAll-categories"></p>
            </div>
            <div className="col-lg-4">
              <img
                className="ic-search"
                src="images/search-icon.png"
                width="16px"
                height="16px"
              />
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputGroup"
                  placeholder="კატეგორიის ძებნა..."
                  value={searchTerm}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container  categories-small">
          <div className="row">
            <div className=" col-12">
              <div className="row">
                {searchResults.length === 0
                  ? renderCats()
                  : searchResults &&
                    searchResults.slice(0, 6).map((value, index) => (
                      // <div>
                      <div className="col-lg-2 col-md-4 col-6 category-row"  key={index} onClick={() => postAjob(value.name)}>
                      <div className="category-box" style={{display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'center', alignItems:'center'}} >
                      <span></span>
                       <span></span>
                       <span></span>
                       <span></span>
                      {value.imageUrl ?
                        <img
                          src={`${API_BASE_URL}${value.imageUrl}`}
                          width="50px"
                          height="50px"
                          className="category-icons"
                        /> :
                        <img
                          src="/images/favicon.png"
                          width="40px"
                          height="40px"
                          className="category-icons"
                        />
                      }
                      <div className="categories-small-cards">
                        {value.name} 
                      </div>
                      </div>
                    </div>
                    ))}
              </div>
              <div className="col-lg-12">
            {!catsliceState ? (
              <a href="#" id="loadMore" onClick={() => handleToggleAllCats()}>
                <img
                  src="images/see-more-icon.png"
                  width="16px"
                  height="16"
                  style={{ marginRight: "5px" }}
                />{" "}
                ყველა კატეგორია
              </a>
            ) : (
              <a href="#" id="loadMore" onClick={() => handleToggleAllCats()}>
                <img
                  src="images/see-more-icon.png"
                  width="16px"
                  height="16"
                  style={{ marginRight: "5px", transform: "rotate(180deg)" }}
                />{" "}
                კატეგორიების აკეცვა
              </a>
            )}
          </div>
            </div>
          </div>
        </div>
        <div className="container categories-large">
          <div className="row no-gutters">
            {searchResults.length === 0
              ? renderCats()
              : searchResults &&
                searchResults.slice(0, catslice).map((value, index) => (
                  <div className="col-sm-2 col-lg-2 col-md-4 col-3 category-row" onClick={() => postAjob(value.name)}>
                    <div className="category-box">
                      {value.imageUrl ? (
                        <img
                          src={`${API_BASE_URL}${value.imageUrl}`}
                          width="50px"
                          height="50px"
                          className="category-icons"
                        />
                      ) : (
                        <img
                          src="/images/favicon.png"
                          width="40px"
                          height="40px"
                          className="category-icons "
                        />
                      )}
                      <div className="categories-cards">{value.name}</div>
                    </div>
                  </div>
                ))}
          </div>
          <br />
          <div className="col-lg-12">
            {!catsliceState ? (
              <a href="#" id="loadMore" onClick={() => handleToggleAllCats()}>
                <img
                  src="images/see-more-icon.png"
                  width="16px"
                  height="16"
                  style={{ marginRight: "5px" }}
                />{" "}
                ყველა კატეგორია
              </a>
            ) : (
              <a href="#" id="loadMore" onClick={() => handleToggleAllCats()}>
                <img
                  src="images/see-more-icon.png"
                  width="16px"
                  height="16"
                  style={{ marginRight: "5px", transform: "rotate(180deg)" }}
                />{" "}
                კატეგორიების აკეცვა
              </a>
            )}
          </div>
        </div>
        <div className="white-space"></div>

        {/* <div className="hr-container">
          <div className="container inner-container">
            <div className="row">
              <h4 className="cards-title col-xl-9 col-md-9 col-sm-9 col-7 fcaps text-small">
                <b>ტოპ კონტაქტები</b>
              </h4>
              <div className="col-xl-3 col-md-3 col-sm-3 col-5 text-right d-none d-md-block">
                <button type="button" className="btn btn-primary d-none d-md-block">
                  ყველა კონტაქტის ნახვა
                </button>
              </div>
            </div>
          </div>
          <div className="nav-prev">
            <img src="images/left-arrow.png" width="50px" height="50px" />
          </div>
          <div className="nav-next">
            <img src="images/right-arrow.png" width="50px" height="50px" />
          </div>
          <div className="row">
            <div className="col-xl-1 col-md-1 col-sm-1 col-1"></div>
            <ServiceProviders />
          </div>
        </div> */}

        <div className="chat-icon">
          <a href="">
            <img src="images/chat-icon.png" width="50px" height="50px" />
          </a>
        </div>
        <h3 className="banner-bottom-head">
          <b>მიიღე მეტი საქმე ჩვენი დახმარებით</b>
        </h3>
        <p className="banner-bottom-text">
          დააწექით ღილაკს და მიჰყევით ინსტრუქციას
        </p>
        <div className="banner-bottom-button">
          <a type="button" className="btn btn-success fcaps" href="/register">
            დარეგისტრირდი ხელოსნად
          </a>
        </div>
        <img
          className="banner-bottom-img"
          src="images/bottom-banner.jpg"
          width="100%"
          height="210px"
        />
      </div>

      <div className="white-space"></div>

      <div className="howitworks__mobile"> 
        <HowItWorks />
      </div>

      <div className="flow-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-5">
              <h4>
                <b>როგორ მუშაობს პლატფორმა?</b>
              </h4>
              <p>მიჰყევით ქვემოთ მოცემულ 4 მარტივ ნაბიჯს</p>
            </div>
            <div className="col-lg-4 col-sm-7 text-right">
              <button
                type="button"
                className="btn btn-primary fcaps"
                onClick={redirectToPostJob}
              >
                იპოვე ხელოსანი
              </button>
            </div>
          </div>
        </div>

        <div className="white-space"></div>

        <div className="container">
          <img src="images/flow-step1.png" className="first-flow-image" />
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <hr className="flow-hr-line" />
              <img src="images/flow-step2.png" className="flow-image" />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <hr className="flow-hr-line" />
              <img src="images/flow-step3.png" className="flow-image" />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <hr className="flow-hr-line" />
              <img src="images/flow-step4.png" className="flow-image" />
            </div>
          </div>
        </div>
      </div>

      <div className="white-space"></div>
      <div className="white-space"></div>
    </>
  );
}

export default Home;

// function Home(props) {
//   return (
//     <>
//       <Body />
//     </>
//   );
// }

// export default Home;
