import './sp-profile-details.scss';
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

import { useCookies } from 'react-cookie';
import { API_BASE_URL } from '../../../constants/apiConstants';

const SpProfileDetails = ( props ) => {   
  const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [description, setDescription] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [livingAddress, setLivingAddress] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [jobCategory, setJobCategory] = useState("");

  useEffect(() => {   
    const getSpData = () => {
        const selectedSp = (props.serviceProvidersInfo).find(serviceProvider => serviceProvider.id === props.selectedSpId);
        setDescription(selectedSp.description);
        setFirstName(selectedSp.firstName);
        setLastName(selectedSp.lastName);
        setLivingAddress(selectedSp.livingAddress);
        setAvatar(selectedSp.Avatar);
        setJobCategory(selectedSp.jobCategory);
    }
    
    const ddd = () => {
        console.log(props.serviceProvidersInfo)
        console.log(props.selectedSpId)
    }

    getSpData();
    ddd();
  }, [])

  useEffect(() => {

    const feedbackUrl = `${API_BASE_URL}/api/cs/jobs/feedbacks/get/${props.selectedSpId}`;

    const feedbackAxios = axios.create({
      withCredentials: false,
      baseURL: feedbackUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchFeedbackData = async () => {
      try {
        //setIsLoading(true);
        const result = await feedbackAxios.post(`${feedbackUrl}`);
        setFeedbacks(result.data);
        //setIsLoading(false)
        // console.log("FEEDBACKKKKKKKK",feedbacks)

      } catch (err) {
        console.log("Request faied", err);
      }
    };

    const portfolioUrl = `${API_BASE_URL}/api/sp/portfolio/get/${props.selectedSpId}`;
    const portfolioAxios = axios.create({
      withCredentials: false,
      baseURL: portfolioUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchPortfolioData = async () => {
      try {
        //setIsLoading(true);
        const result = await portfolioAxios.post(`${portfolioUrl}`);
        setPortfolio(result.data);
        //setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };

    const certificateUrl = `${API_BASE_URL}/api/sp/certificates/get/${props.selectedSpId}`;
    const certificateAxios = axios.create({
      withCredentials: false,
      baseURL: certificateUrl,
      headers: {
        Authorization: `Bearer ${cookies.ContactiAccessToken}`
      }
    });

    const fetchCertificateData = async () => {
      try {
        //setIsLoading(true);
        const result = await certificateAxios.post(`${certificateUrl}`);
        setCertificates(result.data);
        //setIsLoading(false)

      } catch (err) {
        console.log("Request faied", err);
      }
    };
    fetchFeedbackData()
    fetchPortfolioData()
    fetchCertificateData();
  }, [props.selectedSpId, cookies.ContactiAccessToken]);


  return (
    <div className="modal animated fadeIn" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">ხელოსნის პროფილი</h5>
                    <button onClick={() => props.closeModal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
               
                <div className="modal-body">
                    <>
                        <div style={{ display: "flex", gap:"1rem" }}>
                          <div>
                            <img className='profile__details__image cover' src={`${avatar ? API_BASE_URL+avatar : "../images/profile-picture.jpg"}`} />
                          </div>
                          <div style={{ display: "flex", flexDirection:"column", gap:"1rem" }}>
                              <div className="" style={{fontWeight:"bold"}}>{firstName}&nbsp;{lastName}</div>
                              <div className="" style={{color:'#408df4',fontWeight:'bold', fontSize:'12px'}}>{jobCategory}</div>
                              <div className="" style={{fontSize:'12px'}}>
                                  <i className="bi bi-geo-alt" /><span className="jobs-location-name">{livingAddress}</span>
                              </div>
                          </div>    
                        </div>
                            {/* <div className="carousel-item">
                                <img src="../images/gallery/sp2.jpg" className="about-slider" />
                            </div>
                            <div className="carousel-item">
                                <img src="../images/gallery/sp3.jpg" className="about-slider" />
                            </div> */}
                        {/* </div> */}
                        <hr  style={{backgroundColor: '#f8f8f8',height: "1px", borderColor : '#f8f8f8'}}/>
                        
                        <div className="sp-profileView-feedback">
                            <p className="feedback-positive job-top-section-left no-margin">76% პოზიტიური შეფასება</p>
                            <p className="jobs-price-label no-margin" data-toggle="modal" data-target="#AllFeedbacksModal" data-dismiss="modal">{`ყველა შეფასება (${feedbacks ? feedbacks.length : '0'})`}</p>
                        </div>
                    </>

                    <>
                        <div className="profile-title portfolio-title">ჩემს შესახებ</div>
                        <div className="" style={{color:'#408df4',fontWeight:'bold', fontSize:'14px', marginLeft:'10px', marginTop:'10px'}}>{description}</div>
                        <hr  style={{backgroundColor: '#f8f8f8',height: "1px", borderColor : '#f8f8f8'}}/>
                    </>

                    <>
                      {portfolio.length > 0 ? (
                      <div>                  
                          <div className="profile-title portfolio-title">პორტფოლიო</div>
                        <div className="modal-body d-flex bg-light">
                            <div className='profile__details__portfolio'>
                                {portfolio.length > 0 ? portfolio.map((value, index) => (
                                        <div key={index} className="">
                                          <img src={`https://staging-cms.contacti.ge/${value.imageUrl}`} alt="" className="sp-portfolio-img" />
                                        </div>
                                        )) : <p className="jobs-title sp-certificate-title cert-fallback">ფოტოები არ არის დამატებული</p>
                                }
                            </div>                      
                        </div>
                        <hr  style={{backgroundColor: '#f8f8f8',height: "1px", borderColor : '#f8f8f8'}}/>
                      </div>
                      ) : (null)}  
                       
                    </>

                    <>
                      {certificates.length > 0 ? (
                          <div>                  
                            <div className="profile-title portfolio-title">სერტიფიკატები</div>
                            <div className="sp-certificates">
                                <div className={`col-lg-6 col-12 vacancy-row ${certificates.length <= 0 ? `cert-fallback` : ''}`}>
                                {certificates.length > 0 ? certificates.map((value, index) => (
                                    <div className="sp-certificate-container">
                                    <i className="bi bi-file-earmark-pdf sp-certificate-pdf" />
                                    <p className="jobs-title sp-certificate-title">{value.title}</p>
                                    </div>
                                )) : <p className="jobs-title sp-certificate-title cert-fallback">სერტიფიკატები არ არის დამატებული</p>
                                }
                           
                                </div>
                            </div>
                            <hr  style={{backgroundColor: '#f8f8f8',height: "1px", borderColor : '#f8f8f8'}}/>
                          </div>
                        ) : (null)}  



                 
                    </>

                    <>

                    {feedbacks.length > 0 ? (
                          <div>                  
                            <div className="profile-title portfolio-title" data-toggle="modal" data-target="#AllFeedbacksModal" data-dismiss="modal">{`ყველა შეფასება (${feedbacks ? feedbacks.length : '0'})`}</div>
                            <div className="modal-body d-flex bg-light">
                            <div className='d-flex'>
                                {feedbacks && feedbacks.length > 0 ? feedbacks.map((value, index) => (
                                    <div key={index} className="card sp-portfolio-img-container">
                                        <div className="sp-allFeedbacks-container">
                                        <div className="jobs-details-container">
                                            <p className="jobs-title job-top-section-left">{value.feedbackByDisplayName}</p>
                                            <p className="vacancy-date job-top-section-right">{}</p>
                                        </div>
                                        <p className="contact-detail">{value.feedbackCategory}</p>
                                        <div className="jobs-content-footer no-margin">
                                            <p className="feedback-positive sp-listing-feedback">76% პოზიტიური შეფასება</p>
                                        </div>
                                        <span className="quotation-mark">„</span>
                                        <span className="feedback-txt">{value.feedbackDescription}</span>
                                        </div>
                                    </div>
                                    )) : <p className="jobs-title sp-certificate-title cert-fallback">შეფასებები ჯერ არ დამატებულა</p>
                                }
                            </div>
                            <hr  style={{backgroundColor: '#f8f8f8',height: "1px", borderColor : '#f8f8f8'}}/>
                            </div> 
                          </div>
                        ) : (null)}  

                        
                    </>   
                </div>

                <div className="modal-footer">
                    <button onClick={() => props.closeModal(false)} type="button" className="btn btn-primary" data-dismiss="modal">დახურვა</button>
                    
                </div>
            </div>
        </div>
    </div>
  )
}

export default SpProfileDetails