import './invoices.scss';
import 'react-datepicker/dist/react-datepicker.css';

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import DatePicker from 'react-datepicker';
import { FormGroup, Input } from 'reactstrap';

import { API_BASE_URL } from '../../constants/apiConstants';

const getFormattedPrice = (price) => `$${price.toFixed(2)}`;

const Invoices = (props) => {
    const [modalInvoiceData, setModalInvoiceData] = useState(undefined);
    const [invoices, setInvoices] = useState([]);
    const [sortedInvoices, setSortedInvoices] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [showPaymentOptions, setShowPaymentOptions] = useState(false);
    const [orderedInvoices, setOrderedInvoices] = useState([]);
    const [paidOrderedInvoices, setPaidOrderedInvoices] = useState([]);
    const [showRememberCard, setShowRememberCard] = useState(false);
    const [search, setSearch] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);

    const [checkedState, setCheckedState] = useState(
        new Array(invoices.length).fill(false)
    );

    const [total, setTotal] = useState(0);

    useEffect(() => {

        const getInvoicesUrl = `${API_BASE_URL}/api/sp/invoices/get`;

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: getInvoicesUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const result = await authAxios.post(`${getInvoicesUrl}`);
                setInvoices(result.data);
                setIsLoading(false);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [cookies.ContactiAccessToken]);

    const paidInvoices = (invoiceList) => {

        const paidInvoices = _.filter(invoiceList, function (c) {
            return c.shortlistFeePayed;
        });

        return paidInvoices;
    };

    const unPaidInvoices = (invoiceList) => {
        const uPaidInvoices = _.filter(invoiceList, function (c) {
            return !c.shortlistFeePayed;
        });

        return uPaidInvoices;
    };

    const redirectToPaymentDetails = (e) => {
        console.log(e.target.value);

        if (e.target.value.includes("Add")) {
            window.location.href = '/payment-details';
        }
        else {
            setShowRememberCard(true);
        }
    };

    useEffect(() => {
        if (invoices && invoices.length > 0) {
            setSortedInvoices(_.orderBy(invoices, (o) => {
                return moment(o.dueDate).format('YYYYMMDD');
            }, ['asc']));
        }
    }, [invoices.length, invoices]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setPaidOrderedInvoices(paidInvoices(sortedInvoices));
                setOrderedInvoices(unPaidInvoices(sortedInvoices));
            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [invoices.length, sortedInvoices.length, sortedInvoices]);

    const filterByDate = (date) => {

        const filterDate = moment(date).format('YYYYMMDD');
        const filteredByDate = _.filter(sortedInvoices, function (c) {
            return (filterDate === moment(c.dueDate).format('YYYYMMDD'));
        });

        setPaidOrderedInvoices(paidInvoices(filteredByDate));
        setOrderedInvoices(unPaidInvoices(filteredByDate));
    };

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
        setShowPaymentOptions(true);

        const totalPrice = updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    return sum + orderedInvoices[index].amount;
                }
                return sum;
            },
            0
        );

        setTotal(totalPrice);
    };

    return (
        <div className="mobile-sidebar invoice-wrapper">
            <div className="edit-details notifications-page-title">
                <div className="editDetails-title-container">
                    {/* <a onClick={() => goBack()}>
                        <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                    </a> */}
                    <a>
                        <i className="bi bi-arrow-left-circle editDetails-back-btn" />
                    </a>
                    <p className="edit-details-title">ინვოისები</p>
                </div>
            </div>
            <div className="row no-gutters listing-search">
                <div className="col search-invoice">
                    <img className="ic-search" src="../resources/icons/search-icon.png" />
                    <div className="input-group">
                        <input type="text" className="form-control search-form" onChange={(e) => setSearch(e.target.value)} id="jobsFilter" placeholder="ძებნა ინვოისის ნომრის მიხედვით..." />
                    </div>
                </div>
                <div className="col datepicker-invoice">
                    <div className="invoice-datepicker-container">
                        <DatePicker
                            className="form-control invoice-date-filter"
                            selected={startDate}
                            onChange={(date) => { setStartDate(date); filterByDate(date); }}
                        />
                        <i className="bi bi-calendar3 invoice-datepicker-icon" />
                    </div>

                </div>
            </div>
            <div className="job-cat-container">
                <div className="job-cat-inner">
                    <div className="unpaid-invoice-item">
                        <div className="unpaid-invoice-container title-container">
                            <p className="unpaid-invoices">გადასახდელი ინვოისები<span className="all-unpaid-label">ყველა გადასახდელი ინვოისი</span></p>
                        </div>
                        <input type="checkbox" className="payAll-invoice-checkbox" onClick="" id="checkAll" />
                    </div>
                    {modalInvoiceData ?
                        <div className="modal fade bd-example-modal" id="invoiceModal" tabIndex={-1} role="dialog" aria-labelledby="invoiceModal" aria-hidden="true">
                            <div className="modal-dialog " role="document">
                                <div className="modal-content">
                                    <div className="modal-header invoice-borders">
                                        <h5 className="modal-title">ინვოისი</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalInvoiceData(undefined)}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body default-font">
                                        <div className="row invoice-preview invoice-borders">
                                            <div className="col-6">
                                                <img src="../images/logo-invoice.png" className="invoice-logo" />
                                            </div>
                                            <div className="col-6 invoice-preview-details">
                                                <p>შპს კონტაქტი</p>
                                                <p>საირმის ქუჩა 35 </p>
                                                <p>თბილისი, საქართველო</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 user-attribute invoice-addressee">
                                                <span>{modalInvoiceData.serviceProviderName}</span>
                                                <span>{modalInvoiceData.serviceProviderAddress}</span>
                                                <span>თბილისი, საქართველო</span>
                                                <span>0112</span>
                                            </div>
                                            <div className="col-7 unpaid-invoices invoice-addressee">
                                                <span>ინვოისის ნომერი: {modalInvoiceData.id}</span>
                                                <span>Account Reference: D-375-505</span>
                                                <span>Issued: 31 {moment(modalInvoiceData.monthYearId, 'MMMMyyyy').format('MMMM')} {moment(modalInvoiceData.monthYearId, 'MMMMyyyy').format('yyyy')}</span>
                                                <span>Due Date: {moment(modalInvoiceData.dueDate).format('ll')}</span>
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="row invoice-borders invoice-description-container invoice-addressee">
                                                <span className="unpaid-invoices">Description:</span>
                                                <span className="invoice-description">Shortlisted fees for the month ending 31/{moment(modalInvoiceData.monthYearId, 'MMMMyyyy').format('MM/yyyy')}:</span>
                                                {modalInvoiceData && modalInvoiceData.invoiceJobInfo ? modalInvoiceData.invoiceJobInfo.map((value, index) => (
                                                    <span className="invoice-description">- {value.jobDescription} {value.jobContactiFee}₾ ({value.jobUserFullname})</span>
                                                )) : <span className="invoice-description">no job data available</span>}
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="invoice-sum unpaid-invoices">
                                                            <span className="invoice-sum-details invoice-borders-top">Amount:</span>
                                                            <span>Subtotal:</span>
                                                            <span>VAT @ 20%:</span>
                                                            <span>Invoice Total:</span>
                                                            <span className="invoice-total-sum">Total outstanding:</span>
                                                        </div>
                                                        <div className="invoice-sum-right unpaid-invoices">
                                                            <span className="invoice-sum-details invoice-borders-top">{modalInvoiceData.amount} ₾</span>
                                                            <span>{modalInvoiceData.amount} ₾</span>
                                                            <span>0</span>
                                                            <span>{modalInvoiceData.amount} ₾</span>
                                                            <span className="invoice-total-sum">{modalInvoiceData.amount} ₾</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="invoice-description">
                                            Please ensure that a payment of 39.30₾ reaches us by 7th April 2021. If you have any questions about this invoice please
                                            visit our help section. Please pay securely online using a credit or debit card via the ‘invoices page’ or by a bank transfer
                                            using the Invoice number to the credentials below:
                                        </p>
                                        <div className="invoice-addressee unpaid-invoices">
                                            <span>დასახელება: Dspace LTD</span>
                                            <span>ანგარიშის ნომერი: GE52 TB70 1973 6080 1000 16</span>
                                            <span>SWIFT კოდი: TBCBGE22XXX</span>
                                        </div>
                                    </div>
                                    <div className="container invoice-modal-footer">
                                        <button type="button" onClick="window.print()" className="upload-certificate-btn print-invoice"><i className="bi bi-printer" /> ინვოისის ამობეჭდვა </button>
                                        <button type="button" className="btn btn-primary print-invoice pay-now-btn"> გადახდა </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    {
                        orderedInvoices && orderedInvoices.length <= 0 ?
                            (
                                isLoading ?
                                <div className="job-category invoice-spinner">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                <div className="job-category unpaid-invoice-container">No Invoices for the selected date</div>
                            ) :

                            orderedInvoices.filter((val) => {
                                if (search == "") {
                                    return val;
                                } else if (val.invoiceNumber.toLowerCase().includes(search.toLowerCase())) {
                                    return val;
                                }
                            }).map((value, index) => {
                                return (
                                    <div className="unpaid-invoice-item" key={index}>
                                        <div className="job-category unpaid-invoice-container" onClick={() => setModalInvoiceData(value)}>
                                            <p className="unpaid-invoice-number">{`ინვოისი #${value.id}`} - <span className="invoice-price">{value.amount} ₾</span></p>
                                            <p className="invoice-pay-day">გადახდის ბოლო თარიღი: <b>{moment(value.dueDate).format('ll')} </b></p>
                                        </div>
                                        <input
                                            className="invoice-checkbox"
                                            type="checkbox"
                                            id={`custom-checkbox-${index}`}
                                            name={value.id}
                                            value={IDBKeyRange}
                                            checked={checkedState[index]}
                                            onChange={() => handleOnChange(index)}
                                        />
                                    </div>
                                );
                            })

                    }

                    {showRememberCard ?
                        <div className="row">
                            <div className="col">
                                <label>Rember my card
                                    <input type="checkbox" className="remember-card" id="customCheck1" />
                                </label>
                            </div>
                        </div> : ''
                    }

                    {showPaymentOptions ?
                        <div className="payment-options-wrapper">

                            <div className="dropdown" id="payment-options">
                                <FormGroup>
                                    <Input type="select" name="select" id="exampleSelect" onChange={(e) => redirectToPaymentDetails(e)}>
                                        <option>ბარათის მონიშვნა</option>
                                        <option data-thumbnail="../resources/icons/mastercard.png">4786 **** **** 1335</option>
                                        <option>სხვა ბარათის დამატება</option>
                                    </Input>
                                </FormGroup>

                                {/* <div className="form-control select-card" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Select your card <i className="bi bi-chevron-down payment-dropdown-icon" /></div>
                                <ul className="dropdown-menu payment-dropdown-menu">
                                    <a className="dropdown-item" id="selectCard" onClick="selectCard()">
                                        <img src="../../resources/icons/mastercard.png" className="card-type-img" />4786 **** **** 1335</a>
                                    <a href="#" className="dropdown-item">Add another card</a>
                                </ul> */}

                            </div>

                            <div className="pay-btn-container" id="pay-button">
                                <button type="button" className="btn btn-primary pay-now-btn">გადახდა - {total} ₾</button>
                            </div>
                        </div> : ''
                    }
                    <p className="unpaid-invoices">გადახდილი ინვოისები</p>
                    {paidOrderedInvoices && paidOrderedInvoices.length <= 0 ?
                        (
                            isLoading ?
                                <div className="job-category invoice-spinner">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">იტვირთება...</span>
                                    </div>
                                </div> :
                            <div className="job-category unpaid-invoice-container">ინვოისები არ მოიძებნა</div>
                        ) :
                        paidOrderedInvoices.filter((val) => {
                            if (search == "") {
                                return val;
                            } else if (val.invoiceNumber.toLowerCase().includes(search.toLowerCase())) {
                                return val;
                            }
                        }).map(({ id, dueDate, amount, shortlistFeePayed, monthYearId }, index) => {
                            return (
                                <div className="job-category unpaid-invoice-container" key={index}>
                                    <p className="unpaid-invoice-number">{`Invoice #${id}`} - <span className="invoice-price paid-price">{amount} ₾</span></p>
                                    <p className="invoice-pay-day">Payment due date: <b>{moment(dueDate).format('ll')}</b></p>
                                    <p className="invoice-paid">Shortlist fee for {moment(monthYearId, 'MMMMyyyy').format('MMMM')} <span className="invoice-paid-badge"><i className="bi bi-check-circle-fill invoice-paid-icon" />{shortlistFeePayed ? 'paid' : 'unpaid'}</span></p>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            {/* <div className="feedback-pagination">
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Previous">
                                <span aria-hidden="true">&lt;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                        <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                        <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                        <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                        <li className="page-item">
                            <a className="pagination-controls" href="#" aria-label="Next">
                                <span aria-hidden="true">&gt;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div> */}
            <div className="white-space" /><div className="white-space" />
        </div>
    );
}

export default Invoices;