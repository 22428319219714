import React from "react";

const PreSignUpHeader = () => {
  const regObj = localStorage.getItem('reg_labels');
  const regLabels = JSON.parse(regObj);
  console.log(regLabels)
  return (
    <div className="sign-up-header">
      <h3>{regLabels.preRegistrationBtnLabel}</h3>
      <small>
      {/* შეავსეთ ქვემოთ მოცემული ველები <strong>(ყველა მათგანი სავალდებულოა)</strong> */}
      შეავსეთ ქვემოთ მოცემული ველები
      </small>
    </div>
  );
};

export default PreSignUpHeader;
