import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactPaginate from 'react-paginate';

import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ka' 
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from "react-device-detect";
import { API_BASE_URL } from '../../constants/apiConstants';
import { NavLink } from 'react-router-dom';

const ShortListedJobs = () => {

    /*Pagination Setup */

    const [pageNumber, setPageNumber] = useState(0);

    const postsPerPage = 4;
    const pagesVIsited = pageNumber * postsPerPage;

   /*Pagination Setup  */
    const [sentDetailsModal, setSentDetailsModal] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [showDeleteCatItem, setShowDeleteCatItem] = React.useState(-1);
    const [shortlistedJobs, setShortlistedJobs] = React.useState([]);
    const [spJobs, setSPJobs] = React.useState([]);
    // const [spSelectJobInterestedJobsModal, setSPSelectJobInterestedJobsModal] = React.useState(false);
    const [mustUpdateShortListedJobs, setMustUpdateShortListedJobs] = useState(0)
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [isLoading, setIsLoading] = useState(false);
    const contactiCookie = cookies.ContactiAccessToken;
    const [selecterItemObj, setSelecterItemObj] = useState(null);

    useEffect(() => {

        const updateUrl = `${API_BASE_URL}/api/sp/jobs/get/state/ShortListed`;

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const result = await authAxios.post(`${updateUrl}`);
                setShortlistedJobs(result.data);
                setIsLoading(false);

            } catch (err) {
                console.log("Request faied", err);
            }
        };

        fetchData();
    }, [mustUpdateShortListedJobs, cookies.ContactiAccessToken]);

    useEffect(() => {
        if (contactiCookie === undefined) {
            setIsLoading(false);
        }
    }, [isLoading]);
    const updateJobState = async (jobId, state) => {
        const updateUrl = API_BASE_URL + "/api/sp/jobs/state/update";

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: updateUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        await authAxios.post(`${updateUrl}`, { "jobPostId": jobId, "jobState": state });
        setMustUpdateShortListedJobs(Math.floor((Math.random() * 100000000000) + 1));
    };


/* Pagination Functionality */
    const pageCount = Math.ceil(shortlistedJobs.length / postsPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    const displayShortListedJobsPaging = shortlistedJobs
    ? shortlistedJobs
        .slice(pagesVIsited, pagesVIsited + postsPerPage)
        .map((value, index) => {
          {
            index = postsPerPage * pageNumber + index;
          }
          return (
            <>
              <div
                className={
                  index === activeTabIndex
                    ? "jobs-content jobs-tablinks active"
                    : "jobs-content jobs-tablinks"
                }
                onClick={() => {
                  setActiveTabIndex(index);
                  setSelecterItemObj(value);
                }}
                id="defaultOpen"
              >
                <div className="row no-gutters">
                  <div className="jobs-img-container">
                    {value && value.images && value.images[0] ? (
                      <img
                        src={`${API_BASE_URL}/${value.images[0]}`}
                        alt="job preview"
                        className="jobs-img"
                      />
                    ) : (
                      <img
                        src="../../images/no-image.jpg"
                        alt="job preview"
                        className="jobs-img"
                      />
                    )}
                  </div>
                  <div className="col jobs-inner">
                    <p className="jobs-title">{value.jobTitle}</p>
                    <p className="user-attribute">{value.jobCategoryName}</p>
                    <p className="jobs-price">{value.budgetRange}</p>
                    {value.invited ? (
                      <p className="jobs-invited">
                        <i className="bi bi-check2 invited-check" />
                        მოგიწვიეს
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row no-gutters jobs-content-footer">
                  <div className="jobs-location">
                    <i className="bi bi-geo-alt" />
                    <span className="jobs-location-name">{value.city}</span>
                  </div>
                  <span className="jobs-post-date">{`დაემატა ${moment(
                    value.postedDate.replace("Z", "")
                  ).fromNow()}`}</span>
                </div>
              </div>
            </>
          );
        })
    : "";

/* Pagination Functionality */

    const renderJobList = () => {
        return (
          <div
            className="tab-pane fade show active p-3"
            id="one"
            role="tabpanel"
            aria-labelledby="all-jobs"
          >
            {isMobile &&( <div style={{width:'100%', height:'52px', background:'#1ab253', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{position:'absolute', left: '0', marginLeft:'15px'}} onClick={()=>setSelecterItemObj(null) }> 
           <NavLink to="/profile"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
      <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg></NavLink>
             </div>
            <div style={{color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>შერჩეული საქმეები</div>
          </div>)}
    
    
            <div className="row no-gutters jobs-filter">
              <div className="col-12 md:col-4">
                <img
                  className="ic-search"
                  src="../resources/icons/search-icon.png"
                />
                <div className="input-group mb-2">
                  <input
                    type="text"
                    className="form-control search-form"
                    id="jobsFilter"
                    placeholder="მოძებნე კატეგორიით..."
                  />
                </div>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-12 col-md-6">
                <div className="jobs-cat-inner">
                  {displayShortListedJobsPaging}
                  {
                    <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    pageCount={pageCount}
                    onPageChange={changePage}               
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName={"paginationButtons"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                    renderOnZeroPageCount={null}
                    />
                  }
                  {/* {interestedJobs
                    ? interestedJobs.map((value, index) => (
                        <div
                          className={
                            index === activeTabIndex
                              ? "jobs-content jobs-tablinks active"
                              : "jobs-content jobs-tablinks"
                          }
                          onClick={() => {
                            setActiveTabIndex(index);
                            setSelecterItemObj(value);
                          }}
                          id="defaultOpen"
                        >
                          <div className="row no-gutters">
                            <div className="jobs-img-container">
                              {value && value.images && value.images[0] ? (
                                <img
                                  src={`${API_BASE_URL}/${value.images[0]}`}
                                  alt="job preview"
                                  className="jobs-img"
                                />
                              ) : (
                                <img
                                  src="../../images/no-image.jpg"
                                  alt="job preview"
                                  className="jobs-img"
                                />
                              )}
                            </div>
                            <div className="col jobs-inner">
                              <p className="jobs-title">{value.jobTitle}</p>
                              <p className="user-attribute">
                                {value.jobCategoryName}
                              </p>
                              <p className="jobs-price">{value.budgetRange}</p>
                              {value.invited ? (
                                <p className="jobs-invited">
                                  <i className="bi bi-check2 invited-check" />
                                  Invited
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="row no-gutters jobs-content-footer">
                            <div className="jobs-location">
                              <i className="bi bi-geo-alt" />
                              <span className="jobs-location-name">
                                {value.city}
                              </span>
                            </div>
                            <span className="jobs-post-date">{`დაემატა ${moment(
                              value.postedDate.replace("Z", "")
                            ).fromNow()}`}</span>
                          </div>
                        </div>
                      ))
                    : ""} */}
                </div>
              </div>
              {shortlistedJobs
                ? shortlistedJobs.map((value, index) =>
                    index === activeTabIndex ? renderJobDetails(value) : ""
                  )
                : ""}
            </div>
          </div>
        );
      };
    

      const renderJobDetails = (value) => {
        return (
          <div className=" col-6 tabcontent d-none d-md-block" id="tab-1">
            <div className="jobs-tabbed-gallery">
              <div className="jobs-details-container">
                <p className="job-poster">{value.jobPostedBy}</p>
                <span className="jobs-price-label">
                  კლიენტის ბიუჯეტი:{" "}
                  <span className="jobs-price">{value.budgetRange}</span>
                </span>
              </div>
              <div className="jobs-details-container">
                <p className="jobs-category">{value.jobCategoryName}</p>
                <p className="jobs-post-date no-margin">{`${moment(
                  value.postedDate.replace("Z", "")
                ).fromNow()}`}</p>
              </div>
              <div className="jobs-location user-attribute">
                <i className="bi bi-geo-alt jobs-details-location" />
                <span className="jobs-location-name jobs-details-location">
                  {value.city}
                </span>
              </div>
              <div className="jobs-gallery-container"></div>
              <Carousel
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                autoPlay={false}
              >
                {value.images.map((val, i) => (
                 <div className="jobs-img-expand">
                 <div className="jobs-gallery-tag tag-shortlisted"><i className="bi bi-tag" />შერჩეული</div>
                 <img src={`${API_BASE_URL}/${val}`} />
             </div>
                ))}
              </Carousel>
            </div>
            <div className="jobs-apply-container">
              <p className="jobs-description">{value.jobDescription}</p>
              <span className="jobs-title">
                ხელოსნის გადასახადი შერჩევის შემდეგ:
              </span>
              <span className="job-poster fee">{value.fee && value.fee}</span>
              <p className="jobs-apply-info">
                ინტერესის გამოხატვა უფასოა. საფასურს იხდით მხოლოდ მაშინ, როდესაც
                მომხმარებელი შეგარჩევთ.
              </p>
            </div>
            <div className="apply-container">
              <p className="user-attribute">
                მოწვევაზე დათანხმების შემდეგ, თქვენ მყისიერად იღებთ მომხმარებლის
                საკონტაქტო ინფორმაციაზე წვდომას და მოგიწევთ შერჩევის საფასურის
                გადახდა.
              </p>
            </div>
            <div className="apply-container">
                <p className="interested">შერჩეული: 12</p>
                <span className="shortlisted">შერჩეული: 24</span>
            </div>
            <div className="btn-group shortlisted-btn-wrapper">
                <a type="button" className="btn btn-primary btn-lg btn-block apply-btn call-btn glyphicon glyphicon-earphone" data-toggle="modal"  href="tel:713-992-0916" data-target="#formModal" data-dismiss="modal">
                {value.mobileNumber}
                </a>
                <a type="button" className="btn btn-primary btn-lg btn-block apply-btn chat-btn" data-toggle="modal" data-target="#formModal" data-dismiss="modal" onClick={() => {setSentDetailsModal(true)}}>პირობების ნახვა</a>
            </div>
        
          </div>
        );
      };

    const renderJobDetailsMobile = (value) => {
        return (
          <div style={{background:'#f8f8f8', padding:'0 0 30px 0'}}>
          <div style={{width:'100%', height:'52px', background:'#eb6c20', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{position:'absolute', left: '0', marginLeft:'15px'}} onClick={()=>setSelecterItemObj(null) }> 
            <svg onClick={()=>setSelecterItemObj(null)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
      <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
    </svg>
             </div>
            <div style={{color:'#fff', display:'flex', justifyContent:'center', alignItems:'center'}}>საქმეების სია</div>
            
          </div>
          <div className=" col-12 tabcontent" id="tab-1">
            <div className="jobs-tabbed-gallery" style={{marginLeft:'0'}}>
              
             
             
              <div className="jobs-gallery-container"></div>
              <Carousel
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                autoPlay={false}
              >
                {value.images.map((val, i) => (
                  <div className="jobs-img-expand">
                    <img src={`${API_BASE_URL}/${val}`} />
                  </div>
                ))}
              </Carousel>
            </div>
           
            <div className="jobs-apply-container">
            <div style={{width:'100%', marginTop:'10px', display:'flex', justifyContent:'space-between'}} >
              <div style={{color:'#3989f4', fontSize:'14px', fontWeight:'bold'}}>{value.jobPostedBy}</div>
              <div style={{color:'#909090', fontSize:'12px'}}>{`${moment(value.postedDate.replace("Z", "")  ).fromNow()}`}</div>
            </div>
            <div style={{width:'100%',  marginTop:'10px', display:'flex', justifyContent:'space-between'}} >
              <div style={{color:'#2b2b2b', fontSize:'18px', fontWeight:'bold'}}>{value.jobCategoryName}</div>
              <div style={{color:'#3989f4', fontSize:'18px'}}>{value.budgetRange}</div>
            </div>
            <div style={{color:'#3989f4', fontSize:'18px', display:'flex', marginTop:'5px'}}><i className="bi bi-geo-alt jobs-details-location" style={{fontSize:'16px'}} /> 
            <p style={{fontSize:'14px', color:'#909090', marginTop:'5px'}}>{value.city}</p></div>
    
    
            
            </div>
            
      
            <div  style={{display:'flex',justifyContent:'space-between'}}>
                <p className="interested">შერჩეული: 12</p>
                <span className="shortlisted">შერჩეული: 24</span>
                 </div>
               <div className="">
                                        <a type="button" className="btn btn-primary btn-lg btn-block apply-btn call-btn glyphicon glyphicon-earphone" data-toggle="modal"  href="tel:713-992-0916" data-target="#formModal" data-dismiss="modal">
                                       {value.mobileNumber}
                                        </a>
                                        <a type="button" className="btn btn-primary btn-lg btn-block apply-btn chat-btn" data-toggle="modal" data-target="#formModal" data-dismiss="modal" onClick={() => setSentDetailsModal(true)}>პირობების ნახვა</a>
                </div>

          </div>
          </div>
        );
      };


      const renderContentByDevice = () => {
        if (isMobile) {
          if (selecterItemObj) {
            return renderJobDetailsMobile(selecterItemObj);
          }
           return renderJobList();
        }
        return renderJobList();
      };
    











    return (
        <>
            { isLoading ?
                <div className="loader-wrapper">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> :
                <>
            {renderContentByDevice()}

                
                    {/*Interested JOBS TAB*/}
                    {/* <div className="tab-pane fade show active p-3" id="one" role="tabpanel" aria-labelledby="all-jobs">
                        <div className="row no-gutters jobs-filter">
                            <div className="col-4">
                                <img className="ic-search" src="../resources/icons/search-icon.png" />
                                <div className="input-group mb-2">
                                    <input type="text" className="form-control search-form" id="jobsFilter" placeholder="მოძებნე კატეგორიით..." />
                                </div>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-6">
                                <div className="jobs-cat-inner">

                                    {interestedJobs ? interestedJobs.map((value, index) => (
                                        <div className={index === activeTabIndex ? "jobs-content jobs-tablinks active" : "jobs-content jobs-tablinks"} onClick={() => setActiveTabIndex(index)} id="defaultOpen">
                                            <div className="row no-gutters">
                                                <div className="jobs-img-container">
                                                {value && value.images ? <img src={`${API_BASE_URL}/${value.images[0]}`} alt='job preview' className="jobs-img" /> : <img src='' alt='job preview' className="jobs-img" />}
                                                </div>
                                                <div className="col jobs-inner">
                                                    <p className="jobs-title">{value.jobTitle}</p>
                                                    <p className="user-attribute">{value.jobCategoryName}</p>
                                                    <p className="jobs-price">{value.budgetRange}</p>
                                            {value.invited ? <p className="jobs-invited"><i className="bi bi-check2 invited-check" />Invited</p> : ''}
                                                </div>
                                            </div>
                                            <div className="row no-gutters jobs-content-footer">
                                                <div className="jobs-location">
                                                    <i className="bi bi-geo-alt" /><span className="jobs-location-name">{value.city}</span>
                                                </div>
                                                <span className="jobs-post-date"><span className="jobs-post-date">{`და${moment(value.postedDate.replace('Z', '')).fromNow()}`}</span></span>
                                            </div>
                                        </div>)) : ''}
                                </div>
                                {/* <div className="feedback-pagination">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <a className="pagination-controls" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&lt;</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li className="page-number-container active"><a className="pagination-numbers" href="#">1</a></li>
                                            <li className="page-number-container"><a className="pagination-numbers" href="#">2</a></li>
                                            <li className="page-number-container"><a className="pagination-numbers" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="pagination-controls" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&gt;</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> 
                            {interestedJobs ? interestedJobs.map((value, index) => (
                                index === activeTabIndex ? <div className="col-6 tabcontent-1" id="tab-1">
                                    <div className="jobs-tabbed-gallery">
                                        <div className="jobs-details-container">
                                            <p className="job-poster">{value.jobPostedBy}</p>
                                            <span className="jobs-price-label">კლიენტის ბიუჯეტი: <span className="jobs-price">{value.budgetRange}</span></span>
                                        </div>
                                        <div className="jobs-details-container">
                                            <p className="jobs-category">{value.jobCategoryName}</p>
                                            <p className="jobs-post-date no-margin">{`${moment(value.postedDate.replace('Z', '')).fromNow()}`}</p>
                                        </div>
                                        <div className="jobs-location user-attribute">
                                            <i className="bi bi-geo-alt jobs-details-location" /><span className="jobs-location-name jobs-details-location">{value.city}</span>
                                        </div>
                                        <div className="jobs-gallery-container">
                                           
                                        </div>

                                       
                                        <Carousel showStatus={false} showArrows={false} showIndicators={false} autoPlay={false}>
                                            {
                                                value.images.map((val, i) =>
                                                    <div className="jobs-img-expand">
                                                        <div className="jobs-gallery-tag tag-shortlisted"><i className="bi bi-tag" />Shortlisted</div>
                                                        <img src={`${API_BASE_URL}/${val}`} />
                                                    </div>
                                                )
                                            }
                                        </Carousel>
                                    </div>
                                    <div className="jobs-apply-container">
                                        <p className="jobs-apply-info">{value.jobDescription}
                                        </p>
                                        <span className="jobs-title">ხელოსნის გადასახადი შერჩევის შემდეგ:</span><span className="job-poster fee">{value.fee && value.fee}</span>
                                        <p className="jobs-apply-info">NB! need to figure out what this is In publishing and graphic design, Lorem ipsum is a placeholder text</p>
                                    </div>
                                    <div className="apply-container">
                                        <p className="interested">შერჩეული: 12</p>
                                        <span className="shortlisted">შერჩეული: 24</span>
                                    </div>
                                    <div className="btn-group shortlisted-btn-wrapper">
                                        <a type="button" className="btn btn-primary btn-lg btn-block apply-btn call-btn glyphicon glyphicon-earphone" data-toggle="modal"  href="tel:713-992-0916" data-target="#formModal" data-dismiss="modal">
                                       {value.mobileNumber}
                                        </a>
                                        <a type="button" href="/join-chat" className="btn btn-primary btn-lg btn-block apply-btn chat-btn" data-toggle="modal" data-target="#formModal" data-dismiss="modal">Chat</a>
                                    </div>

                                </div> : '')) : ''}
                        </div>
                    </div> */}
                    
                    {sentDetailsModal && 
                      (<div className="modal fade" tabIndex={-1}>                      
                        <div   className="modal-dialog modal-sm chat-modal modal-dialog-scrollable ">
                          <div className="modal-content" style={{marginTop:'50%'}}>
                            <div className="modal-header">
                              <h5 className="modal-title" id="chatModalLabel">გაგზავნილი პირობები</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>setSentDetailsModal(false)}>
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              {/* <div className="row no-gutters">
                                <div className="jobs-img-container">
                                  <img src="../images/gallery/1.png" className="sp-listing-img" style={{width:'50px',height:'50px',borderRadius:'50%'}} />
                                </div>
                                <div className="col jobs-inner">
                                {!isLoading ? (
                                  <>
                                    <p style={{color:'#408df4', fontWeight:'bold'}}>{shortlistedJobs[activeTabIndex].fullName}</p>
                                    <p style={{color:'#408df4', fontWeight:'bold'}}> {"hiredSpDetails.jobCategory"}</p>
                                  </>
                                  ) : (<div>Loading...</div> )}  
                                </div>
                              </div> */}
                              <p style={{color:'#ccc',  marginTop:'15px', fontSize:'12px'}}>ბოლო განახლდების თარიღი: 12.02.2022</p>
                              <div style={{width:'100%', height:'200px', background:'#f1f1f1', padding:'15px', fontSize:'13px', marginBottom:'20px', borderRadius:'5px'}}>
                                <div style={{marginBottom:'20px'}}>
                                  <div>მუშაობის დაწყება:</div>
                                  <div style={{fontWeight:'bold'}}>{ moment(shortlistedJobs[activeTabIndex].jobStates[0].canStartWorkDate).format('DD.MM.YYYY')}-ში</div>
                                </div>                                     
                                <div style={{marginBottom:'20px'}}>
                                  <div>შესრულების დრო:</div>
                                  <div style={{fontWeight:'bold'}}>{shortlistedJobs[activeTabIndex].jobStates[0].workTimeNumberOfUnit} {shortlistedJobs[activeTabIndex].jobStates[0].workTimeUnit}</div>
                                </div>
                                <div style={{marginBottom:'20px'}}>
                                  <div>ბიუჯეტი:</div>
                                  <div style={{fontWeight:'bold'}}>{shortlistedJobs[activeTabIndex].jobStates[0].estimateSalary} </div>                                      
                                </div>                                  
                              </div>
                              {<button type="button" className={`btn btn-primary btn-lg sp-buttons `} onClick={()=>setSentDetailsModal(false)}>დახურვა</button> }
                            </div>      
                          </div>
                        </div>
                      </div>)}
                </>
            }
        </>
    )
}

export default ShortListedJobs;