import React from "react";
import "./header.scss";

function AuthHeader(props) {
  const redirectToHome = () => {
    window.location.href = "/";
    props.updateTitle("Home");
  };

  return (
    <div className="row auth-header-wrapper">
      {/* <span
        className="nav-button col-lg-1 col-sm-1 col-1"
        onClick="openNav()"
      ></span> */}
      <div className="col-xl-1 col-lg-8 col-md-6 col-sm-6 col-5 logo">
        <a href="/"><img src="images/logo2.png" /></a>
      </div>

      <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-6">
        {/* <button
          type="button"
          className="btn btn-primary back-button"
          onClick={() => redirectToHome()}
        >
          Back
        </button> */}
      </div>
    </div>
  );
}

export default AuthHeader;
