import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { API_BASE_URL } from '../../constants/apiConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

function PostJobRequest() {
    const [postJobResponse, setPostJobResponse] = useState(false);
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [imgUrl, setImgUrl] = useState("");

    const postJobUrl = API_BASE_URL + "/api/sp/jobs/add";
    const addImageUrl = API_BASE_URL + "/api/visuals/add";
    const jobImgData = localStorage.getItem("jobImgData") !== null ? localStorage.getItem("jobImgData") : "";

    let savedPostJob = {};
    let jobObject = {};

    const redirectToJobs = () => {
        window.location.href = "/customer/customer-profile";
      };

    if (localStorage.hasOwnProperty("savedPostJob")) {

        savedPostJob = localStorage.getItem("savedPostJob");
        jobObject = JSON.parse(savedPostJob);
    } else {
        setTimeout(function () {
            redirectToJobs();
          }, 500);
    }

    const authAxios = axios.create({
        headers: {
            Authorization: `Bearer ${cookies.ContactiAccessToken}`,
            'Content-Type': 'application/json'
        }
    });

    // const imagePayload = { "filename": jobObject.jobPhoto, "description": jobObject.jobPhoto, "image": jobImgData }

    // const addImage = useCallback(async () => {
    //     try {
    //         if (!imgUrl) {
    //             const result = await authAxios.post(`${addImageUrl}`, imagePayload);
    //             setImgUrl(result.data);
    //         }
    //     } catch (err) {
    //         setImgUrl("");
    //         console.log("Request faied", err);
    //     }
    // });


    const postJobRequest = useCallback(async () => {
        try {
           
            jobObject.email = jobObject.email ? jobObject.email: '';
            jobObject.mobileNumber = jobObject.mobileNumber ? jobObject.mobileNumber: '';
            
            if (!postJobResponse) {
             
                const result = await authAxios.post(`${postJobUrl}`, jobObject);

                console.log("posted`", result.data);
                
                setPostJobResponse(result.data);
                setOpen(true);
                localStorage.removeItem('savedPostJob');
            }
        } catch (err) {
            setPostJobResponse(false);
            console.log("Request faied", err);
        }
    });

    useEffect(() => {
        if (!postJobResponse && jobObject) {
            postJobRequest();
        }
    }, [cookies.ContactiAccessToken]);

 //======================================Alerts Start=============================================//

 const [open, setOpen] = useState(false);

 const handleClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }
   setOpen(false);
 };

//======================================Alerts End=============================================//
    return (

        <div className="post-job-feedback">
            {postJobResponse ?
                // <div className="card text-center m-3 job-posted alert-success">
                //     <div className="card-body">
                //         Your job has been successfully posted
                //     </div>
                //     <button type="button" className="btn btn-outline-primary" onClick={redirectToHome()}>Home</button>
                // </div>
                <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    მომსახურების მოთხოვნა წარმატებით გამოქვეყნდა!
                  </Alert>
                </Snackbar>
              </Stack>
                 :
                <div className="loader-wrapper">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                // <div className="card text-center m-3 job-posted alert-danger">
                //     <div className="card-body">
                //       There was an error while posting ypour job , please contact support
                //     </div>
                // </div>
            }
        </div>
    );
}

export { PostJobRequest };