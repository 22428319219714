import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";
import { isMobile } from "react-device-detect";

import { API_BASE_URL } from "../../constants/apiConstants";

function Categories(props) {
  const [categories, setCategories] = useState([]);
  const [contentLoad, setContentLoaded] = useState(false);
  let languageStoredInSessionStorage = sessionStorage.getItem("language");

  useEffect(() => {
    const res = async () => {
      if (!contentLoad) {
        const result = await axios.get(
          API_BASE_URL + `/api/metadata/${languageStoredInSessionStorage}/jobcategories`
        );
        const data = result.data;
        if (data) {
          console.log('gets in');
          // localStorage.clear();
          localStorage.setItem("categories", JSON.stringify(data));
          setCategories((categories) => [...categories, data]);
          setContentLoaded(true);
        }
      }
    };
    res();

    // remove local storage item if it exists (it exists if user selects category on starting page)
    localStorage.removeItem("clickedCatId");  

  }, []);


  const postAjob = (category) => {
    localStorage.setItem("clickedCat", category.name);
    localStorage.setItem("clickedCatId", category.id);
    window.location.href= "/post-a-job";
  };

  if (contentLoad) {

    return (
      <>
        {!isMobile
          ? categories &&
          categories[0].slice(0, props.slice? categories[0].length : 18 ).map((value, index) => (
            <a className="col-lg-2 col-md-4 col-3 category-row" onClick={() => postAjob(value)} key={index}>
              <div className="category-box" >
              <span></span>
               <span></span>
               <span></span>
               <span></span>
              {value.imageUrl ?
                <img
                  src={`${API_BASE_URL}${value.imageUrl}`}
                  width="50px"
                  height="50px"
                  className="category-icons"
                /> :
                <img
                  src="/images/favicon.png"
                  width="40px"
                  height="40px"
                  className="category-icons"
                />
              }
              <div className="categories-cards">
                {value.name}
              </div>
              </div>
            </a>
          ))
          : categories &&
          categories[0].slice(0, props.slice? categories[0].length : 6 ).map((value, index) => (
            // <div >
            <a className="col-lg-2 col-md-4 col-6 category-row" onClick={() => postAjob(value)} key={index}>
            <div className="category-box" style={{display:'flex', flexDirection:'column', alignContent:'center', justifyContent:'center', alignItems:'center'}} >
            <span></span>
             <span></span>
             <span></span>
             <span></span>
            {value.imageUrl ?
              <img
                src={`${API_BASE_URL}${value.imageUrl}`}
                width="50px"
                height="50px"
                className="category-icons"
              /> :
              <img
                src="/images/favicon.png"
                width="40px"
                height="40px"
                className="category-icons"
              />
            }
            <div className="categories-small-cards">
              {value.name}
            </div>
            </div>
          </a>
            // </div>
          ))}
      </>
    );
  } else {
    return (<>კეტეგორიები ვერ მოიძებნა</>);
  }
}

export default Categories;
