import "./notifications.scss";

import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";

const NotificationItem = ({ Date, Id, JobPostId, Message, Seen, onclick }) => {
  const renderNotificationByType = () => {
    if (Seen) {
      <div className="notification-item-cont">
        {Message}
      </div>;
    }
    return (
      <div
        className="notification-item-cont"
        style={{ fontWeight: "bold" }}
        onClick={() => onclick(Id,JobPostId)}
      >
        {Message}
      </div>
    );
  };

  return renderNotificationByType();
};

NotificationItem.propTypes = {
  Date: PropTypes.string.isRequired,
  Id: PropTypes.number.isRequired,
  JobPostId: PropTypes.number.isRequired,
  Message: PropTypes.string.isRequired,
  Seen: PropTypes.bool.isRequired,
  onclick: PropTypes.func.isRequired,
};

export default NotificationItem;
