

const FooterBottom = (props) => {

    return (
      //   <div className="footer-bottom">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-3 footer-line">
      //         <a href="#" className="footer-social-fb">
      //           <img src="images/fb-icon.png" width="16px" height="16px" />
      //         </a>
      //         <a href="#">
      //           <img src="images/insta-icon.png" width="16px" height="16px" />
      //         </a>
      //       </div>
      //       <div className="col-6 footer-line">
      //         <p className="copyright-text">&#169; ყველა უფლება დაცულია</p>
      //       </div>
      //       <div className="col-3 footer-line">
      //         {/* <a className="language">English</a> */}
      //         {/* <img src="images/language.png" width="18px" height="18px" /> */}
      //         <div className="language-select">
      //           <select
      //             className="custom-select"
      //             value={props.language}
      //             onChange={e => { props.handleSetLanguage(e.target.value); window.location.reload(false); }}
      //           >
      //             <option value="en">En</option>
      //             <option value="ka">Geo</option>
      //           </select>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <></>
    );
}

export default FooterBottom;
