import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { API_BASE_URL } from '../../constants/apiConstants';
import Loader from '../loader';

const Certificates = () => {
    const [deleteItem, setDeleteItem] = React.useState(-1);
    const [certificate, setCertificate] = React.useState([]);
    const [cookies, removeCookie] = useCookies(["ContactiAccessToken"]);
    const [mustUpdateCertificate, setMustUpdateCertificate] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const getUrl = `${API_BASE_URL}/api/sp/certificate/get`;

        const authAxios = axios.create({
            withCredentials: false,
            baseURL: getUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const result = await authAxios.post(`${getUrl}`);
                setCertificate(result.data);
            } catch (err) {
                console.log("Request failed", err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [mustUpdateCertificate, cookies.ContactiAccessToken]);


    const deleteCertificate = async (pId) => {
        const delUrl = API_BASE_URL + "/api/sp/certificate/remove";

        const authAxios = axios.create({
            baseURL: delUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        try {
            setIsLoading(true);
            setDeleteItem(-1);
            await authAxios.post(`${delUrl}`, { "id": deleteItem });
        } catch (err) {
            console.log("Request failed", err);
        } finally {
            setIsLoading(false);
        }
        setMustUpdateCertificate(Math.floor((Math.random() * 100000000000) + 1));
    };

    const selectFiles = (e) => {
        e.preventDefault();

        if(e.target.files && e.target.files.length > 0) {
            upload(e.target.files[0])
        }
    }

    const toBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ data: reader.result.split(',')[1], name: file.name });
            reader.onerror = error => reject(error);
        });
    };

    const upload = async (file) => {
        const addImagesUrl = API_BASE_URL + "/api/sp/certificate/add";

        const authAxios = axios.create({
            baseURL: addImagesUrl,
            headers: {
                Authorization: `Bearer ${cookies.ContactiAccessToken}`
            }
        });

        const fileData = await toBase64(file);

        const payload = { "filename": fileData.name, "description": fileData.name, "image": fileData.data };
        try {

            setIsLoading(true);
            setDeleteItem(-1);
            await authAxios.post(`${addImagesUrl}`, payload);
        } catch (err) {
            console.log("Request failed", err);
        } finally {
            setIsLoading(false);
        }

        setMustUpdateCertificate(Math.floor((Math.random() * 100000000000) + 1));
    }

    return (
        <>
            {isLoading ?
                <Loader /> :
                <div className="job-cat-container">
                    <div className="job-cat-inner">
                        {certificate ? certificate.map((value, index) => (<div className="row job-category" id="certificateItem">
                            <div className="col-lg-10">
                                <p className="job-category-txt">{value.description} <i className="bi bi-file-earmark-pdf" /></p>
                            </div>
                            <div className="col-lg-2">
                                <img className="delete-btn" src="../resources/icons/delete.png" width="24px" alt="Delete Certificate" data-toggle="modal" data-target="#deleteCertifModal" onClick={() => setDeleteItem(value.id)} />
                            </div>
                        </div>)) : ''}
                        {deleteItem !== -1 ?
                            <div className="modal fade bd-example-modal-sm" id="deleteCertifModal" tabIndex={-1} role="dialog" aria-labelledby="deleteCertifModal" aria-hidden="true">
                                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">სერტიფიკატის წაშლა</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body default-font">
                                            დარწმუნებული ხართ რომ გსურთ სერტიფიკატის წაშლა?
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="dismiss-btn-modal default-font" data-dismiss="modal" onClick={() => setDeleteItem(-1)}>გაუქმება</button>
                                            <button type="button" className="delete-btn-modal default-font" data-dismiss="modal" onClick={() => deleteCertificate()}>წაშლა</button>
                                        </div>
                                    </div>
                                </div>
                            </div> : ''
                        }
                    </div>
                </div>}
            <div className="uploadcert-btn-container">
                <div className="input-group upload-certificate">
                    <div className="upload-certificate-btn">
                        <input type="file" className="custom-file-input" name="certificate" id="inputGroupFile01" onChange={selectFiles} />
                        <label className="upload-certificate-label" htmlFor="inputGroupFile01"><i className="bi bi-cloud-arrow-up upload-certificate-icon" /> &nbsp; სერტიფიკატების ატვირთვა</label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Certificates;