import "./select-theme.scss";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useStateMachine } from "little-state-machine";
import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";

import cities from "./cities.json";
import SignUpHeader from "./sign-up-header";
import updateAction from "./updateAction";

const RegistrationStep2 = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  const [isFirstOption, setIsFirstOption] = useState(false);
  const [livingAddress, setLivingAddress] = useState("");

  const categoryObj = localStorage.getItem("reg_categories");
  const categoryOptions = JSON.parse(categoryObj);

  const [items, setItems] = React.useState([
    { label: "Loading ...", value: "" },
  ]);

  const regObj = localStorage.getItem("reg_labels");
  const regLabels = JSON.parse(regObj);

  const onSubmit = (data) => {
    data.livingAddress = livingAddress;
    actions.updateAction(data);
    props.history.push("./step3");
  };

  useEffect(() => {
    // options();
  }, []);

  const getLivingAddress = (e, livingAddress) => {
    console.log(e.target.value);

    if (livingAddress?.name === "Tbilisi") {
      setIsFirstOption(true);
    } else {
      setIsFirstOption(false);
    }
    setLivingAddress(livingAddress?.value);
  };

  return (
    <div className="card col-12 col-lg-6 col-xl-3 login-card mt-5 hv-center">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SignUpHeader />
          <div className="reg-form-inner">
            <Controller
              render={({ ...props }) => (
                <Autocomplete
                  options={cities}
                  getOptionLabel={(option) => option.value}
                  renderOption={(option) => (
                    <span value={option.value}>{option.value}</span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={regLabels.livingAddress}
                      variant="outlined"
                      className="fcaps"
                      name="livingcity"
                      {...register("livingcity", {
                        required: "Required",
                      })}
                    />
                    
                    
                  )}
                  onChange={(e, data) => getLivingAddress(e, data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              value={(option) => option.name}
              defaultValue={""}
              name="livingAddress"
              
              control={control}
              className="fcaps"
            />
             {errors["livingcity"] && (
                <p className="error-message">ქალაქის ველის შევსება სავალდებულოა!</p>
              )}
           
            {/* <Controller
              render={({ ...props }) => (
                <Autocomplete
                  options={cities}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => <span>{option.name}</span>}
                  renderInput={(params) => (
                    <TextField {...params} label="Address" variant="outlined" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              value={(option) => option.name}
              defaultValue={"Tbilisi"}
              name="address"
              control={control}
            /> */}
            <div className="form-group">
              {/* <select className="form-control" id="sel1" {...register("jobCategory", {
                          required: "Required",
                        })}>
                        <option value="" disabled selected>{regLabels.categoriesLabel}</option>
                  {categoryOptions && categoryOptions.map((x,y) => <option key={y} value={x.Name}>{x.Properties.jobCategoryName}</option>)}
                  </select> */}
              {isFirstOption ? (
                <select
                  id="inputState"
                  className="form-control"
                  placeholder="District"
                  {...register("district", {
                    required: "Required",
                  })}
                >
                  <option value="" disabled selected>
                    {regLabels.districtLabel}
                  </option>
                  <option>ვაკე </option>
                  <option>საბურთალო</option>
                  <option>წავკისი</option>
                  <option>მთაწმინდა</option>
                  <option>ტაბახმელა </option>
                  <option>კოჯორი</option>
                  <option>ვარკეთილი</option>
                  <option>ივერთუბანი</option>
                  <option>ვაზისუბანი</option>
                  <option>თბილისის ზღვა</option>
                  <option>კაკლები</option>
                  <option>ძველი თბილისი</option>
                  <option>სანზონა</option>
                  <option>წყნეთი</option>
                  <option>კრწანისი</option>
                  {/* <option>Chugureti</option> */}
                  <option>დიდუბე</option>
                  <option>ნაძალადევი</option>
                  {/* <option>Gldani</option> */}
                  <option>ისანი</option>
                  <option>სამგორი</option>
                  <option>დიდი დიღომი</option>
                  {/* <option>Lilo</option> */}
                  {/* <option>Airport Setlment </option> */}
                  <option>ავჭალა</option>
                  <option>ფონიჭალა</option>
                  <option>ბეთანია</option>
                  <option>შინდისი</option>
                </select>
              ) : (
                ""
              )}
            </div>
            {/* <div className="form-group text-left">
                    <input
                      type="text"
                      className={`form-control ${errors && errors.address ? "error" : ""
                        }`}
                      {...register("address", { required: true, maxLength: 30 })}
                      id="address"
                      placeholder={regLabels.addressLabel}
                      defaultValue={""}
                    />
              </div> */}

            <input
              type="hidden"
              {...register("address")}
              id="address"
              defaultValue={""}
            />

            <InputGroup>
              <FormControl
                as="textarea"
                aria-label="With textarea"
                placeholder={regLabels.descriptionLabel}
                className={`form-control description ${
                  errors && errors.description ? "error" : ""
                }`}
                // {...register("description", { required: true, maxLength: 255 })}
                {...register("description", { maxLength: 255 })}
                id="description"
              />
            </InputGroup>
          </div>
          <button type="submit" className="btn btn-primary register-btn first-step-btn fcaps">
          {regLabels.nextButtonLabel}
          </button>
          {/* <div className="row btn-wapper">
            <a
              href="/register"
              type="button"
              className="btn btn-primary col-sm-5 sign-in-btn fcaps"
            >
              {regLabels.previousButtonLabel}
            </a>
            <button
              type="submit"
              className="btn btn-primary col-sm-5 sign-in-btn fcaps"
            >
              {regLabels.nextButtonLabel}
            </button>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default withRouter(RegistrationStep2);
