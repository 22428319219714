import React from 'react'
import FaqCard from './faq-card'

const Faq = () => {
  return (
    <FaqCard />    
  )
}
// test

export default Faq